import { PermissionConstants } from "./PermissionConstants";

export const Constants = {
  defaultPageSize: 10,
  maxPageSize: 9999,
  timeoutNavigation: 2000,
  dismissSuccessMessage: 2000,
  productType: "ProductType",
  appointmentStatusType: "AppointmentStatusType",
  purchaseType: "PurchaseType",
  identityType: "IdentityType",
  genderType: "GenderType",
  employeeType : "EmployeeType",
  titleType: "TitleType",
  discountType: "DiscountType",
  commissionType: "CommissionType",
  cardType: "CardType",
  stockRequestMethodType: "StockRequestMethodType",
  paymentStatus: "PaymentStatus",
  customerDeliverOrderStatus: "CustomerDeliverOrderStatus",
  payrollCategoryType: "PayrollCategoryType",
  purchaserType: "PurchaserType",
  stockRequestPurchaserType: "StockRequestPurchaserType",
  stockRequestStatus: "StockRequestStatus",
  stockReceiveStatus: "StockReceiveStatus",
  inboundNoticeStatus: "InboundNoticeStatus",
  purchaseOrderStatus: "PurchaseOrderStatus",
  purchaseOrderType: "PurchaseOrderType",
  purchasePaymentMethod: "PurchasePaymentMethod",
  createPurchaseOrderType: "CreatePurchaseOrderType",
  goodsReceiveNoteStatus: "GRNStatus",
  purchaseDeliverOrderStatus: "PurchaseDeliverOrderStatus",
  leaveType: "LeaveType",
  notificationType: "NotificationType",
  stockAdjustmentStatus: "StockAdjustmentStatus",
  stockInOutStatus: "StockInOutStatus",
  stockReturnToHQStatus: "HQStockReturnStatus",
  stockOutReason: "StockOutReason",
  controlType: "ControlType",
  formType: "FormType",
  paymentMethodOptionType: "PaymentMethodOptionType",
  transferAccount: "TransferAccount",
  eWalletType: "EwalletType",
  stockTakeStatus: "StockTakeStatus",
  debitType: "DebitType",
  auditLogEntityType: "AuditLogEntityType",
  auditType: "AuditType",
  customerSearchType: "CustomerSearchType",
  appointmentSourceType: "AppointmentSourceType",
  salesOrderStatus: "SalesOrderStatus",
  calendarViewType: "CalendarViewType",
  bookingSlotIntervalType: "BookingSlotIntervalType",
  promotionType: "PromotionType",
  redemptionCapType: "RedemptionCapType",
  exchangeToCreditStatus: "ExchangeToCreditStatus",
  returnRefundType: "ReturnRefundType",
  leadStatus: "LeadStatus",
  creditAdjustmentType: "CreditAdjustmentType",
  commissionEarnType: "CommissionEarnType",
  productCommissionType: "ProductCommissionType",
  transferCreditAdjustmentType: "TransferCreditAdjustmentType",
  leaveStatus: "LeaveStatus",
  leavePeriodType: "LeavePeriodType",
  stockMovementType: "StockMovementType",
  creditHistoryEntityType: "CreditHistoryEntityType",
  paymentAdjustmentStatus: "PaymentAdjustmentStatus",
  customerProductReturnType: "CustomerProductReturnType",
  customerProductReturnStatus: "CustomerProductReturnStatus",
  extendPackageStatus: "ExtendPackageStatus",
  salesAdjustmentType: "SalesAdjustmentType",
  createSalesAdjustmentType: "CreateSalesAdjustmentType",
  commissionSchemeType: "CommissionSchemeType",
  accessRequestStatus: "AccessRequestStatus",
  stockOutProductReasonType: "StockOutProductReasonType",
  dailySalesRecordType: "DailySalesRecordType",
  customerTransferStatus: "CustomerTransferStatus",
  stockLevelStatus: "StockLevelStatus",
  additionBonusType: "AdditionBonusType",
  virtualWarehouseStatus: "VirtualWarehouseStatus",
  productRedemptionStatus: "ProductRedemptionStatus",
  virtualWarehouseCreditExchangeStatus: "VirtualWarehouseCreditExchangeStatus",
  productRedemptionNoteReturnStatus: "ProductRedemptionNoteReturnStatus",
  batchJobRunningType: "BatchJobRunningType",
  subBranchRequestType: "SubBranchRequestType",
  customerSubBranchRequestStatus: "CustomerSubBranchRequestStatus",
  searchCustomerCriteriaType: "SearchCustomerCriteriaType",
  appolousSaleStatus: "AppolousSaleStatus",
  virtualWarehouseMovementType: "VirtualWarehouseMovementType",
  marcomRecordType: "MarcomRecordType",
  customerValidateType: "CustomerValidateType",
  dailySalesRecordTypeDropdownFilter: "DailySalesRecordTypeDropdownFilter",
  customerRelationshipType: "CustomerRelationshipType",
  defaultFollowUpRemark: "DefaultFollowUpRemark",
  leadPlatformType: "LeadPlatformType",
  machineConsumptionType: "MachineConsumptionType",
  machineUsageType: "MachineUsageType",
  clType: "CLType",
  earnSalesType: "EarnSalesType",
  exchangeType: "ExchangeType",
  none: "None",
  defaultDateFormat: "YYYY-MM-DD[T]HH:mm:ssZ",
  textInputDateFormat:"YYYY-MM-DD",
  textInputDateAndTimeFormat: "YYYY-MM-DD[T]HH:mm",
  displayDateFormat:"YYYY-MM-DD",
  displayYearAndMonthFormat:"YYYY-MM",
  displayTimeFormat: "hh:mm A",
  displayTime24HoursFormat: "HH:mm",
  displayTimeWithoutAFormat: "hh:mm",
  displayHourFormat: "HH",
  displayMinutesFormat: "mm",
  displayCompareTimeFormat: "HH:mm:ss",
  displayDateAndTimeFormat: "YYYY-MM-DD hh:mm A",
  displayDateAndTimeWithDayFormat: "ddd, Do MMMM, YYYY [at] hh:mma",
  displayDateTextInputFormat: "DD/MM/YYYY",
  displayDateForNote: "MMM DD, YYYY",
  displayDay: "dddd",
  displaySmallDay: "ddd",
  displayInputDateFormat: "DD/MM/YYYY",
  displayDateFormatV2: "DD-MM-YYYY",
  success: "SUCCESS",
  failed: "FAILED",
  service: "Service",
  amount: "Amount",
  percentage: "Percentage",
  package: 'Package',
  product: "Product",
  optionalProducts: "OptionalProducts",
  paid: 'Paid',
  partial: 'Partial',
  partialPayment: 'PartialPayment',
  void: 'Void',
  pending: 'Pending',
  received: 'Received',
  receive: 'Receive',
  partialReceived: 'PartialReceived',
  receiving: 'Receiving',
  sent: 'Sent',
  processing: 'Processing',
  acknowledge: 'Acknowledge',
  acknowledged: 'Acknowledged',
  approve: 'Approve',
  reject: 'Reject',
  save: 'Save',
  submit: 'Submit',
  complete: 'Complete',
  completed: 'Completed',
  partialComplete: "PartialComplete",
  partialCompleted: "PartialCompleted",
  rejected: 'Rejected',
  approved: 'Approved',
  pendingAllocation: 'PendingAllocation',
  expired: "Expired",
  reversed: "Reversed",
  searchBarWidth: "200px",
  actionButtonWidth: "120px",
  purchasable: "Purchasable",
  consumable: "Consumable",
  redeemable: "Redeemable",
  companyAssest: "CompanyAssest",
  machinery: "Machinery",
  notForSale: "NotForSale",
  emptyId: "00000000-0000-0000-0000-000000000000",
  invalidDate: "0001-01-01T00:00:00+00:00",
  invalidDateTime:"0001-01-01T00:00:00+00:00",
  invalidDateTimeWithValue: "0001-01-01T08:00:00+08:00",
  infiniteDateTime:"9999-12-31T00:00:00+08:00",
  infiniteDateTimeWithValue:"9999-12-31T23:59:59.9999999+00:00",
  emptyData: "N/A",
  emptyDataV2: "-",
  htmlButton: "HTMLButtonElement",
  tokenExpired: "token_expired",
  en: "en",
  cn: "cn",
  innerWidthCompare1440: 1400,
  innerWidthCompare: 1200,
  innerWidthCompare770: 770,
  innerWidthCompare472: 472,
  innerWidthCompare991: 991,
  hq: "HQ",
  outlet: "Outlet",
  unit: 'Unit',
  cancelled: "Cancelled",
  cancel: "Cancel",
  purchaseFromExternalSupplier: "PurchaseFromExternalSupplier",
  purchaseForOutlet: "PurchaseForOutlet",
  addition: "addition",
  deduction: "deduction",
  lightgreen: "#34c38f",
  lightgreenv2: "#afe7a8",
  lightgreenv3: "#daf2d0",
  lightsuperblue: '#50f1f1',
  lightblue: "#50a5f1",
  lightbluev2: "#96c9f6",
  lightbluev3: "#dae9f8",
  red: "#f46a6a",
  darkRed: "#990000",
  tomatoRed: "#d75442",
  darkTomatoRed: "#B04334",
  lightredfaded: "#f46a6a80",
  lightgrey: "#74788d",
  lightgreyfaded: "#74788d40",
  tableCellGrey: "#edf0f5",
  tableCellBorderGrey: "#E5E4E2",
  grey: "#646a87",
  orange: "#f1b44c",
  green: '#279c71',
  darkblue:'#508bf1',
  pink:'#e83e8c',
  verylightpurple: '#d2cae6',
  lightpurple: '#556ee6',
  midPurple: '#B4A7D6',
  lightpink: '#e83e8c60',
  lightorange:"#fed8b1",
  white: '#FFFFFF',
  placeholderColor: '#eff2f7',
  localBackgroundColor: "#e8e8ed50",
  brown: '#995c00',
  darkbrown: '#9e4d06',
  darkorange: '#ED872D',
  darkgreen: '#2b8a68',
  leadPink: '#FBE4FF',
  leadYellow: "#FFF5BA",
  blue: "#50b6f1",
  black: "#343a40",
  dark: "#000000",
  lightbluefaded: "#50a5f140",
  seagreen: "#5a9c7c",
  lightseagreen: "#70b895",
  lightseablue: "#4ba4d1",
  tabBlue: "#83acf2",
  candypink: "#de7c8e",
  violet: "#c965ed",
  olivine: "#c2995d",
  lavender:"#9480bd",
  savoryblue:"#4a77d9",
  persianblue: "#1a43bf",
  specialyellow: "#E7D27C",
  madisonblack: "#2c3e50",
  peach: "#e08283",
  golden: "#f3c200",
  heliotrope: "#c653ff",
  greyblue: "#578ebe",
  palepink: "#f0a3b1",
  chocalate: "#753f32",
  // validationError: process.env.NODE_ENV !== 'production' ? true : false,
  validationError: true,
  manageWithSpace: "Manage ",
  text: "Text",
  multilineText : "MultilineText",
  dropDownlist: "DropdownList",
  checkboxes: "Checkboxes",
  radioList: "RadioList",
  branch: "Branch",
  warehouse: "Warehouse",
  view: "View",
  add: "Add",
  update: "Update",
  delete: "Delete",
  ownBranchDisplayName: "Own Branch",
  ownBranchValue: "ownBranch",
  otherBranchDisplayName: "Other Branch",
  otherBranchValue: "otherBranch",
  preferredName: "PreferredName",
  customerForm: "CustomerForm",
  serviceRecord: "ServiceRecord",
  radio: "radio",
  checkbox: "checkbox",
  numeric: "Numeric",
  dateTime: "DateTime",
  date: "Date",
  time: "Time",
  string: 'string',
  array: 'array',
  open: "Open",
  followUp: "FollowUp",
  turnedUp: "TurnedUp",
  closed: "Closed",
  others: "Others",
  marComUserRoleId: "987250df-cd24-4582-9ec6-2271ee226407",
  marComCustomerServiceId: "b13bc576-16a3-4140-b44d-d76c8c1dc3e5",
  customerServiceId: "6f229f22-a795-4e00-9324-ba632ce442ad",
  consultantUserRoleId: "19724157-f4db-4be7-a121-918500db966e",
  branchManagerUserRoleId: "ddc5e91e-eb53-428b-90ee-f139360234dd",
  customerServiceConsultantPositionId: "b8746fd7-dd97-46f8-ad31-23a5d4c6216b",
  referralMediaSourceId: "ec6d933a-9830-4ea4-aea3-de5b6fe34036",
  supervisorId: "153f1625-8af6-4463-b8a0-8828ce61b963",
  customerCreditPaymentMethodId: "3eab8b93-7c05-4500-8f12-fdcd036bf6dc",
  transferCreditPaymentMethodId: "d9b4528a-5702-48a5-ae31-0a18602ddd6a",
  existing: "Existing",
  lead: "Lead",
  walkIn: "WalkIn",
  // blank: _blank,
  blank: "",
  supportBlank: "_blank",
  confirmed: "Confirmed",
  inServing: "InServing",
  runningLate: "RunningLate",
  notShow: "NotShow",
  inProgress: "InProgress",
  email: "Email",
  sms: "SMS",
  pushNotification: "PushNotification",
  secureMessage: "SecureMessage",
  byPeriod: "ByPeriod",
  year9999: "9999",
  present: "Present",
  paymentOrderForm: "payment-order-form",
  redemptionOrderForm: "redemption-order-form",
  productRedemptionNoteForm: "product-redemption-note-form",
  virtualWarehouseCreditExchangeForm: "virtual-warehouse-credit-exchange-form",
  appolousCustomerPackagePurchaseForm: "appolous-customer-package-purchase-form",
  creditRedemptionForm: "credit-redemption-form",
  packageProduct: "PackageProduct",
  packageOptionalProduct: "PackageOptionalProduct",
  packageService: "PackageService",
  productOnly: "ProductOnly",
  allServiceTreatment: "AllServiceTreatment",
  purchasedServiceTreatment: "PurchasedServiceTreatment",
  purchasedPackage: "PurchasedPackage",
  active: "Active",
  creditExchanged: "CreditExchanged",
  menuIconSize: 16,
  cashAdjustment: "CashAdjustment",
  adjustCredit: "AdjustCredit",
  creditToCash: "CreditToCash",
  salesOrderToCredit: "SalesOrderToCredit",
  payWithCredit: "PayWithCredit",
  creditExchangeSubmitted: "CreditExchangeSubmitted",
  serviceTreatmentOnly: "ServiceTreatmentOnly",
  appointmentMade: "AppointmentMade",
  promotion: "Promotion",
  identityNo: "identityNo",
  employeeNo: "employeeNo",
  selectWithTableListSelectButton: "SelectWithTableListSelectButton",
  selectWithTableListDeleteButton: "SelectWithTableListDeleteButton",
  currency: "currency",
  pendingApproval: "PendingApproval",
  dashLineChart: "DashLineChart",
  doughnutChart: "DoughnutChart",
  pieChart: "PieChart",
  barChart: "BarChart",
  branchStaff: "BranchStaff",
  weekly: "Weekly",
  month: "Monthly",
  returnAndExchange: "ReturnAndExchange",
  yes: "YES",
  no: "NO",
  returnAndRefund: "ReturnAndRefund",
  customerDeliveryOrder: "CustomerDeliveryOrder",
  customerProductReturn: "CustomerProductReturn",
  productReturnToCredit: "ProductReturnToCredit",
  contactNo: "contactNo",
  mobileNo: "mobileNo",
  supplierContactNo: "supplierContactNo",
  warehouseContactNo: "warehouseContactNo",
  emergencyContact: "emergencyContact",
  last4CardDigitNo: "last4CardDigitNo",
  customerDefectProductReturn: "CustomerDefectProductReturn",
  customerDefectExchange: "CustomerDefectExchange",
  owner: "Owner",
  customerCredit:"CustomerCredit",
  transferCredit:"TransferCredit",
  processingFees: "Processing Fees",
  request: "Request",
  review: "Review",
  hqStockReturn: "HQStockReturn",
  defectStockReturnToHQ: "DefectStockReturnToHQ",
  stockReceive: "StockReceive",
  outletStockPurchase: "OutletStockPurchase",
  unpaid: "Unpaid",
  report: "Report",
  ReportPermissionList: [PermissionConstants.ManageAppointmentReport, PermissionConstants.ManageCustomerReport,
    PermissionConstants.ManageInventoryReport, PermissionConstants.ManageManagementReport, PermissionConstants.ManageMarcomReport,
    PermissionConstants.ManagePaymentReport, PermissionConstants.ManageSalesReport, PermissionConstants.ManageServiceReport, PermissionConstants.ManageMasterDataReport],
  refundSalesOrderToCredit: "RefundSalesOrderToCredit",
  productReturnCredit: "ProductReturnCredit",
  manualAdjustment: "ManualAdjustment",
  purchaseCustomerCredit: "PurchaseCustomerCredit",
  customerCreditPayment: "CustomerCreditPayment",
  editOrCancelCustomerSalesOrderForm: "editOrCancelCustomerSalesOrderForm",
  hqStaff: "HQStaff",
  identityCard: "IdentityCard",
  passport: "Passport",
  manualStockOut: "ManualStockOut",
  appointmentDate: "AppointmentDate",
  submissionDate: "SubmissionDate",
  signed: "Signed",
  voidRequestSubmitted: "VoidRequestSubmitted",
  voided: "Voided",
  enter: "Enter",
  malaysia: "Malaysia",
  paymentAdjustment: "PaymentAdjustment",
  salesOrder: "SalesOrder",
  deliverOrder: "DeliverOrder",
  autoDeliverOrder: "AutoDeliverOrder",
  creditAdjustment: "CreditAdjustment",
  creditExchange: "CreditExchange",
  virtualWarehouse: "VirtualWarehouse",
  productRedemptionNote: "ProductRedemptionNote",
  virtualWarehouseCreditExchange: "VirtualWarehouseCreditExchange",
  customerTransfer: "CustomerTransfer",
  reversedVirtualWarehouse: "ReversedVirtualWarehouse",
  fullVersion: "FullVersion",
  customerVersion: "CustomerVersion",
  customerWithoutQuantityVersion: "CustomerWithoutQuantityVersion",
  healthy: "Healthy",
  moderate: "Moderate",
  low: "Low",
  payment: "Payment",
  contractLiability: "ContractLiability",
  encashFromCredit: "EncashFromCredit",
  dayOff: "DayOff",
  generated: "Generated",
  amended: "Amended",
  final: "Final",
  regenerate: "Regenerate",
  requestForAmend: "RequestForAmend",
  markAsFinal: "MarkAsFinal",
  resubmit: "ReSubmit",
  export: "Export",
  revertPayWithCredit: "RevertPayWithCredit",
  reversePurchaseCustomerCredit: "ReversePurchaseCustomerCredit",
  customerVirtualWarehouseCreditExchange: "CustomerVirtualWarehouseCreditExchange",
  productRedemptionNoteToCredit: "ProductRedemptionNoteToCredit",
  payAppolousWithCredit: "PayAppolousWithCredit",
  reversePayAppolousWithCredit: "ReversePayAppolousWithCredit",
  redemptionPendingSign: "RedemptionPendingSign",
  productRedemptionNoteReturn: "ProductRedemptionNoteReturn",
  StockRequestProductNotAvailableMessage: "Following requested product's stock is not available: ",
  customerSalesOrder: "customerSalesOrder",
  redemption: "redemption",
  creditTransaction: "creditTransaction",
  postedTransaction: "postedTransaction",
  dayOffWithSpace: "Off Day",
  referral: "Referral",
  resign: "ReSign",
  resignUpgrade: "Resign/Upgrade",
  consultation: "Consultation",
  closeProgram: "CloseProgram",
  exchangeToCredit: "ExchangeToCredit",
  customerAccessRequest: "CustomerAccessRequest",
  customerCreditAdjustment:"CustomerCreditAdjustment",
  customerNote:"CustomerNote",
  customerPaymentAdjustment: "CustomerPaymentAdjustment",
  extendPackage: "ExtendPackage",
  followUpHistory: "FollowUpHistory",
  salesAdjustmentHistory: "SalesAdjustmentHistory",
  dailySalesReportHistory: "DailySalesReportHistory",
  exceptionThrow: "ExceptionThrow",
  textAlphaNumericRegex: /^[a-zA-Z0-9\s]+$/,
  numberOnlyWithTwoDecimalPlacesRegex: /^\d*(,\d{3})*(\.\d{0,2})?$/ ,
  mobileNumber: "MobileNumber",
  individual: "Individual",
  mobileNoWithSpace: "Mobile No",
  submitForApproval: "SubmitForApproval",
  pendingFinalApproval: "PendingFinalApproval",
  approvedByFirstApprover: "ApprovedByFirstApprover",
  approvedBySecondApprover: "ApprovedBySecondApprover",
  rejectedByFirstApprover: "RejectedByFirstApprover",
  rejectedBySecondApprover: "RejectedBySecondApprover",
  branchCommission: "BranchCommission",
  boldFontWeight: 700,
  normalFontWeight: 400,
  followUpOnly: "FollowUpOnly",
  extendExpiry: "ExtendExpiry",
  reactivate: "Reactivate",
  redeem: "Redeem",
  close: "Close",
  edit: "Edit",
  emptyLast4CardDigitNo: "0000",
  upgrade: "Upgrade",
  appolousSale: "AppolousSale",
  manualDeductionAdjustment: "ManualDeductionAdjustment",
  manualAdditionAdjustment: "ManualAdditionAdjustment",
  draft: "Draft",
  dropdownSearchAPITimeout: 800,
  dropdownSearchValueLength: 3,
  stockTransfer: "StockTransfer",
  supportSingleRedeem: false,
  byAmount: "ByAmount",
  arrived: "Arrived",
  special: "Special",
  activeTabWidth: '80px',
  supportMalaysiaRounding: true,
  pendingSecondApproval: "PendingSecondApproval",
  rejectedByFinalApprover: "RejectedByFinalApprover",
  approvedByFinalApprover: "ApprovedByFinalApprover",
  cancellation: "Cancellation",
  purchaseDeliver: "PurchaseDeliver",
  group: "Group",
  newJoin: "NewJoin",
  newVisit: "NewVisit",
  appolousProductRedemption: "AppolousProductRedemption",
  reverseAppolousProductRedemption: "ReverseAppolousProductRedemption",	
  copy: "Copy",
  parent: "Parent",
  nikeSymbol: '✔',
  xSymbol: '✗',
  changePaymentMethod: "ChangePaymentMethod",
  signUp: 'SignUp',
  newJoinOnly: "NewJoinOnly",
  skip: 'Skip',
  apply: "Apply",
  generate: "Generate",
  byDuration: "ByDuration",
  validationErrorActualMessage: true,
  EmployeePositionMovementErrorMessage: "Employee-035",
  EmployeeBranchTransferErrorMessage: "Employee-036",
  tncCategoryType: "TncCategoryType",
  invoice: "Invoice",
  pendingAssign: "PendingAssign",
  knockOff: "KnockOff",
  unknownPaymentStatus: "UnknownPaymentStatus",
  submitted: "Submitted",
  cashOutStatus: "CashOutStatus",
  cashOutTransactionType: "CashOutTransactionType",
  changePaymentModeStatus: "ChangePaymentModeStatus",
  pendingSign: "PendingSign",
  pendingFirstApproval: "PendingFirstApproval",
  reviseRequired: "ReviseRequired",
  rejectAndRevise: "RejectAndRevise",
  closeCounterStatus: "CloseCounterStatus",
  overridden: "Overridden",
  preview: "Preview",
  onlineTransferId: "325ac1a3-3747-4b58-8d6f-b27d8c21f13e",
  chequeId: "879fff5b-38d8-4122-8255-e0c5d8380143",
  ambankDuitNowId: "495ebdc6-f177-4e41-85ca-f4a13389bf0f",
  creditPaymentType: "CreditPaymentType",
  creditType: "CreditType",
  credit: "Credit",
  productCredit: "ProductCredit",
  exchangedCredit: "ExchangedCredit",
  refund: "Refund",
  pendingKnockOff: "PendingKnockOff",
  normalCredit: "NormalCredit",
  government: "Government",
  business: "Business",
  taxEntityBusinessType: "TaxEntityBusinessType",
  taxEntityIdentityType: "TaxEntityIdentityType",
  msic: "0",
  state: "1",
  country: "2",
  returnRedemption: "ReturnRedemption",
  creditRedemptionNoteRequestStatus: "CreditRedemptionNoteRequestStatus",
  returnRedemptionSubmitted: "ReturnRedemptionSubmitted",
  returnedRedemption: "ReturnedRedemption",
  noTin: "No TIN",
  noTinValue: " ",
  creditRedemption: "CreditRedemption",
  pageSizeForReferenceDocuments: 5,
  customerRedemption: "CustomerRedemption",
  creditRedemptionNote: "CreditRedemptionNote",
  productRedemptionNoteCreditExchange: "ProductRedemptionNoteCreditExchange",
  customerTransferRequest: "CustomerTransferRequest",
  customerSubBranchRequest: "CustomerSubBranchRequest",
  creditNote: "CreditNote",
  refundNote: "RefundNote",
  right: "RIGHT",
  down: "DOWN",
  flowCardWidth: 280,
  flowCardHeight: 80,
  creditNoteStatus: "CreditNoteStatus",
  refundSubmitted: "RefundSubmitted",
  refunded: "Refunded",
  approvedForProcessing: "ApprovedForProcessing",
  refundNoteStatus: "RefundNoteStatus",
  approveForProcessing: "ApproveForProcessing",
  rejectOnly: "RejectOnly",
  creditRedemptionNoteRequest: "CreditRedemptionNoteRequest"
}
