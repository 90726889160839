import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, displayPromotionCode, displayWatermark, escapedNewLineToLineBreakTag, getBranchId, getBranchUser, getPurchaseTypeIcon, IncludesLocationPathName, paymentStatusColor, returnPriceWithCurrency } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip, Form, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import MyButton from "../../app/components/form/MyButton";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";
import { useLocation } from "react-router-dom";

const ReceiptDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { customerSalesOrderStore, branchStore, employeeStore, settingsStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setLoading, setErrorMessage, setSuccessMessage } = commonStore;
  const { customerSalesOrderReceiptDetail, setCustomerSalesOrderReceiptDetail, getCustomerSalesOrderReceipt, getCustomerSalesOrderPaymentHistory } = customerSalesOrderStore;
  const { employeeDropdownList, setEmployeeDropdownList, getEmployeeDropdown } = employeeStore;
  const { salesSettings, getSalesSettings } = settingsStore;
  const location = useLocation();
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Receipt" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CustomerSalesOrder" }), urlPath: RoutesList.customerSalesOrder }];
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer])

  const receiptFullDetail = customerSalesOrderReceiptDetail ||  {
    id: "",
    isDelete: false,
    paymentDate: "",
    paymentReceiptNo: "",
    businessEntityId: "",
    brandName: "",
    businessEntityName: "",
    companyNumber: "",
    branchId: "",
    branchName: "",
    branchAddress: "",
    branchContactNo: "",
    customerId: "",
    customerName: "",
    customerNo: "",
    customerAddress: "",
    customerMobileNo: "",
    subTotal: 0,
    total: 0,
    serviceBy: "",
    note: "",
    paymentBranchName: "",
    paymentBranchAddress: "",
    paymentBranchContactNo: "",
    paymentReceiptDetails: [],
  }

  const blnHQStaff = !getBranchUser();
  const [showSignaturePad, setShowSignaturePad] = useState(true);

  async function fetchReceiptDetailAPI(blnCallEmployee = false) {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getSalesSettings()
      ];

      let blnFoundBlnSalesAdjustment= false;
      if (location.state) {
        if (location.state.blnSalesAdjustment) {
          blnFoundBlnSalesAdjustment = true;
          aryAPI.push(getCustomerSalesOrderReceipt(id))
        }
      }

      if (!blnFoundBlnSalesAdjustment) {
        aryAPI.push(getCustomerSalesOrderPaymentHistory(id))
      }
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCustomerSalesOrder], true)) {
      return;
    }

    fetchReceiptDetailAPI(true);

    return (() => {
      if (id && !addAction) {
        setCustomerSalesOrderReceiptDetail(undefined);
      }
    })
  }, []);
  
  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <Col xl="8">
          <h6 className="m-0 text-end">
            {label}
          </h6>
        </Col>
        <Col xl="4">
          <h6 className="text-end">
            {value}
          </h6>
        </Col>
      </Row>
    )
  }

  const displayCustomRowData = (colSpan, label, value) => {
    return (
      <tr>
        <td colSpan={colSpan} />
        <td className="text-end" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="receipt-title margin-bottom-24">
                    <Row style={{ alignItems: 'center' }}>
                      <Col>
                        <div className="logo-lg" style={{ lineHeight: '20px' }}>
                          <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                            {intl.formatMessage({ id: "Receipt" })}
                          </span>
                          <br />
                          <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold">
                            {receiptFullDetail.brandName}
                          </h3>
                          <h3 className="margin-bottom-0 font-weight-bold" style={{ fontSize: '14px', marginTop: '5px' }}>
                            {`${receiptFullDetail.businessEntityName} ${receiptFullDetail.companyNumber ? `(${receiptFullDetail.companyNumber})` : ""}`}
                          </h3>
                          <h6 className="margin-bottom-0" style={{ fontSize: '13px' }}>
                            {receiptFullDetail.paymentBranchName}
                          </h6>
                          <h6 className="margin-bottom-0" style={{ fontSize: '13px' }}>
                            {receiptFullDetail.paymentBranchAddress}
                          </h6>
                          {/* <br/> */}
                          <h6 className="margin-bottom-0" style={{ fontSize: '13px' }}>
                            TEL: {receiptFullDetail.paymentBranchContactNo}
                          </h6>
                        </div>
                      </Col>
                      <Col>
                        {displayHeaderRowData(`${intl.formatMessage({ id: "ReceiptNo" })}:`, receiptFullDetail.paymentReceiptNo)}
                        {displayHeaderRowData(`${intl.formatMessage({ id: "Date" })}:`, moment(receiptFullDetail.paymentDate).format(Constants.displayDateFormat))}
                        {displayHeaderRowData(`${intl.formatMessage({ id: "Time" })}:`, moment(receiptFullDetail.paymentDate).format(Constants.displayTimeFormat))}
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <Row className="margin-top-24">
                    <Col xs="6">
                      <address>
                        <strong style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>{intl.formatMessage({ id: "Customer" })}:</strong>
                        <br />
                        <div className="margin-top-12"/>
                        {
                          blnPermissionViewCustomer ?
                            <Link to={receiptFullDetail.customerId ? `/${RoutesList.customer}/view/${receiptFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                              <Label className="margin-bottom-0 pointer-clickable">{receiptFullDetail.customerName || Constants.emptyData}</Label>
                            </Link>
                          :
                          <span >{`${receiptFullDetail.customerName}`}</span> 
                        }
                        <br />
                        <span>{`${receiptFullDetail.customerMobileNo}`}</span>
                        <br />
                        <div style={{width: '50%'}}>
                          <span>{`${receiptFullDetail.customerAddress}`}</span>
                        </div>
                      </address>
                    </Col>
                    <Col xs="6">
                      <address>
                        <strong style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>{intl.formatMessage({ id: "ServiceBy" })}:</strong>
                        <br />
                        <div className="margin-top-12"/>
                        <span>{`${receiptFullDetail.serviceBy}`}</span>
                      </address>
                    </Col>
                  </Row>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 font-weight-bold">
                      {intl.formatMessage({ id: "PaymentSummary" })}
                    </h3>
                  </div>
                    {/* watermark */}
                    {displayWatermark()}
                  <div className="table-responsive">
                    <Table className="table-fixed" style={{ width: '100%' }}>
                      <thead className="table-light">
                        <tr>
                          <th>{intl.formatMessage({ id: "Name" })}</th>
                          <th>{intl.formatMessage({ id: "PaymentMethod" })}</th>
                          <th className="text-center">{intl.formatMessage({ id: "TransactionNo"})}/{intl.formatMessage({ id: "ApprovalCode"})}</th>
                          <th className="text-end" style={{width: "150px"}}>{intl.formatMessage({ id: "Amount" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {receiptFullDetail.paymentReceiptDetails && receiptFullDetail.paymentReceiptDetails.length > 0 &&
                          receiptFullDetail.paymentReceiptDetails.map((item, key) => (
                            <tr key={key}>
                              <td>{item.name}</td>
                              <td>
                                <p className={`margin-bottom-0`}>{`${item.paymentMethodName}${item.cardType ? ` (${item.cardType} | ${item.last4CardDigitNo})` : ""}`}</p>
                                <p className="payment-method-sub-text">{`${item?.optionName ? `${item.optionName}` : ""}${item.paymentIssuerBankName ? ` - ${item.paymentIssuerBankName}` : ""}`}</p>
                              </td>
                              <td className="text-center">{item.transactionNo || Constants.emptyData}</td>
                              <td className="text-end">{returnPriceWithCurrency(item.paidAmount)}</td>
                            </tr>
                          ))
                        }
                        {
                          <>
                            <tr>
                              <td colSpan={2} />
                              <td className="text-end" style={{ fontWeight: 'bold' }}>
                                <div>                                  
                                  { `${intl.formatMessage({ id: "SubTotal" })}`}
                                </div>
                                <div>                                  
                                  { `${intl.formatMessage({ id: "Tax" })}`}
                                </div>
                              </td>
                              <td className="text-end">
                                <div>
                                  {`${returnPriceWithCurrency(receiptFullDetail.subTotal)}`}
                                </div>
                                <div>
                                  {`${returnPriceWithCurrency(0)}`}
                                </div>
                              </td>
                            </tr>
                            {displayCustomRowData(2, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(receiptFullDetail.total))}
                          </>
                        }
                      </tbody>
                    </Table>
                  </div>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 font-weight-bold">
                      {intl.formatMessage({ id: "Note" })} :
                    </h3>
                  </div>
                  <GeneralTextAreaForModal
                    title=""
                    name="note"
                    className="margin-top-0"
                    row={5}
                    disabled={true}
                    field={receiptFullDetail}
                    setFieldValue={() => { }} />
                  <p className="text-center margin-top-16" style={{fontSize: '14px'}}>{salesSettings?.receiptExtraInfo ? escapedNewLineToLineBreakTag(salesSettings.receiptExtraInfo) : ""}</p>
                  <div className="d-print-none flex-direction-row">
                    <GeneralSubmitAndCancelBtn
                      className="width-100-percentage"
                      successMessage={successMessage}
                      viewAction={false}
                      hidePrimaryButton={true}
                      showPrint={true}
                      cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                      cancelButtonBesidePrint={true}
                      cancelButtonFunction={()=> {
                        history.goBack();
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(ReceiptDetail);
