import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { IncludesLocationPathName, customerSubBranchRequestStatusColor, checkPermission, getBranchId, newSpaceBeforeCapitalLetter, exchangeToCreditStatusColor, deliveryOrderStatusColor, salesOrderStatusColor, creditRedemptionNoteRequestStatusColor } from "../../app/common/function/function";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Form, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import MyButton from "../../app/components/form/MyButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import Borderline from "../../app/components/form/BorderLine";
import SignaturePad from "../../app/components/form/SignaturePad";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import CancelModal from "../../app/components/modal/CancelModal";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import ReferenceDocumentTransactionModal from "../ReferenceDocument/Modal/ReferenceDocumentTransactionModal";

const CustomerSubBranchRequestDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { customerSubBranchRequestStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setLoading,
    setErrorMessage,
    setSuccessMessage,
    setShowCancelPrompt,
  } = commonStore;
  const { customerSubBranchRequestDetail, setCustomerSubBranchRequestDetail, getCustomerSubBranchRequestWithId, updateCustomerSubBranchRequest, reviewCustomerSubBranchRequest } = customerSubBranchRequestStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CustomerSubBranchRequest" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Requests" }), urlPath: RoutesList.customerSubBranchRequest }, { title: intl.formatMessage({ id: "CustomerSubBranchRequest" }), urlPath: RoutesList.customerSubBranchRequest }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnReferenceDocumentTransactionModal, setBlnReferenceDocumentTransactionModal] = useState(false);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionViewCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionViewCustomerDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const blnPermissionViewExchangeToCredit = checkPermission([PermissionConstants.ManageExchangeToCredit]);
  const blnPermissionUpdateCustomerDeliverOrder = checkPermission([PermissionConstants.UpdateCustomerDeliverOrder]);
  const blnPermissionManageCreditRedemptionNoteRequest = checkPermission([PermissionConstants.ManageCreditRedemptionNoteRequest]);
  const blnPermissionManageCreditRedemptionNote = checkPermission([PermissionConstants.ManageCreditRedemptionNote]);
  const blnPermissionManageRefundNote = checkPermission([PermissionConstants.ManageRefundNote]);

  const customerTransferFullDetail = customerSubBranchRequestDetail || {
    id: "",
    fromSubBranchId: "",
    fromSubBranchName: "",
    customerId: "",
    customerName: "",
    toSubBranchId: "",
    toSubBranchName: "",
    subBranchConsultantId: "",
    subBranchConsultantName: "",
    requestBranchId: "",
    requestBranchName: "",
    requestType: "",
    signatureImage: "",
    signedDate: "",
    requestReason: "",
    status: "",
    actionBy: "",
    actionOn: "",
    reviewComment: "",
    pendingRequests: []
  }

  const pendingRequestColumns = [
    {
      dataField: "requestNo",
      text: intl.formatMessage({ id: "RequestNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        row.pendingRequestType === Constants.salesOrder && blnPermissionViewCustomerSalesOrder
        ?
        <Link to={`/${RoutesList.customerSalesOrder}/view/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.deliverOrder && blnPermissionViewCustomerDeliverOrder
        ?
        <Link to={`/${RoutesList.customerRedemption}/${blnPermissionUpdateCustomerDeliverOrder ? "edit" : "view"}/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.creditExchange && blnPermissionViewExchangeToCredit
        ?
        <Link to={`/${RoutesList.exchangeToCredit}/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.returnRedemption && blnPermissionViewExchangeToCredit
        ?
        <Link to={`/${RoutesList.returnRedemption}/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.creditRedemptionNoteRequest && blnPermissionManageCreditRedemptionNoteRequest
        ?
        <Link to={`/${RoutesList.creditRedemptionNoteRequest}/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.creditRedemptionNote && blnPermissionManageCreditRedemptionNote
        ?
        <Link to={`/${RoutesList.creditRedemptionNote}/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        row.pendingRequestType === Constants.refundNote && blnPermissionManageRefundNote
        ?
        <Link to={`/${RoutesList.refundNote}/${row.id}`} target={Constants.blank} rel="noopener noreferrer" className="pointer-clickable">
          {row.requestNo}
        </Link>
        :
        `${row.requestNo}`
      )
    },
    {
      dataField: "pendingRequestType",
      text: intl.formatMessage({ id: "PendingRequestType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.pendingRequestType) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "requestStatus",
      text: intl.formatMessage({ id: "RequestStatus" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.requestStatus}
          statusColorFunction={
            row.pendingRequestType === Constants.creditExchange || row.pendingRequestType === Constants.returnRedemption ? exchangeToCreditStatusColor 
            : 
            row.pendingRequestType === Constants.deliverOrder ? deliveryOrderStatusColor
            :
            row.pendingRequestType === Constants.creditRedemptionNoteRequest ? creditRedemptionNoteRequestStatusColor
            :
            salesOrderStatusColor
          }/>
      )
    },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: customerSubBranchRequestDetail || {
      id: "",
      fromSubBranchId: "",
      fromSubBranchName: "",
      customerId: "",
      customerName: "",
      toSubBranchId: "",
      toSubBranchName: "",
      subBranchConsultantId: "",
      subBranchConsultantName: "",
      requestBranchId: "",
      requestBranchName: "",
      requestType: "",
      signatureImage: "",
      signedDate: "",
      requestReason: "",
      status: "",
      actionBy: "",
      actionOn: "",
      reviewComment: "",
      pendingRequests: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);

      let valuesFinal = {
        id: valuesTemp.id,
        signatureImage: valuesTemp.signatureImage,
        requestReason: valuesTemp.requestReason,
        reviewComment: valuesTemp.reviewComment,
        workflowAction: valuesTemp.workflowAction,
      }

      if (valuesTemp.workflowAction === Constants.submit && !valuesTemp.signatureImage) {
        validation.setFieldValue("signatureError", true)
        setErrorMessage(intl.formatMessage({ id: "CustomerSubBranchRequest-005" }))
        return;
      }
      try {
        if (valuesTemp.workflowAction === Constants.submit || valuesTemp.workflowAction === Constants.cancel) { 
          await updateCustomerSubBranchRequest(valuesFinal);
        }
        else {
          await reviewCustomerSubBranchRequest(valuesFinal);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const blnPermissionUpdateCustomerSubBranchRequest = checkPermission([PermissionConstants.UpdateCustomerSubBranchRequest]);
  const blnPermissionReviewCustomerSubBranchRequest = checkPermission([PermissionConstants.ReviewCustomerSubBranchRequest]);
  const blnSignedStatus = customerTransferFullDetail.status === Constants.signed;
  const blnApprovedOrRejectedStatus = (customerTransferFullDetail.status === Constants.approved || customerTransferFullDetail.status == Constants.rejected);
  const blnPendingStatus = customerTransferFullDetail.status === Constants.pending;
  const blnCancelledStatus = customerTransferFullDetail.status === Constants.cancelled;
  const blnIsSameBranchUser = customerTransferFullDetail.requestBranchId === getBranchId();
  const blnIsToSubBranchUser = customerTransferFullDetail.toSubBranchId === getBranchId();
  const blnIsFromSubBranchUser = customerTransferFullDetail.fromSubBranchId === getBranchId();
  const blnPendingRequestExist = customerTransferFullDetail.pendingRequests && customerTransferFullDetail.pendingRequests.length > 0;
  const blnDisplayApprovalButton = blnSignedStatus && (blnPermissionReviewCustomerSubBranchRequest && (blnIsToSubBranchUser || blnIsFromSubBranchUser)) && !blnPendingRequestExist;

  async function fetchCustomerSubBranchRequestDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getCustomerSubBranchRequestWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!viewAction) {
      if (!checkPermission([PermissionConstants.UpdateCustomerSubBranchRequest, PermissionConstants.ReviewCustomerSubBranchRequest], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageCustomerSubBranchRequest], true)) {
        return;
      }
    }

    fetchCustomerSubBranchRequestDetailAPI();

    return (() => {
      if (id && !addAction) {
        setCustomerSubBranchRequestDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (customerSubBranchRequestDetail) {
      if (customerSubBranchRequestDetail.status == Constants.pending && viewAction && blnPermissionUpdateCustomerSubBranchRequest && blnIsSameBranchUser) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.customerSubBranchRequest}/edit/${id}`) } })
      }

      let indexViewContractLiabilityTransaction = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewContractLiabilityTransaction" })}`})
      if (viewAction && indexViewContractLiabilityTransaction < 0) {
        burgerMenuListContent.push({
          label: `${intl.formatMessage({ id: "ViewContractLiabilityTransaction" })}`,
          onFunction: () => {
            setBlnReferenceDocumentTransactionModal(true);
          }
        })
      }
    }
  }, [customerSubBranchRequestDetail])

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <Row>
                        <Col>
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "CustomerSubBranchRequest" })}
                            </span>
                            <br />
                            <br />
                          </div>
                        </Col>
                      </Row>
                      <Col>
                        {displayHeaderRowData(`${intl.formatMessage({ id: "Branch" })}:`, customerTransferFullDetail.requestBranchName)}
                        {
                          blnApprovedOrRejectedStatus
                          &&
                          <>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ActionBy" })}:`, customerTransferFullDetail.actionBy)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ActionOn" })}:`, customerTransferFullDetail.actionOn ?  moment(customerTransferFullDetail.actionOn).format(Constants.displayDateFormat) : Constants.emptyData)}
                          </>
                        }
                      </Col>
                    </div>
                    <address>
                      <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                      <br />
                      <div style={{ marginTop: '8px' }}>
                        <GeneralStatus
                          status={customerTransferFullDetail.status}
                          statusColorFunction={customerSubBranchRequestStatusColor}
                          blnDisableCenter />
                      </div>
                    </address>
                    <Borderline />
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "CustomerDetail" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed" style={{ width: '100%' }}>
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "Name" })}</th>
                            <th>{intl.formatMessage({ id: "OriginalSubBranch" })}</th>
                            <th>{intl.formatMessage({ id: "NewSubBranch" })}</th>
                            <th>{intl.formatMessage({ id: "SubBranchConsultant" })}</th>
                            <th>{intl.formatMessage({ id: "Type" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {
                                blnPermissionViewCustomer
                                ?
                                <Link to={customerTransferFullDetail.customerId ? `/${RoutesList.customer}/view/${customerTransferFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0">{`${customerTransferFullDetail.customerName}`}</Label>
                                </Link>
                                :
                                `${customerTransferFullDetail.customerName}`
                              }
                            </td>    
                            <td>{customerTransferFullDetail.fromSubBranchName || Constants.emptyData}</td>
                            <td>{customerTransferFullDetail.toSubBranchName || Constants.emptyData}</td>
                            <td>{customerTransferFullDetail.subBranchConsultantName || Constants.emptyData}</td>
                            <td>{customerTransferFullDetail.requestType || Constants.emptyData}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    {
                      blnPendingRequestExist && blnSignedStatus
                      &&
                      <div>
                        <div className="py-2 mt-3">
                          <h3 className="font-size-15 font-weight-bold">
                            <span style={{color: 'hotpink'}} className="margin-bottom-0">{`*`}{intl.formatMessage({ id: "PendingRequests" })}</span> 
                          </h3>
                          <p>{intl.formatMessage({id: "ReviewPendingRequestsForSubBranch"})}</p>
                        </div>
                        <div className="">
                          <PaginationTableWithoutApi
                            title={intl.formatMessage({ id: "PendingRequests" })}
                            headerClassName={"table-pink"}
                            keyField={"id"}
                            columns={pendingRequestColumns}
                            pageSize={5}
                            options={validation.values.pendingRequests || []}
                            blnDisplayForMainListing={true} />
                        </div>
                      </div>
                    }
                    <div className={`py-2 mt-3`}>
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "ReasonForRequest" })} :
                      </h3>
                    </div>
                    <GeneralTextArea
                      title=""
                      name="requestReason"
                      row={5}
                      disabled={viewAction || !blnPermissionUpdateCustomerSubBranchRequest || Boolean(successMessage) || (blnPendingStatus && !blnIsSameBranchUser) || validation.isSubmitting}
                      validation={validation} />
                    {
                      blnPermissionReviewCustomerSubBranchRequest &&
                      (blnApprovedOrRejectedStatus || blnSignedStatus) &&
                      <>
                        <div className={`py-2 mt-3`}>
                          <h3 className="font-size-15 font-weight-bold">
                            {intl.formatMessage({ id: "ReviewComment" })} :
                          </h3>
                        </div>
                        <GeneralTextArea
                          title=""
                          name="reviewComment"
                          row={5}
                          disabled={blnApprovedOrRejectedStatus || !blnPermissionReviewCustomerSubBranchRequest || Boolean(successMessage) || (!blnIsToSubBranchUser && !blnIsFromSubBranchUser) || validation.isSubmitting || blnPendingRequestExist}
                          validation={validation} />
                      </>
                    }
                    <div className="margin-top-24" />
                    {!blnCancelledStatus &&
                      <SignaturePad
                        title={intl.formatMessage({ id: "CustomerSignature" })}
                        successMessage={successMessage}
                        validation={validation}
                        disabled={blnSignedStatus || Boolean(successMessage) || viewAction || (blnPendingStatus && !blnIsSameBranchUser)}
                        isSubmitting={validation.isSubmitting} />
                    }
                    <hr className="margin-top-48" />
                    <div className="flex-direction-row d-print-none">
                      {
                        blnPermissionUpdateCustomerSubBranchRequest && blnIsSameBranchUser && blnSignedStatus && !Boolean(successMessage)
                        &&
                        <MyButton
                          type="button"
                          class="btn btn-warning margin-right-8"
                          content={intl.formatMessage({ id: "Cancel" })}
                          onClick={() => {
                            setShowCancelPrompt(true)
                            // validation.setFieldValue("workflowAction", Constants.cancel)
                          }}
                          disable={validation.isSubmitting}
                          loading={validation.isSubmitting}
                        />
                      }
                      {
                        blnPendingStatus
                        &&
                        blnIsSameBranchUser
                        &&
                        !viewAction
                        &&
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={viewAction}
                          validation={validation}
                          dirtyDisabled={true}
                          hidePrimaryButton={!blnPermissionUpdateCustomerSubBranchRequest || !blnIsSameBranchUser  || !blnPendingStatus}
                          showPrint={true}
                          submitButtonTitle={intl.formatMessage({ id: "Submit" })}
                          onClickFunction={() => {
                            validation.setFieldValue("workflowAction", Constants.submit)
                          }}
                          extraRightButtonColor="btn-warning"
                          extraRightButtonTitle={intl.formatMessage({ id: "Cancel" })}
                          extraRightButtonFunction={() => {setShowCancelPrompt(true)}}
                          extraRightDirtyDisabled={true}
                          extraRightButtonWithoutForm={true}
                          showExtraRightButton={!Boolean(successMessage) && blnPendingStatus && blnPermissionUpdateCustomerSubBranchRequest && !viewAction}
                        />
                      }
                      {
                        viewAction
                        &&
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          validation={validation}
                          dirtyDisabled={true}
                          showPrint={true}
                          extraLeftDirtyDisabled={true}
                          showExtraLeftButton={blnDisplayApprovalButton}
                          extraLeftButtonTitle={intl.formatMessage({ id: "Approve" })}
                          extraLeftButtonColor="btn-success"
                          extraLeftButtonFunction={() => {
                            validation.setFieldValue("workflowAction", Constants.approve)
                          }}
                          submitButtonTitle={intl.formatMessage({ id: "Reject" })}
                          submitButtonColor="btn-danger"
                          hidePrimaryButton={!blnDisplayApprovalButton}
                          onClickFunction={() => {
                            validation.setFieldValue("workflowAction", Constants.reject)
                          }}
                        />
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
        <CancelModal
          onCancelClick={async () => {
            validation.setFieldValue("workflowAction", Constants.cancel);
            validation.submitForm();
          }} />
      </DetailViewLayout>
      {
        blnReferenceDocumentTransactionModal
        &&
        <ReferenceDocumentTransactionModal
          blnShow={blnReferenceDocumentTransactionModal}
          setModal={setBlnReferenceDocumentTransactionModal}
          documentId={id}
          documentType={Constants.customerSubBranchRequest}/>
      }
    </div>
  );
};

export default observer(CustomerSubBranchRequestDetail);
