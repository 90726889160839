import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Label,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import moment from "moment";
import { checkPermission, extendPackageStatusColor, returnCurrency, returnPriceWithCurrency, getBranchUser, newSpaceBeforeCapitalLetter, getBranchName, getBranchId, arraySpliceInAllValue } from "../../app/common/function/function";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { ObjectForDropdown } from "../../app/models/common";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import MyButton from "../../app/components/form/MyButton";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
// import ExtendPackageDetailModal from "./Modal/ExtendPackageDetailModal";
import { ExtendPackageListObject } from "../../app/models/extendPackage";
import DeleteModal from "../../app/components/modal/DeleteModal";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import ExtendPackageDetailModal from "./Modal/ExtendPackageDetailModal";
import MyAddButton from "../../app/components/form/MyAddButton";

const ExtendPackage = () => {
  const intl = useIntl();
  //Use Store
  const { extendPackageStore, branchStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setErrorMessage, setSuccessMessage, setShowDeletePrompt } = commonStore;
  const { extendPackageList, getExtendPackage, getExtendPackageWithId, reviewExtendPackage, requestExtendPackage, deleteExtendPackage } = extendPackageStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { extendPackageStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedExtendPackageStatus, setSelectedExtendPackageStatus] = useState<number | string>(AllStatus);
  const [selectedExtendPackageStatusId, setSelectedExtendPackageStatusId] = useState<number | string | undefined>(" ");
  const [extendPackageStatusList, setExtendPackageStatusList] = useState<StaticReferenceDataObject[]>([])
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const allBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : allBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const [selectedRow, setSelectedRow] = useState<ExtendPackageListObject | undefined>(undefined);
  const [extendPackageDetailModal, setExtendPackageDetailModal] = useState(false);
  const [selectedExtendPackageDetail, setSelectedExtendPackageDetail] = useState<any>([]);
  const allCustomer = `${intl.formatMessage({ id: "AllCustomer" })}`;
  const [selectedCustomer, setSelectedCustomer] = useState<string>(allCustomer);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const blnPermissionDeleteExtendPackage = checkPermission([PermissionConstants.DeleteExtendPackage]);
  const blnPermissionRequestExtendPackage = checkPermission([PermissionConstants.RequestExtendPackage]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const [blnAction, setBlnAction] = useState("");

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    async function fetchExtendPackageListAPI() {
      setLocalLoading(true)
      let aryAPI: any = [
        fetchExtendPackageAPI(false),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.extendPackageStatus, AllStatus),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? allBranch : "", true))
      setExtendPackageStatusList(resultAPI[2]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageExtendPackage], true)) {
      return;
    }

    fetchExtendPackageListAPI();
  }, [])

  useEffect(() => {
    if ((selectedExtendPackageStatusId || selectedExtendPackageStatusId === 0) && !initialCallAPI) {
      fetchExtendPackageAPI(true);
    }
  }, [selectedExtendPackageStatusId])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (
      (selectedBranchId) && !initialCallAPI) {
      getCustomerDropdownFunction();
      fetchExtendPackageAPI(true, Constants.emptyData);
    }
  }, [selectedBranchId]);

  useEffect(() => {
    if (!initialCallAPI && displayCustomerDropdown) {
      fetchExtendPackageAPI(true);
    }
  }, [selectedCustomerId])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setExtendPackageDetailModal(false);
        fetchExtendPackageAPI(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  async function fetchExtendPackageAPI(blnDropDown?: boolean, customerId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getExtendPackage({
      pageNumber: currentPage,
      pageSize: pageSize,
      extendPackageStatus: selectedExtendPackageStatusId,
      branchId: selectedBranchId,
      customerId: customerId === Constants.emptyData ? "" : selectedCustomerId,
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: ExtendPackageListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickViewDetail = async (extendPackageId: any) => {
    setLoading(true);
    let resultPreviewRefundCredit = await getExtendPackageWithId(extendPackageId);
    setSelectedExtendPackageDetail(resultPreviewRefundCredit);
    setBlnAction(Constants.review);
    setExtendPackageDetailModal(true);
    setLoading(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const ExtendPackageColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "extendPackageNo",
      text: intl.formatMessage({ id: "ExtendPackageNo" }).toUpperCase(),
      style: {
        width: "15%"
      },
    },
    {
      dataField: "requestedDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.requestedDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.requestedDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "actionUserName",
      text: intl.formatMessage({ id: "ActionBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.actionUserName || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "ActionDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.actionDate !== Constants.invalidDateTime ? moment(row.actionDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.actionDate !== Constants.invalidDateTime ? moment(row.actionDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={extendPackageStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to="#"
              onClick={() => onClickViewDetail(row.id)}
              type="viewModal" />
            {
              blnPermissionDeleteExtendPackage
              &&
              row.status == Constants.processing
              &&
              <TableActionButton
                onClick={() => onClickDelete(row)}
                type={'delete'} />
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ExtendPackage" }) })}
        title={intl.formatMessage({ id: "ExtendPackage" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionRequestExtendPackage
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "ExtendExpiredPackage" })}
            onClick={() => {
              setExtendPackageDetailModal(true);
              setBlnAction(Constants.request);
            }}
            disable={localLoading || loading} />
        }>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "ExtendPackage" }) })}
              name="status"
              options={extendPackageStatusList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedExtendPackageStatus}
              initialValue={selectedExtendPackageStatusId}
              setFieldLabel={setSelectedExtendPackageStatus}
              setFieldValue={setSelectedExtendPackageStatusId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              onChange={(value) => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
                // getCustomerWithLoading(value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            {
              localLoading
                ?
                <div />
                :
                displayCustomerDropdown &&
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading}
                  onChange={() => {
                    clearCustomerDropdownList();
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
            }
          </Col>
          {/* <Col xl={'4'} md={'6'} xs={'6'}>
            <MyButton
              type="button"
              class="btn btn-primary margin-top-28 "
              content={intl.formatMessage({ id: "AdvancedSearch" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => { setCustomerSearchModal(true) }}
            />
          </Col> */}
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ExtendPackageColumns}
              data={extendPackageList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ExtendPackage" }) })}
              objSorted={defaultSorted}
              extendPackageStatus={selectedExtendPackageStatusId}
              branchId={selectedBranchId}
              requestAPI={getExtendPackage} />
        }
      </ListViewLayout>
      <DeleteModal
        displayName={selectedRow?.extendPackageNo}
        onDeleteClick={async () => {
          setLoading(true);
          await deleteExtendPackage(selectedRow?.id || "", selectedRow?.extendPackageNo || "");
          let currentPageTemp = currentPage;
          if (extendPackageList.length === 1 && currentPage !== 1) {
            setCurrentPage((value) => value - 1)
            currentPageTemp = currentPageTemp - 1;
          }
          await getExtendPackage({ pageNumber: currentPageTemp, pageSize: pageSize })
          setLoading(false);
        }} />
      {
        extendPackageDetailModal &&
        <ExtendPackageDetailModal
          blnShow={extendPackageDetailModal}
          setModal={setExtendPackageDetailModal}
          selectedExtendPackageDetail={selectedExtendPackageDetail}
          setSelectedExtendPackageDetail={setSelectedExtendPackageDetail}
          requestExtendPackage={requestExtendPackage}
          reviewExtendPackage={reviewExtendPackage}
          blnAction={blnAction}
        />
      }
    </div>
  );
}

export default observer(ExtendPackage);
