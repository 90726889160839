import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, IncludesLocationPathName, returnPriceWithCurrency, refundNoteStatusColor, validRefundNoteStatusForView } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Label, Table } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { useFormik } from "formik";
import * as Yup from "yup";
import { history } from "../..";
import { Link } from "react-router-dom";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import MyButton from "../../app/components/form/MyButton";
import { RefundNoteReviewRequestBody } from "../../app/models/refundNote";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import RejectRefundNoteModal from "./Modal/RejectRefundNoteModal";
import { burgerMenu } from "../../app/models/common";
import ReferenceDocumentTransactionModal from "../ReferenceDocument/Modal/ReferenceDocumentTransactionModal";

const RefundNoteDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { refundNoteStore, commonStore } = useStore();
  const { successMessageWithoutNavigation, loading, setLoading } = commonStore;
  const { refundNoteDetail, setRefundNoteDetail, getRefundNoteWithId, reviewRefundNote, completeRefundNote } = refundNoteStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "RefundNote" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "RefundNote" }), urlPath: RoutesList.refundNote }];
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionReviewRefundNoteApproval = checkPermission([PermissionConstants.ReviewRefundNoteApproval]);
  const blnPermissionReviewRefundNoteComplete = checkPermission([PermissionConstants.ReviewRefundNoteComplete]);
  const [rejectRefundNoteModal, setRejectRefundNoteModal] = useState(false);
  const [reviewWorkflowAction, setReviewWorkflowAction] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const [burgerMenuListContent, setBurgerMenuListContent] = useState<burgerMenu[]>([]);
  const [blnReferenceDocumentTransactionModal, setBlnReferenceDocumentTransactionModal] = useState(false);
  
  const refundNoteFullDetail = refundNoteDetail || {
    documentNo: "",
    requestBranchId: "",
    requestBranchName: "",
    customerId: "",
    customerName: "",
    customerIdentityNo: "",
    customerMobileNo: "",
    status: "",
    preparedBy: "",
    preparedOn: "",
    reviewedBy: "",
    reviewedOn: "",
    actionedBy: "",
    actionedOn: "",
    creditRedemptionNoteRequestId: "",
    creditRedemptionNoteRequestNo: "",
    subBrandId: "",
    subBrandName: "",
    accountHolderName: "",
    bankAccountType: "",
    bankAccountNo: "",
    reason: "",
    creditNoteDetails: [],
    creditNoteIds: [],
    subTotalRefundAmount: 0,
    refundToCustomer: 0,
    processingFee: 0,
    refundSales: 0,
    customerEntitledAmount: 0,
    customerBranchId: "",
    customerBranchName: "",
    customerSubBranchId: "",
    customerSubBranchName: "",
    remark: "",
    reviewRemark: ""
  }

  const blnPendingStatus = refundNoteFullDetail.status === Constants.pending;
  const blnApprovedForProcessingStatus = refundNoteFullDetail.status === Constants.approvedForProcessing;
  const blnPermissionManageCreditNote = checkPermission([PermissionConstants.ManageCreditNote]);
  const blnAllowApproveRejectAction = blnPermissionReviewRefundNoteApproval && blnPendingStatus;
  const blnAllowCompleteAction = blnPermissionReviewRefundNoteComplete && blnApprovedForProcessingStatus;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: refundNoteDetail || {
      id: "",
      workflowAction: "",
      reviewRemark: ""
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  async function fetchRefundNoteDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getRefundNoteWithId(id),
      ];
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[0]) {
        if (!validRefundNoteStatusForView(resultAPI[0].status)) {
          history.push(`/${RoutesList.notFound}`)
          setLoading(false);
          return;
        }
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageRefundNote], true)) {
      return;
    }

    fetchRefundNoteDetailAPI();

    let indexViewContractLiabilityTransaction = _.findIndex(burgerMenuListContent, { label: `${intl.formatMessage({ id: "ViewContractLiabilityTransaction" })}` })
    if (viewAction && indexViewContractLiabilityTransaction < 0) {
      burgerMenuListContent.push({
        label: `${intl.formatMessage({ id: "ViewContractLiabilityTransaction" })}`,
        onFunction: () => {
          setBlnReferenceDocumentTransactionModal(true);
        }
      })
    }
    
    return (() => {
      if (id && !addAction) {
        setRefundNoteDetail(undefined);
      }
    })
  }, []);

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <div>
          <h6 className="m-0 text-muted">
            {label}
          </h6>
        </div>
        <div>
          <h6 className="text-muted" style={{whiteSpace: "pre-wrap"}}>
            {value}
          </h6>
        </div>
      </Row>
    )
  }

  const CreditNotesColumns = [
    {
      dataField: "requestedOn",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.requestedOn).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.requestedOn).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
        <>
          {
            blnPermissionManageCreditNote
            ?
            <Link to={`/${RoutesList.creditNote}/view/${row.id}`} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4">{row.documentNo}</Label>
            </Link>
            :
            <Label className="margin-bottom-0 margin-left-4">{row.documentNo}</Label>
          }
        </>
    },
    {
      dataField: "subTotalCreditNoteAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.subTotalCreditNoteAmount)}
        </div>
      )
    }
  ];

  const displayCustomRowData = (colSpan, label, value) => {
    return (
      <tr key={label}>
        <td colSpan={colSpan} />
        <td className="text-end" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {`${returnPriceWithCurrency(value)}`}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="refundNote-title margin-bottom-16 ">
                      <Row>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "RefundNote" })}
                            </span>
                            <br />
                            <h3 className="font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {refundNoteFullDetail.requestBranchName}
                            </h3>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "DocumentNo" })}:`, refundNoteFullDetail.documentNo)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "Customer" })}:`, 
                              blnPermissionViewCustomer 
                              ?
                              <Link to={refundNoteFullDetail.customerId ? `/${RoutesList.customer}/view/${refundNoteFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 pointer-clickable">{refundNoteFullDetail.customerName || Constants.emptyData}</Label>
                              </Link>
                              :
                              `${refundNoteFullDetail.customerName}`
                            )}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "IdentityNo" })}:`, refundNoteFullDetail.customerIdentityNo)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "MobileNo" })}:`, refundNoteFullDetail.customerMobileNo)}
                          </div>
                        </Col>
                        <Col xs="6" className="text-end">
                          {displayHeaderRowData(`${intl.formatMessage({ id: "PreparedBy" })}:`, `${refundNoteFullDetail.preparedBy}\n${moment(refundNoteFullDetail.preparedOn).format(Constants.displayDateAndTimeFormat)}`)}
                          {
                            refundNoteFullDetail.actionedBy
                            &&
                            <>
                              <br/>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "ActionBy" })}:`, `${refundNoteFullDetail.actionedBy}\n${moment(refundNoteFullDetail.actionedOn).format(Constants.displayDateAndTimeFormat)}`)}
                            </>
                          }
                          {
                            refundNoteFullDetail.reviewedBy
                            &&
                            <>
                              <br/>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "ReviewedBy" })}:`, `${refundNoteFullDetail.reviewedBy}\n${moment(refundNoteFullDetail.reviewedOn).format(Constants.displayDateAndTimeFormat)}`)}
                            </>
                          }
                        </Col>
                      </Row>
                    </div>
                    <hr/>
                    <Col xs="6">
                      <address>
                        <div className="margin-top-8">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          <br />
                          <div style={{ marginTop: '8px' }}>
                            <GeneralStatus 
                              status={refundNoteFullDetail.status}
                              statusColorFunction={refundNoteStatusColor}
                              blnDisableCenter/>
                          </div>
                        </div>
                      </address>
                    </Col>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "RefundNoteDetails" })}
                      className="fw-bold margin-top-36"
                      titleSize="16px"
                      paddingBottom="12px"
                      blnSkipMarginTop={true}
                    />
                    <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            {refundNoteFullDetail.subBrandName ? <th>{intl.formatMessage({ id: "SubBrand" })}</th> : <th/>}
                            <th>{intl.formatMessage({ id: "AccountHolderName" })}</th>
                            <th>{intl.formatMessage({ id: "BankAccountType" })}</th>
                            <th>{intl.formatMessage({ id: "BankAccountNo" })}</th>
                            <th style={{width: "30%"}}>{intl.formatMessage({ id: "Reason" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={`refund_note_details_index`}>
                            {refundNoteFullDetail.subBrandName ? <td>{refundNoteFullDetail.subBrandName}</td> : <td/>}
                            <td>{refundNoteFullDetail.accountHolderName}</td>
                            <td>{refundNoteFullDetail.bankAccountType}</td>
                            <td>{refundNoteFullDetail.bankAccountNo}</td>
                            <td>{refundNoteFullDetail.reason}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "CreditNoteDetails" })}
                      className="fw-bold margin-top-36"
                      titleSize="16px"
                      paddingBottom="12px"
                      blnSkipMarginTop={true}
                    />
                    <div className="margin-top-16">
                      <PaginationTableWithoutApi 
                        title={intl.formatMessage({ id: "CreditNoteDetails" })}
                        headerClassName={"table-light"}
                        options={refundNoteFullDetail.creditNoteDetails}
                        columns={CreditNotesColumns}
                        pageSize={Constants.defaultPageSize}
                        blnHideTotal={true}
                        keyField={"id"}/>
                    </div>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "Refund" })}
                      className="fw-bold margin-top-36"
                      titleSize="16px"
                      paddingBottom="12px"
                      blnSkipMarginTop={true}
                    />
                    <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            <th style={{width: "40%"}}>{intl.formatMessage({ id: "TotalCNAmount" })}</th>
                            <th style={{width: "40%"}}>{intl.formatMessage({ id: "RefundSales" })}</th>
                            <th className="text-end">{intl.formatMessage({ id: "RefundToCustomer" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={`refund_note_details_index_2`}>
                            <td>{returnPriceWithCurrency(refundNoteFullDetail.subTotalRefundAmount)}</td>
                            <td>{returnPriceWithCurrency(refundNoteFullDetail.refundSales)}</td>
                            <td className="text-end">{returnPriceWithCurrency(refundNoteFullDetail.refundToCustomer)}</td>
                          </tr>
                          {displayCustomRowData(1, `${intl.formatMessage({ id: "ProcessingFees" })}`, refundNoteFullDetail.processingFee)}
                          {displayCustomRowData(1, `${intl.formatMessage({ id: "CustomerEntitle" })}`, refundNoteFullDetail.customerEntitledAmount)}
                        </tbody>
                      </Table>
                    </div>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "AdditionalInformation" })}
                      className="fw-bold margin-top-36"
                      titleSize="16px"
                      paddingBottom="12px"
                      blnSkipMarginTop={true}
                    />
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Remark" })}
                      name="remark"
                      row={4}
                      field={refundNoteFullDetail}
                      setFieldValue={()=> {}}
                      disabled={true} />
                    <GeneralTextArea
                      title={intl.formatMessage({ id: "Review" })}
                      name="reviewRemark"
                      row={4}
                      disabled={!blnAllowApproveRejectAction || localLoading}
                      validation={validation}/>
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      {
                        blnAllowApproveRejectAction
                        &&
                        !Boolean(successMessageWithoutNavigation)
                        &&
                        <>
                          <MyButton
                            type="button"
                            class="btn btn-success"
                            content={intl.formatMessage({ id: "Approve" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                            onClick={async () => {
                              let valuesFinal : RefundNoteReviewRequestBody = {
                                id: id,
                                workflowAction: Constants.approveForProcessing,
                                reviewRemark: validation.values.reviewRemark,
                              }
                              
                              try {
                                setLocalLoading(true);
                                let resultReviewFirstApprovalRefundNote: any = await reviewRefundNote(valuesFinal);
                                if (resultReviewFirstApprovalRefundNote) {
                                  if (resultReviewFirstApprovalRefundNote.status) {
                                    if (resultReviewFirstApprovalRefundNote.status === Constants.success) {
                                      fetchRefundNoteDetailAPI();
                                    }
                                  }
                                }
                              } 
                              finally {
                                setLocalLoading(false);
                              }
                            }}
                          />
                          <MyButton
                            type="button"
                            class="btn btn-back margin-left-8"
                            style={{ width: '200px' }}
                            content={intl.formatMessage({ id: "RejectAndRevise" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                            onClick={async () => {
                              setReviewWorkflowAction(Constants.rejectAndRevise)
                              setRejectRefundNoteModal(true);
                            }}
                          />
                          <MyButton
                            type="button"
                            class="btn btn-danger margin-left-8"
                            style={{ width: '150px' }}
                            content={intl.formatMessage({ id: "RejectOnly" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                            onClick={async () => {
                              setReviewWorkflowAction(Constants.rejectOnly)
                              setRejectRefundNoteModal(true);
                            }}
                          />
                        </>
                      }
                      {
                        blnAllowCompleteAction
                        &&
                        !Boolean(successMessageWithoutNavigation)
                        &&
                        <>
                          <MyButton
                            type="button"
                            class="btn btn-success"
                            content={intl.formatMessage({ id: "Complete" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                            onClick={async () => {
                              try {
                                setLocalLoading(true);
                                let resultReviewFirstApprovalRefundNote: any = await completeRefundNote(id);
                                if (resultReviewFirstApprovalRefundNote) {
                                  if (resultReviewFirstApprovalRefundNote.status) {
                                    if (resultReviewFirstApprovalRefundNote.status === Constants.success) {
                                      fetchRefundNoteDetailAPI();
                                    }
                                  }
                                }
                              } 
                              finally {
                                setLocalLoading(false);
                              }
                            }}
                          />
                        </>
                      }
                      <GeneralSubmitAndCancelBtn
                        className="width-100-percentage"
                        successMessage={successMessageWithoutNavigation}
                        viewAction={false}
                        hidePrimaryButton={true}
                        showPrint={true}
                        cancelButtonBesidePrint={(blnAllowApproveRejectAction || blnAllowCompleteAction) && !Boolean(successMessageWithoutNavigation)}
                        cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        {
          rejectRefundNoteModal
          &&
          <RejectRefundNoteModal
            showPrompt={rejectRefundNoteModal}
            setShowPrompt={setRejectRefundNoteModal}
            title={intl.formatMessage({ id: reviewWorkflowAction === Constants.rejectOnly ? "RejectRefundNoteAlert" : "RejectAndReviseRefundNoteAlert"}, { refundNoteNo: refundNoteFullDetail.documentNo})}
            buttonTitle={intl.formatMessage({ id: reviewWorkflowAction === Constants.rejectOnly ? "RejectOnly" :"RejectAndRevise" })}
            intl={intl}
            defaultRemark={validation.values.reviewRemark}
            refundNoteNo={refundNoteFullDetail.documentNo}
            loading={localLoading}
            buttonFunction={async (remark)=> {
              try {
                setLocalLoading(true);
                let resultCancelRefundNote = await reviewRefundNote({
                  id: id, 
                  workflowAction: reviewWorkflowAction,
                  reviewRemark: remark
                });
                if (resultCancelRefundNote) {
                  if (resultCancelRefundNote.status) {
                    if (resultCancelRefundNote.status === Constants.success) {
                      fetchRefundNoteDetailAPI();
                      setRejectRefundNoteModal(false);
                    }
                  }
                }
              }
              finally
              {
                setLocalLoading(false);
              }
            }}/> 
        }
      </DetailViewLayout>
      {
        blnReferenceDocumentTransactionModal
        &&
        <ReferenceDocumentTransactionModal
          blnShow={blnReferenceDocumentTransactionModal}
          setModal={setBlnReferenceDocumentTransactionModal}
          documentId={id}
          documentType={Constants.refundNote}/>
      }
    </div>
  );
};

export default observer(RefundNoteDetail);
