import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Label,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../../";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { appointmentStatusColor, arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, getBrandId, leadStatusColor, newSpaceBeforeCapitalLetter, returnSubBrandList } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { EmployeeListObject } from "../../../app/models/employee";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { useLocation } from "react-router-dom";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import ReportExpandRow from "../subView/ReportExpandRow";
import { SubBrandObject } from "../../../app/models/brand";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralInput from "../../../app/components/form/GeneralInput";
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const LeadsDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportMarcomStore, mediaSourceStore, branchStore, staticReferenceStore, employeeStore, commonStore } = useStore();
  const { loading, setLoading, totalItem } = commonStore;
  const { leadsDetailsList, getLeadsDetailList, setLeadsDetailsList, exportLeadsDetails } = reportMarcomStore;
  const { getMediaSourceDropdown } = mediaSourceStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { getEmployeeDropdown } = employeeStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedLeadStatus, setSelectedLeadStatus] = useState<number | string>(AllStatus);
  const [selectedLeadStatusId, setSelectedLeadStatusId] = useState<number | string | undefined>(" ");
  const [leadStatusList, setLeadStatusList] = useState<StaticReferenceDataObject[]>([])
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [mediaSourceListFinal, setMediaSourceListFinal] = useState<ObjectForDropdown[]>([]);
  const AllSource = `${intl.formatMessage({ id: "AllSource" })}`;
  const [selectedMediaSourceName, setSelectedMediaSourceName] = useState(AllSource);
  const [selectedMediaSourceId, setSelectedMediaSourceId] = useState(" ");
  const [selectedFirstAppointmentDateRange, setSelectedFirstAppointmentDateRange] = useState<DateRangeInterface | undefined>({ fromDate: "", toDate: "" });
  const [selectedLastAppointmentDateRange, setSelectedLastAppointmentDateRange] = useState<DateRangeInterface | undefined>({ fromDate: "", toDate: "" });
  const [selectedSubmissionDateRange, setSelectedSubmissionDateRange] = useState<DateRangeInterface | undefined>({ fromDate: "", toDate: "" });
  const [exportMenu, setExportMenu] = useState(false);
  const [marcomConsultantList, setMarcomConsultantList] = useState<EmployeeListObject[]>([]);
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedMarcomConsultantName, setSelectedMarcomConsultantName] = useState(All);
  const [selectedMarcomConsultantId, setSelectedMarcomConsultantId] = useState(" ");
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];

  const paramsSearch = useLocation().search;
  const sourceParams = new URLSearchParams(paramsSearch).get('sourceId');
  const marcomConsultantIdParams = new URLSearchParams(paramsSearch).get('employeeId');
  const submissionFromDateParams = new URLSearchParams(paramsSearch).get('submissionFromDate');
  const submissionToDateParams = new URLSearchParams(paramsSearch).get('submissionToDate');
  const appointmentFromDateParams = new URLSearchParams(paramsSearch).get('appointmentFromDate');
  const appointmentToDateParams = new URLSearchParams(paramsSearch).get('appointmentToDate');
  const subBrandParams = new URLSearchParams(paramsSearch).get('subBrandId');
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');
  const firstAppointmentFromDateParams = new URLSearchParams(paramsSearch).get('firstAppointmentFromDate');
  const firstAppointmentToDateParams = new URLSearchParams(paramsSearch).get('firstAppointmentToDate');

  const blnPermissionManageAppointment = checkPermission([PermissionConstants.ManageAppointment]);
  const blnPermissionManageLead = checkPermission([PermissionConstants.ManageLead]);
  const blnPermissionExportMarcomReport = checkPermission([PermissionConstants.ExportMarcomReport]);

  const aryLeadsAppointmentsDetails : ObjectForReportRow[] = [
    {label: "AppointmentDate", value: "appointStartDateTime", type: "date"}, 
    //{label: "AppointmentEndTime", value: "appointEndDateTime", type: "date"}, 
    {label: "Branch", value: "branchName", type: "string"}, 
    {label: "Consultant", value: "consultantName", type: "string"}, 
    {label: "Room", value: "roomName", type: "string"}, 
    {label: "ServiceTreatments", value: "serviceTreatmentNames", type: "string"}, 
    {label: "Duration", value: "duration", type: "string"}, 
    {label: "Status", value: "appointmentStatus", type: "status"}, 
  ];

  const aryLeadsAppointmentsDetailsWithPermission : ObjectForReportRow[] = [
    ...aryLeadsAppointmentsDetails,
    {label: "Action", value: "appointmentId", type: "action"}
  ]

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      isFirstAppointmentOnly: false
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  useEffect(() => {
    if (returnSubBrandList().length > 0) {
      setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
    }
    
    async function fetchLeadListAPI() {
      setLoading(true);
      setLoadingDropdownRefresh(true);

      let blnParams = (((sourceParams || subBrandParams) && ((submissionFromDateParams && submissionToDateParams) || (appointmentFromDateParams && appointmentToDateParams))) || (branchParams && firstAppointmentFromDateParams && firstAppointmentToDateParams));
      let aryAPI: any = [
        getBranchDropDown(),
        getMediaSourceDropdown(),
        getStaticReferenceWithType(Constants.leadStatus, AllStatus),
        getEmployeeDropdown({ brandId: getBrandId(), branchId: "", userRoleIds: [`${Constants.marComUserRoleId},${Constants.customerServiceId},${Constants.marComCustomerServiceId}`] })
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setMediaSourceListFinal(arraySpliceInAllValue(resultAPI[1], AllSource, false, "name", "id"));
      setLeadStatusList(resultAPI[2])
      setMarcomConsultantList(arraySpliceInAllValue(resultAPI[3], All, true, "preferredName", "id"));

      if (blnParams) {
        if(submissionFromDateParams){
          setSelectedSubmissionDateRange({fromDate:  moment(submissionFromDateParams).format(Constants.displayDateFormat) || "", toDate:  moment(submissionToDateParams).format(Constants.displayDateFormat) || ""})
        }
        if(appointmentFromDateParams){
          setSelectedLastAppointmentDateRange({fromDate:  moment(appointmentFromDateParams).format(Constants.displayDateFormat) || "", toDate:  moment(appointmentToDateParams).format(Constants.displayDateFormat) || ""})
        }
        if (firstAppointmentFromDateParams) {
          setSelectedFirstAppointmentDateRange({fromDate:  moment(firstAppointmentFromDateParams).format(Constants.displayDateFormat) || "", toDate:  moment(firstAppointmentToDateParams).format(Constants.displayDateFormat) || ""})
          validation.setFieldValue("isFirstAppointmentOnly", true)
        }
      
        let mediaSourcesListTemp: any = _.cloneDeep(resultAPI[1]);
        let mediaSourcesIdTemp : number | string = " ";
        mediaSourcesListTemp.map((value) => {
          if (sourceParams == value.id) {
            setSelectedMediaSourceName(value.name)
            setSelectedMediaSourceId(value.id);
            mediaSourcesIdTemp = value.id;
          }
        })

        let marcomConsultantIdTemp = " ";
        if (marcomConsultantIdParams) {
          let marcomConsultantListTemp: any = _.cloneDeep(resultAPI[3]);
          marcomConsultantListTemp.map((value) => {
            if (marcomConsultantIdParams == value.id) {
              setSelectedMarcomConsultantName(value.preferredName)
              setSelectedMarcomConsultantId(value.id);
              marcomConsultantIdTemp = value.id;
            }
          })
        }

        let subBrandIdTemp: number | string = " ";
        if (subBrandParams) {
          let subBrandListTemp: any = _.cloneDeep(returnSubBrandList());
          subBrandListTemp.map((value) => {
            if (subBrandParams == value.id) {
              setSelectedSubBrandName(value.name)
              setSelectedSubBrandId(value.id);
              subBrandIdTemp = value.id;
            }
          })
        }

        let branchIdTemp: number | string = " ";
        if (branchParams) {
          let branchListTemp: any = _.cloneDeep(resultAPI[0]);
          branchListTemp.map((value) => {
            if (branchParams === value.id) {
              setSelectedBranchName(value.name)
              setSelectedBranchId(value.id);
              branchIdTemp = value.id;
            }
          })
        }
        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.leadsDetail}` });
        await fetchLeadsDetailApi(false, mediaSourcesIdTemp, marcomConsultantIdTemp, { fromDate:  submissionFromDateParams ? moment(submissionFromDateParams).format(Constants.displayDateFormat) : "", toDate:  submissionToDateParams ? moment(submissionToDateParams).format(Constants.displayDateFormat) : ""}, { fromDate:  appointmentFromDateParams ? moment(appointmentFromDateParams).format(Constants.displayDateFormat) : "", toDate:  appointmentToDateParams ? moment(appointmentToDateParams).format(Constants.displayDateFormat) : ""}, subBrandIdTemp, branchIdTemp, { fromDate:  firstAppointmentFromDateParams ? moment(firstAppointmentFromDateParams).format(Constants.displayDateFormat) : "", toDate:  firstAppointmentToDateParams ? moment(firstAppointmentToDateParams).format(Constants.displayDateFormat) : ""});
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageMarcomReport], true)) {
      return;
    }

    fetchLeadListAPI();

    return (()=> {
      setLeadsDetailsList([]);
    })
  }, []);

  async function fetchLeadsDetailApi(blnDropDown: boolean = false, mediaSourceTemp?: string, marcomConsultantId?: string, submissionDateRange?: DateRangeInterface, lastAppointmentDateRange?: DateRangeInterface, subBrandIdTemp?: string, branchIdTemp?: string, firstAppointmentDateRange?: DateRangeInterface) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getLeadsDetailList({
      pageNumber: 1,
      pageSize: pageSize,
      sourceId: mediaSourceTemp || selectedMediaSourceId,
      branchId: branchIdTemp || selectedBranchId,
      submissionDateRange: submissionDateRange || selectedSubmissionDateRange,
      firstAppointmentDateRange: firstAppointmentDateRange || selectedFirstAppointmentDateRange,
      lastAppointmentDateRange: lastAppointmentDateRange || selectedLastAppointmentDateRange,
      leadStatus: selectedLeadStatusId,
      marcomConsultantId: marcomConsultantId || selectedMarcomConsultantId,
      subBrandId: subBrandIdTemp || selectedSubBrandId,
      isFirstAppointmentOnly: firstAppointmentDateRange?.fromDate ? true : validation.values.isFirstAppointmentOnly
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportLeadsDetailsAPI(templateType: number) {
    setLocalLoading(true);
    await exportLeadsDetails({
      pageNumber: 1,
      pageSize: pageSize,
      sourceId: selectedMediaSourceId,
      branchId: selectedBranchId,
      submissionDateRange: selectedSubmissionDateRange,
      firstAppointmentDateRange: selectedFirstAppointmentDateRange,
      lastAppointmentDateRange: selectedLastAppointmentDateRange,
      status: selectedLeadStatusId,
      employeeId: selectedMarcomConsultantId,
      subBrandId: selectedSubBrandId,
      isFirstAppointmentOnly: validation.values.isFirstAppointmentOnly,
      templateType: templateType
    });
    setLocalLoading(false);
  }

  const onSelectSubmissionDateRange = (objDateRange: any) => {
    setSelectedSubmissionDateRange(objDateRange);
  }

  const onSelectFirstAppointmentDateRange = (objDateRange: any) => {
    setSelectedFirstAppointmentDateRange(objDateRange);
  }

  const onSelectLastAppointmentDateRange = (objDateRange: any) => {
    setSelectedLastAppointmentDateRange(objDateRange);
  }

  //Table Content UI
  const LeadColumns = [
    {
      dataField: "leadId", //field name from array to display
      text: "leadId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.leadId}</>, //Custom UI to display
    },
    {
      dataField: "leadNo",
      text: intl.formatMessage({ id: "LeadNo" }).toUpperCase(),
      style: {
        flex: 1,
      },
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageLead 
            ?
            <Link to={row.leadId ? `/${RoutesList.lead}/view/${row.leadId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.leadNo || Constants.emptyData}</Label>
            </Link>
            : 
            row.leadNo
          }
        </>
      ),
    },
    {
      dataField: "leadContactNo",
      text: intl.formatMessage({ id: "LeadContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.leadContactNo || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "customerContactNo",
      text: intl.formatMessage({ id: "CustomerContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.customerContactNo || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageLead 
            ?
            <Link to={row.leadId ? `/${RoutesList.lead}/view/${row.leadId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.name || Constants.emptyData}</Label>
            </Link>
            : 
            row.name
          }
        </>
      ),
    },
    {
      dataField: "marcomConsultantName",
      text: intl.formatMessage({ id: "AppointedBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.marcomConsultantName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "mediaSource",
      text: intl.formatMessage({ id: "Source" }).toUpperCase(),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "PreferredBranch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "SubmissionDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.submissionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.submissionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "lastFollowUpDate",
      text: intl.formatMessage({ id: "LastFollowUpDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.lastFollowUpDate !== Constants.invalidDateTime ? moment(row.lastFollowUpDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.lastFollowUpDate !== Constants.invalidDateTime ? moment(row.lastFollowUpDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "firstAppointmentDate",
      text: intl.formatMessage({ id: "FirstAppointmentDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.firstAppointmentDate !== Constants.invalidDateTime ? moment(row.firstAppointmentDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.firstAppointmentDate !== Constants.invalidDateTime ? moment(row.firstAppointmentDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "lastAppointmentDate",
      text: intl.formatMessage({ id: "LastAppointmentDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.lastAppointmentDate !== Constants.invalidDateTime ? moment(row.lastAppointmentDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.lastAppointmentDate !== Constants.invalidDateTime ? moment(row.lastAppointmentDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "turnUpDate",
      text: intl.formatMessage({ id: "TurnUpDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.turnUpDate !== Constants.invalidDateTime ? moment(row.turnUpDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.turnUpDate !== Constants.invalidDateTime ? moment(row.turnUpDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "type",
      text: intl.formatMessage({ id: "LeadType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.type) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={leadStatusColor}/>
      ),
    }
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "AppointmentsDetails" })}
        rowFieldName={"appointments"}
        rowColumns={blnPermissionManageAppointment ? aryLeadsAppointmentsDetailsWithPermission :  aryLeadsAppointmentsDetails}
        keyFieldName={"appointmentId"}
        actionTitle={intl.formatMessage({ id: "ViewAppointment" })}
        actionPathName={`/${RoutesList.appointment}/view/`}
        statusColorFunction={appointmentStatusColor}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "LeadsDetails" }) })}
        title={intl.formatMessage({ id: "LeadsDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMarcomReport
          &&
          <ButtonDropdown
            isOpen={exportMenu}
            toggle={() => setExportMenu(!exportMenu)}
            className={"d-inline-block"}
            disabled={localLoading || loading ? true : false}
            style={{ alignSelf: 'center' }}>
            <DropdownToggle
              className={`btn btn-success margin-left-8`}
              id={`page-header-export-dropdown`}
              tag="button"
              style={{ minWidth: '120px', height: "36px" , borderRadius:'0.25rem' }}>
              <span className="ms-2 me-1">{intl.formatMessage({ id: "Export" })}</span>
              <ChevronDownIcon size={Constants.menuIconSize}/>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem tag="a" onClick={() => exportLeadsDetailsAPI(0)}>
                {intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Cascade" }) })}
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => exportLeadsDetailsAPI(2)}>
                {intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "RawData" }) })}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Leads-Details.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    labelField="name"
                    valueField="id"
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    validationRequired={true}
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "MediaSource" })}
                  name="mediaSource"
                  styles={{}}
                  options={mediaSourceListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedMediaSourceName}
                  initialValue={selectedMediaSourceId}
                  setFieldLabel={setSelectedMediaSourceName}
                  setFieldValue={setSelectedMediaSourceId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "SubmissionDate" })} )}
                  name={"SubmissionDateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectSubmissionDateRange}
                  initialValue={selectedSubmissionDateRange} />
              </DoubleColumnRowParent>
              {
                subBrandListFinal.length === 0
                &&
                <DoubleColumnRowParent>
                  <GeneralDateRange
                    title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "FirstAppointmentDate" })} )}
                    name={"firstAppointmentDateRange"}
                    disabled={localLoading || loading}
                    onChangeFunction={onSelectFirstAppointmentDateRange}
                    initialValue={selectedFirstAppointmentDateRange} />
                </DoubleColumnRowParent>
              }
            </Row>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <DoubleColumnRowParent>
                  <GeneralDateRange
                    title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "FirstAppointmentDate" })} )}
                    name={"firstAppointmentDateRange"}
                    disabled={localLoading || loading}
                    onChangeFunction={onSelectFirstAppointmentDateRange}
                    initialValue={selectedFirstAppointmentDateRange} />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "LastAppointmentDate" })} )}
                  name={"lastAppointmentDateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectLastAppointmentDateRange}
                  initialValue={selectedLastAppointmentDateRange} />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Lead" }) })}
                  name="leadStatus"
                  options={leadStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedLeadStatus}
                  initialValue={selectedLeadStatusId}
                  setFieldLabel={setSelectedLeadStatus}
                  setFieldValue={setSelectedLeadStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "AppointedBy" })}
                  name={"marcomConsultantId"}
                  options={marcomConsultantList}
                  initialLabel={selectedMarcomConsultantName}
                  initialValue={selectedMarcomConsultantId}
                  setFieldLabel={setSelectedMarcomConsultantName}
                  setFieldValue={setSelectedMarcomConsultantId}
                  disabled={localLoading || loading}
                  validationRequired={true} />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "FirstAppointmentOnly" })}
                  name="isFirstAppointmentOnly"
                  type="checkbox"
                  disabled={localLoading || loading}
                  validation={validation}
                  childrenUI={
                    <Label className="margin-bottom-0 margin-left-4">
                      Yes
                    </Label>
                  }
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary margin-bottom-14`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    fetchLeadsDetailApi(true)
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={LeadColumns}
              keyField={"leadId"}
              data={leadsDetailsList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "LeadsDetails" }) })}
              objSorted={defaultSorted}
              requestAPI={getLeadsDetailList}
              expandRow={expandRow}
              branchId={selectedBranchId}
              sourceId={selectedMediaSourceId}
              marcomConsultantId={selectedMarcomConsultantId}
              leadStatus={selectedLeadStatusId}
              submissionDateRange={selectedSubmissionDateRange}
              firstAppointmentDateRange={selectedFirstAppointmentDateRange}
              lastAppointmentDateRange={selectedLastAppointmentDateRange}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName}
              isFirstAppointmentOnly={validation.values.isFirstAppointmentOnly}  />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(LeadsDetails);
