import { useEffect, useState } from "react";
import {
  Label,
  Modal,
  Row} from "reactstrap";
import _ from 'lodash';
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { isTablet } from "react-device-detect";
import PaginationTable from "../../../app/components/table/PaginationTable";
import { Constants } from "../../../app/constants/Constants";
import { DateRangeInterface } from "../../../app/models/common";
import { useFormik } from "formik";
import * as Yup from "yup";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { PaginationRequestBody } from "../../../app/models/pagination";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { firstDayOfMonthToCurrentDay, returnPriceWithCurrency } from "../../../app/common/function/function";
import { RoutesList } from "../../../app/constants/RoutesList";
import { Link } from "react-router-dom";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: string;
  validation: any;
  blnPermissionManageCreditNote: boolean;
}

export default function CreditNotesModal(props: Props) {
  const intl = useIntl();
  const { refundNoteStore } = useStore();
  const { customerCreditNoteList, customerCreditNoteTotalItems, getCustomerCreditNote } = refundNoteStore; 
  const [loadingCreditNotes, setLoadingCreditNotes] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const dateRangeLimit = {
    fromDate: "1901-01-01",
    toDate: moment().format(Constants.displayDateFormat)
  }
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const [triggerSearch, setTriggerSearch] = useState(0);
  const pageSize = Constants.defaultPageSize;
  const disabledFieldInput = loadingCreditNotes;

  const CreditNotesWithSelectActionColumns = [
    {
      dataField: "requestedOn",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.requestedOn).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.requestedOn).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
        <>
          {
            props.blnPermissionManageCreditNote
            ?
            <Link to={`/${RoutesList.creditNote}/view/${row.id}`} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4">{row.documentNo}</Label>
            </Link>
            :
            <Label className="margin-bottom-0 margin-left-4">{row.documentNo}</Label>
          }
        </>
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase()
    },
    {
      dataField: "subTotalCreditNoteAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.subTotalCreditNoteAmount)}
        </div>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: {textAlign: "center"},
      formatter: (cellContent, row, index) => (
        <div className="d-flex gap-3 flex-justfity-content-center">
          <MyButton
            type="button"
            class={`btn btn-info`}
            style={{ padding: "8px", paddingBottom: "4px", paddingTop: "4px"}}
            content={intl.formatMessage({ id: "Select" })}
            onClick={()=> {
              let creditNoteDetailsTemp = _.cloneDeep(props.validation.values.creditNoteDetails);
              creditNoteDetailsTemp.push(row);
              creditNoteDetailsTemp = _.uniqBy(creditNoteDetailsTemp, 'id');
              props.validation.setFieldValue("creditNoteDetails", creditNoteDetailsTemp);
              hideModal();
            }}
          />
        </div>
      ),
    }
  ]

  // validation
  const localValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      documentNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let creditNotesRequestBody: PaginationRequestBody = {
    pageSize: pageSize,
    pageNumber: 1,
    customerId: props.customerId,
    documentNo: localValidation.values.documentNo,
    fromDate: selectedDateRange.fromDate,
    toDate: selectedDateRange.toDate
  }
  
  useEffect(() => {
    async function fetchCreditNotesList() {
      fetchCustomerCreditNote();
      setInitialCallAPI(false);
    }

    fetchCreditNotesList();
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchCustomerCreditNote();
    }
  }, [triggerSearch, selectedDateRange])

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setSelectedDateRange(objDateRange);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setTriggerSearch((value)=> value+1)
  }

  async function fetchCustomerCreditNote() {
    setLoadingCreditNotes(true)
    await getCustomerCreditNote(creditNotesRequestBody);
    setLoadingCreditNotes(false);
  }

  const hideModal = () => {
    props.setModal(false);
  }

  return (
    !props.blnShow
    ?
    <div />
    :
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!disabledFieldInput) {
          hideModal();
        }
      }}
      size="xl"
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "CreditNotes" })}</h5>
        {
          !disabledFieldInput
          &&
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        <Row>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "TransactionDate"})})}
              name={"dateRange"}
              className="mb-0"
              disabled={loadingCreditNotes}
              initialValue={selectedDateRange}
              onChangeFunction={onSelectDateRange}
              minMaxDateObj={dateRangeLimit}
              blnMinMaxDateObjWithBothViews={true} />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "DocumentNo" })}
              name="documentNo"
              className="mb-0"
              type="text"
              disabled={loadingCreditNotes}
              validation={localValidation}
              placeholder={intl.formatMessage({ id: "EnterDocumentNo" })}
              onBlurFunction={() => {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          loadingCreditNotes
          ?
          <Loading 
            className="mt-3"/> 
          :
          <PaginationTable
            blnRemoveBackground={true}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={customerCreditNoteTotalItems}
            tableColumns={CreditNotesWithSelectActionColumns}
            data={customerCreditNoteList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CreditNotes" }) })}
            objSorted={[]}
            customerId={props.customerId}
            documentNo={localValidation.values.documentNo}
            fromDate={selectedDateRange.fromDate}
            toDate={selectedDateRange.toDate}
            requestAPI={getCustomerCreditNote} />
        }
      </div>
    </Modal>
  )
}