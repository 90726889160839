import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, IncludesLocationPathName, returnPriceWithCurrency, creditNoteStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import { burgerMenu } from "../../app/models/common";
import ReferenceDocumentTransactionModal from "../ReferenceDocument/Modal/ReferenceDocumentTransactionModal";

const CreditNoteDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { creditNoteStore, commonStore } = useStore();
  const { successMessage, loading, setLoading } = commonStore;
  const { creditNoteDetail, setCreditNoteDetail, getCreditNoteWithId } = creditNoteStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CreditNote" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CreditNote" }), urlPath: RoutesList.creditNote }];
  const [burgerMenuListContent, setBurgerMenuListContent] = useState<burgerMenu[]>([]);
  const [blnReferenceDocumentTransactionModal, setBlnReferenceDocumentTransactionModal] = useState(false);
  const creditNoteFullDetail = creditNoteDetail || {
    id: "",
    documentNo: "",
    branchId: "",
    branchName: "",
    customerId: "",
    customerName: "",
    referenceDocumentType: 0,
    referenceId: "",
    referenceNo: "",
    status: "",
    requestedOn: "",
    subTotalCreditNoteAmount: 0,
    customerEntitledAmount: 0,
    requestedBy: "",
    reviewedBy: "",
    reviewedOn: "",
    processingFee: 0,
    refundSales: 0
  }

  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCreditRedemptionNote = checkPermission([PermissionConstants.ManageCreditRedemptionNote]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const blnPendingStatus = creditNoteFullDetail.status === Constants.pending;
  const blnRefundedStatus = creditNoteFullDetail.status === Constants.refunded;

  async function fetchCreditNoteDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getCreditNoteWithId(id),
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCreditNote], true)) {
      return;
    }

    fetchCreditNoteDetailAPI();

    let indexViewContractLiabilityTransaction = _.findIndex(burgerMenuListContent, { label: `${intl.formatMessage({ id: "ViewContractLiabilityTransaction" })}` })
    if (viewAction && indexViewContractLiabilityTransaction < 0) {
      burgerMenuListContent.push({
        label: `${intl.formatMessage({ id: "ViewContractLiabilityTransaction" })}`,
        onFunction: () => {
          setBlnReferenceDocumentTransactionModal(true);
        }
      })
    }
    
    return (() => {
      if (id && !addAction) {
        setCreditNoteDetail(undefined);
      }
    })
  }, []);

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <div>
          <h6 className="m-0 text-muted">
            {label}
          </h6>
        </div>
        <div>
          <h6 className="text-muted" style={{whiteSpace: "pre-wrap"}}>
            {value}
          </h6>
        </div>
      </Row>
    )
  }
  
  const displayTableRowData = (label, value) => {
    return (
      <tr>
        <td colSpan={1} className="text-end m-0" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="creditNote-title margin-bottom-16 ">
                      <Row>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "CreditNote" })}
                            </span>
                            <br />
                            <h3 className="font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {creditNoteFullDetail.branchName}
                            </h3>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "DocumentNo" })}:`, creditNoteFullDetail.documentNo)}
                            {
                              displayHeaderRowData(
                                `${intl.formatMessage({ id: "ReferenceDocumentNo" })}:`,
                                blnPermissionManageCustomerSalesOrder && creditNoteFullDetail.referenceId !== Constants.emptyId && creditNoteFullDetail.referenceDocumentType === 0
                                ? 
                                <Link to={`/${RoutesList.customerSalesOrder}/view/${creditNoteFullDetail.referenceId}`} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0 pointer-clickable">{creditNoteFullDetail.referenceNo}</Label>
                                </Link> 
                                :  
                                blnPermissionManageCreditRedemptionNote && creditNoteFullDetail.referenceId !== Constants.emptyId && creditNoteFullDetail.referenceDocumentType === 1
                                ? 
                                <Link to={`/${RoutesList.creditRedemptionNote}/view/${creditNoteFullDetail.referenceId}`} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0 pointer-clickable">{creditNoteFullDetail.referenceNo}</Label>
                                </Link> 
                                :
                                creditNoteFullDetail.referenceNo || Constants.emptyData
                              )
                            }
                            {displayHeaderRowData(`${intl.formatMessage({ id: "RequestBy" })}:`, creditNoteFullDetail.requestedBy)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "DateAndTime" })}:`, `${moment(creditNoteFullDetail.requestedOn).format(Constants.displayDateFormat)}\n${moment(creditNoteFullDetail.requestedOn).format(Constants.displayTimeFormat)}`)}
                          </div>
                        </Col>
                        {
                          !blnPendingStatus 
                          &&
                          <Col xs="6" className="text-end text-muted margin-top-26">
                            <address>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "ReviewedBy" })}:`, creditNoteFullDetail.reviewedBy)}
                              {displayHeaderRowData(`${intl.formatMessage({ id: "ReviewedOn" })}:`, `${moment(creditNoteFullDetail.reviewedOn).format(Constants.displayDateFormat)}\n${moment(creditNoteFullDetail.reviewedOn).format(Constants.displayTimeFormat)}`)}
                            </address>
                          </Col>
                        }
                      </Row>
                    </div>
                    <hr/>
                    <Col xs="6">
                      <address>
                        <strong>{intl.formatMessage({ id: "Customer" })}:</strong>
                        <div style={{fontSize: "14px"}}>
                          {
                            blnPermissionManageCustomer 
                            ? 
                            <Link to={creditNoteFullDetail.customerId ? `/${RoutesList.customer}/view/${creditNoteFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                             <Label className="margin-bottom-0 pointer-clickable">{creditNoteFullDetail.customerName || Constants.emptyData}</Label>
                            </Link>
                            :  
                            creditNoteFullDetail.customerName
                          }
                        </div>
                        <div className="margin-top-8">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          <br />
                          <div style={{ marginTop: '8px' }}>
                            <GeneralStatus 
                              status={creditNoteFullDetail.status}
                              statusColorFunction={creditNoteStatusColor}
                              blnDisableCenter/>
                          </div>
                        </div>
                      </address>
                    </Col>
                    <>
                      <div className="py-2 mt-4">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "CreditNote" })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed">
                          <thead className="table-light">
                            <tr>
                              <th style={{ width: '70%' }}>{intl.formatMessage({ id: "Name" })}</th>
                              <th className="text-end">{intl.formatMessage({ id: "SubTotal" })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key={`credit_note_index`}>
                              <td>{intl.formatMessage({ id: "Credit" })}</td>
                              <td className="text-end">{returnPriceWithCurrency(creditNoteFullDetail.subTotalCreditNoteAmount)}</td>
                            </tr>
                            {
                              blnRefundedStatus
                              &&
                              <>
                                {displayTableRowData(`${intl.formatMessage({ id: "ProcessingFees" })}`, returnPriceWithCurrency(creditNoteFullDetail.processingFee))}
                                {displayTableRowData(`${intl.formatMessage({ id: "RefundSales" })}`, returnPriceWithCurrency(creditNoteFullDetail.refundSales))}
                                {displayTableRowData(`${intl.formatMessage({ id: "CustomerEntitledAmount" })}`, returnPriceWithCurrency(creditNoteFullDetail.customerEntitledAmount))}
                              </>
                            }
                          </tbody>
                        </Table>
                      </div>
                      <GeneralTextAreaForModal
                        title={intl.formatMessage({ id: "Reason" })}
                        name="reason"
                        className="mt-3"
                        row={5}
                        field={creditNoteFullDetail}
                        setFieldValue={()=> {}}
                        disabled={true} />
                      {
                        creditNoteFullDetail.status !== Constants.pending
                        &&
                        <GeneralTextAreaForModal
                          title={intl.formatMessage({ id: "Remark" })}
                          name="remark"
                          className="mt-3"
                          row={5}
                          field={creditNoteFullDetail}
                          setFieldValue={()=> {}}
                          disabled={true} />
                      }
                    </>
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      {
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                        />
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
      {
        blnReferenceDocumentTransactionModal
        &&
        <ReferenceDocumentTransactionModal
          blnShow={blnReferenceDocumentTransactionModal}
          setModal={setBlnReferenceDocumentTransactionModal}
          documentId={creditNoteFullDetail.id}
          documentType={Constants.creditNote}/>
      }
    </div>
  );
};

export default observer(CreditNoteDetail);
