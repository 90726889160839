import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency, creditNoteStatusColor, convertArrayToStringDisplay } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import moment from "moment";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { ObjectForDropdown } from "../../app/models/common";
import Loading from "../../app/components/loading/Loading";
import { history } from "../..";
import { PaginationRequestBody } from "../../app/models/pagination";
import GeneralInput from "../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import MyButton from "../../app/components/form/MyButton";
import ActionPromptModalWithRemarkModal from "../../app/components/modal/ActionPromptModalWithRemarkModal";

const CreditNote = () => {
  const intl = useIntl();

  //Use Store
  const { creditNoteStore, customerStore, staticReferenceStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { creditNoteList, creditNotePaginationParams, getCreditNote, reviewCreditNote, setCreditNoteList, setCreditNotePaginationParams } = creditNoteStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const [localLoading, setLocalLoading] = useState(false);
  const [localReviewLoading, setLocalReviewLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedCreditNoteStatus, setSelectedCreditNoteStatus] = useState<string>(AllStatus);
  const [selectedCreditNoteStatusId, setSelectedCreditNoteStatusId] = useState<number | string | undefined>(" ");
  const [creditNoteStatusList, setCreditNoteStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [disabledCreditNoteIds, setDisabledCreditNoteIds] = useState<string[]>([]);
  const [selectedCreditNoteIds, setSelectedCreditNoteIds] = useState<string[]>([]);
  const [selectedCreditNoteCustomerId, setSelectedCreditNoteCustomerId] = useState("");
  const [selectedCreditNoteDocuments, setSelectedCreditNoteDocuments] = useState<string[]>([]);
  const [showApproveCreditNotePrompt, setShowApproveCreditNotePrompt] = useState(false);
  const [showRejectCreditNotePrompt, setShowRejectCreditNotePrompt] = useState(false);
  const blnPermissionManageCustomerSalesOrder =  checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCreditRedemptionNote =  checkPermission([PermissionConstants.ManageCreditRedemptionNote]);
  const blnPermissionManageCreditRedemptionNoteRequest =  checkPermission([PermissionConstants.ManageCreditRedemptionNoteRequest]);
  const blnPermissionReviewCreditNote =  checkPermission([PermissionConstants.ReviewCreditNote]);
  const blnDisabledActionButton = selectedCreditNoteIds.length === 0  || localLoading || loading;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      documentNo: "",
      referenceNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  //Table Content UI
  const CreditNoteColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
    },
    {
      dataField: "requestedOn",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.requestedOn).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.requestedOn).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "referenceNo",
      text: intl.formatMessage({ id: "ReferenceNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCustomerSalesOrder && row.referenceId !== null && row.referenceDocumentType === Constants.invoice
          ?
          <Link to={`/${RoutesList.customerSalesOrder}/view/${row.referenceId}`} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          blnPermissionManageCreditRedemptionNote && row.referenceId !== null && row.referenceDocumentType === Constants.creditRedemptionNote
          ?
          <Link to={`/${RoutesList.creditRedemptionNote}/view/${row.referenceId}`} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          blnPermissionManageCreditRedemptionNoteRequest && row.referenceId !== null && row.referenceDocumentType === Constants.creditRedemptionNoteRequest
          ?
          <Link to={`/${RoutesList.creditRedemptionNoteRequest}/view/${row.referenceId}`} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          row.referenceNo || Constants.emptyData
        }
      </>
    },
    {
      dataField: "subTotalCreditNoteAmount",
      text: intl.formatMessage({ id: "CNAmount" }).toUpperCase(),
      formatter: (cellContent, row) => <>{returnPriceWithCurrency(row.subTotalCreditNoteAmount)}</>, //Custom UI to display
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={creditNoteStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.creditNote}/view/${row.id}`}
              type={'view'} />
          </div>
        </>
      ),
    },
  ];

  let creditNoteRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    status: selectedCreditNoteStatusId,
    statusName: selectedCreditNoteStatus,
    documentNo: validation.values.documentNo,
    referenceNo: validation.values.referenceNo
  }

  useEffect(() => {
    async function fetchCreditNoteListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.creditNote)
      if (blnBackNavigation) {
        if (creditNotePaginationParams) {
          setCurrentPage(creditNotePaginationParams.pageNumber);
          setSelectedBranchId(creditNotePaginationParams.branchId!);
          setSelectedBranchName(creditNotePaginationParams.branchName!);
          setSelectedCreditNoteStatusId(creditNotePaginationParams.status)
          setSelectedCreditNoteStatus(creditNotePaginationParams.statusName || AllStatus)
          setSelectedCustomerId(creditNotePaginationParams.customerId || "")
          setSelectedCustomer(creditNotePaginationParams.customerName || "")
          validation.setValues((values) => ({
            ...values,
            documentNo: creditNotePaginationParams.documentNo || "",
            referenceNo: creditNotePaginationParams.referenceNo || "",
          }))
          creditNoteRequestBody = creditNotePaginationParams;
        }
      }
      else {
        setCreditNotePaginationParams(undefined);
      }
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.creditNoteStatus, AllStatus),
        getBranchDropDown(),
        getCreditNote(creditNoteRequestBody)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setCreditNoteStatusList(resultAPI[0]);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? AllBranch : "", true))
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCreditNote], true)) {
      return;
    }

    fetchCreditNoteListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.creditNote)
        if (!blnSetParams) {
          setCreditNotePaginationParams(undefined);
        }
      }
      setCreditNoteList([]);
    })
  }, [])

  useEffect(()=> {
    if (creditNoteList) {
      let disabledIdsTemp : string[] = [];
      let selectedCreditNoteCustomerIdTemp = _.cloneDeep(selectedCreditNoteCustomerId);
      if (selectedCreditNoteIds.length > 0) {
        let indexCreditNoteTemp = _.findIndex(creditNoteList, { id: selectedCreditNoteIds[0] })
        if (indexCreditNoteTemp > -1) {
          selectedCreditNoteCustomerIdTemp = creditNoteList[indexCreditNoteTemp].customerId;
        }
      }
      else {
        selectedCreditNoteCustomerIdTemp = "";
      }
      
      creditNoteList.forEach((valueCustomerRefundTemp) => {
        if (valueCustomerRefundTemp.status !== Constants.pending || (selectedCreditNoteCustomerIdTemp ? valueCustomerRefundTemp.customerId !== selectedCreditNoteCustomerIdTemp : false)) {
          disabledIdsTemp.push(valueCustomerRefundTemp.id);
        }
      });

      setDisabledCreditNoteIds(disabledIdsTemp);
      setSelectedCreditNoteCustomerId(selectedCreditNoteCustomerIdTemp);
    }
  }, [creditNoteList, selectedCreditNoteIds])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
      fetchCreditNoteAPI(true, Constants.emptyData);
    }
  }, [selectedBranchId])

  useEffect(() => {
    if (
      (selectedCreditNoteStatusId || selectedCreditNoteStatusId === 0) && !initialCallAPI
    ) {
      fetchCreditNoteAPI(true);
    }
  }, [selectedCreditNoteStatusId, selectedCustomerId, triggerSearch]);

  async function fetchCreditNoteAPI(blnDropDown?: boolean, customerId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCreditNote({
      ...creditNoteRequestBody,
      ...customerId === Constants.emptyData && { customerId: "", customerName: "" },
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedCreditNoteIds((valueCreditNoteIds)=> [...valueCreditNoteIds, row.id]);
      setSelectedCreditNoteDocuments((valueCreditNoteDocuments)=> [...valueCreditNoteDocuments, row.documentNo]);
    } else {
      setSelectedCreditNoteIds((valueCreditNoteIds)=> valueCreditNoteIds.filter(x => x !== row.id));
      setSelectedCreditNoteDocuments((valueCreditNoteDocuments)=> valueCreditNoteDocuments.filter(x => x !== row.documentNo));
    }
  }

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      //Ids
      const idsTemp = rows.map(r => r.id);
      let selectedIdsTemp = _.union(idsTemp, selectedCreditNoteIds);
      setSelectedCreditNoteIds(selectedIdsTemp);

      //Documents
      const documentsTemp = rows.map(r => r.documentNo);
      let selectedDocumentsTemp = _.union(documentsTemp, selectedCreditNoteDocuments);
      setSelectedCreditNoteDocuments(selectedDocumentsTemp);
    } else {
      //Ids
      const idsTemp = creditNoteList.map(r => r.id);
      let selectedIdsTemp = _.pullAll(selectedCreditNoteIds, idsTemp);
      setSelectedCreditNoteIds(selectedIdsTemp.length === 0 ? [] : selectedIdsTemp);

      const documentsTemp = creditNoteList.map(r => r.documentNo);
      let selectedDocumentsTemp = _.pullAll(selectedCreditNoteDocuments, documentsTemp);
      setSelectedCreditNoteDocuments(selectedDocumentsTemp.length === 0 ? [] : selectedDocumentsTemp);
    }
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: selectedCreditNoteIds,
    nonSelectable: disabledCreditNoteIds,
    onSelect: handleOnSelect
    // onSelectAll: handleOnSelectAll
  };

  const onClickApproveOrReject = async (workflowAction: string, remark: string) => {
    setLocalReviewLoading(true);
    let resutlAddCreditNote = await reviewCreditNote({creditNoteIds: selectedCreditNoteIds, workflowAction: workflowAction, remark: remark});
    setLocalReviewLoading(false);
    if (resutlAddCreditNote.status === Constants.success) {
      setShowApproveCreditNotePrompt(false);
      setShowRejectCreditNotePrompt(false);
      setSelectedCreditNoteIds([]);
      setSelectedCreditNoteDocuments([]);
      onClickSearch();
    }
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CreditNote" }) })}
        title={intl.formatMessage({ id: "CreditNote" })}
        breadCrumbList={breadCrumbList}
      >
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "CreditNote" }) })}
                name="creditNoteStatus"
                options={creditNoteStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedCreditNoteStatus}
                initialValue={selectedCreditNoteStatusId}
                setFieldLabel={setSelectedCreditNoteStatus}
                setFieldValue={setSelectedCreditNoteStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          !initialCallAPI
          &&
          <Row className={`${loadingCustomer ? "margin-bottom-16" : ""}`}>
            <DoubleColumnRowParent>
              {
                displayCustomerDropdown &&
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading}
                  onChange={() => {
                    clearCustomerDropdownList();
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
              }
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "DocumentNo" })}
                name="documentNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterDocumentNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "ReferenceNo" })}
                name="referenceNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterReferenceNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          localLoading
          ? 
          <Loading/>
          :
          <>
            {
              blnPermissionReviewCreditNote
              &&
              <div className="display-flex mb-3">
                <MyButton 
                  content={intl.formatMessage({ id: "Approve" })}
                  class="btn btn-success"
                  onClick={()=> {
                    setShowApproveCreditNotePrompt(true);
                  }} 
                  disable={blnDisabledActionButton}/>
                <MyButton 
                  content={intl.formatMessage({ id: "Reject" })}
                  class="btn btn-danger margin-left-8"
                  onClick={()=> {
                    setShowRejectCreditNotePrompt(true);
                  }} 
                  disable={blnDisabledActionButton}/>
                <MyButton 
                  content={intl.formatMessage({ id: "ResetAllSelection" })}
                  class="btn btn-warning margin-left-8"
                  onClick={()=> {
                    setSelectedCreditNoteIds([]);
                    setSelectedCreditNoteDocuments([]);
                  }} 
                  disable={blnDisabledActionButton}/>
              </div>
            }
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CreditNoteColumns}
              data={creditNoteList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CreditNote" }) })}
              objSorted={defaultSorted}
              selectRow={blnPermissionReviewCreditNote ? selectRow : undefined}
              requestAPI={getCreditNote}
              customerId={selectedCustomerId}
              customerName={selectedCustomer}
              branchId={selectedBranchId}
              branchName={selectedBranchName}
              status={selectedCreditNoteStatusId}
              statusName={selectedCreditNoteStatus}
              documentNo={validation.values.documentNo}
              referenceNo={validation.values.referenceNo} />
          </>
        }
      </ListViewLayout>
      {
        showApproveCreditNotePrompt
        &&
        <ActionPromptModalWithRemarkModal 
          title={`${intl.formatMessage({ id: "ConfirmToApproveCreditNote" }, { documentNames: convertArrayToStringDisplay(selectedCreditNoteDocuments) })}`}
          showPrompt={showApproveCreditNotePrompt}
          setShowPrompt={setShowApproveCreditNotePrompt}
          localLoading={localReviewLoading}
          onActionClick={async (remark) => {
            onClickApproveOrReject(Constants.approve, remark);
          }}/>
      }
      {
        showRejectCreditNotePrompt
        &&
        <ActionPromptModalWithRemarkModal 
          title={`${intl.formatMessage({ id: "ConfirmToRejectCreditNote" }, { documentNames: convertArrayToStringDisplay(selectedCreditNoteDocuments) })}`}
          showPrompt={showRejectCreditNotePrompt}
          setShowPrompt={setShowRejectCreditNotePrompt}
          localLoading={localReviewLoading}
          remarkRequired={true}
          onActionClick={async (remark) => {
            onClickApproveOrReject(Constants.reject, remark);
          }}/>
      }
    </div>
  );
}

export default observer(CreditNote);
