import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, getPurchaseTypeIcon, returnPriceWithCurrency, salesOrderStatusColor, getBranchId, purchasedItemStatusColor, returnSubBrandList, returnDocumentNoHyperLink, IncludesLocationPathName } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import SignaturePad from "../../app/components/form/SignaturePad";
import Borderline from "../../app/components/form/BorderLine";
import { ReturnRedemptionGetPreviewObject } from "../../app/models/returnRedemption";
import MyButton from "../../app/components/form/MyButton";
import ExchangeToCreditModal from "../CustomerSalesOrder/Modal/ExchangeToCreditModal";
import { history } from "../..";
import TableActionButton from "../../app/components/table/TableActionButton";
import PencilIcon from "mdi-react/PencilIcon";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { toast } from "react-toastify";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import { burgerMenu } from "../../app/models/common";
import ReferenceDocumentTransactionModal from "../ReferenceDocument/Modal/ReferenceDocumentTransactionModal";
import ActionPromptModalWithRemarkModal from "../../app/components/modal/ActionPromptModalWithRemarkModal";

const CreditRedemptionNoteDetail = () => {
    //Use Params
    const { id } = useParams();
    const intl = useIntl();

    //Use Store
    const { crnStore, returnRedemptionStore, referenceDocumentStore, creditNoteStore, commonStore } = useStore();
    const { successMessage, successMessageWithoutNavigation, loading, setLoading } = commonStore;
    const { crnDetail, getCRNWithId, setCRNDetail, updateCRNSubBrand } = crnStore;
    const { getPreviewReturnRedemption, addReturnRedemption } = returnRedemptionStore;
    const { referenceDocumentList, getReferenceDocument, setReferenceDocumentList } = referenceDocumentStore;
    const { addCreditNote } = creditNoteStore;
    const displayTitle = `${intl.formatMessage({ id: "View" })} ${intl.formatMessage({ id: "CreditRedemptionNote" })}`;
    const breadCrumbList = [{ title: intl.formatMessage({ id: "CreditRedemptionNote" }), urlPath: RoutesList.creditRedemptionNote }];
    const [blnUpdateSubBrand, setBlnUpdateSubBrand] = useState(false);
    const crnFullDetail = crnDetail || {
        id: "",
        documentNo: "",
        companyNumber: "",
        businessEntityId: "",
        businessEntityName: "",
        consultantIds: [],
        consultants: [],
        subTotalAmount: 0,
        discountAmount: 0,
        taxAmount: 0,
        roundingAmount: 0,
        note: "",
        signatureImage: "",
        crnDetails: [],
        purchaseBranchName: "",
        branchId: "",
        branchName: "",
        customerId: "",
        customerName: "",
        totalAmount: 0,
        status: "",
        subBrandId: "",
        subBrandName: "",
        issueDate: "",
        isAllowRefund: false
    }
    const [returnRedemptionPreview, setReturnRedemptionPreview] = useState<ReturnRedemptionGetPreviewObject | undefined>(undefined);
    const [returnRedemptionModal, setReturnRedemptionModal] = useState(false);
    const [showRefundPrompt, setShowRefundPrompt] = useState(false);
    const [localSuccessMessage, setLocalSuccessMessage] = useState("");
    const [localLoading, setLocalLoading] = useState(false);
    const [burgerMenuListContent, setBurgerMenuListContent] = useState<burgerMenu[]>([]);
    const [blnReferenceDocumentTransactionModal, setBlnReferenceDocumentTransactionModal] = useState(false);
    const viewAction = IncludesLocationPathName("view");
    const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
    const blnPermissionConvertExchangedCredit = checkPermission([PermissionConstants.ConvertExchangeToCredit]);
    const blnPermissionUpdateCreditRedemptionNote = checkPermission([PermissionConstants.UpdateCreditRedemptionNote]);
    const blnPermissionCreateCreditNote = checkPermission([PermissionConstants.CreateCreditNote]);

    const ReferenceDocumentColumns = [
        {
            dataField: "documentNo",
            text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <div>
                    {returnDocumentNoHyperLink(row)}
                </div>
            )
        }
    ]

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: crnDetail || {
            id: "",
            signatureImage: ""
        },
        validationSchema: Yup.object({}),
        onSubmit: async () => {
        },
    });

    const validationForUpdateSubBrand = useFormik({
        enableReinitialize: true,
        initialValues: {
            salesOrderId: crnFullDetail.id,
            subBrandId: crnFullDetail.subBrandId,
            subBrandName: crnFullDetail.subBrandName
        },
        validationSchema: Yup.object({}),
        onSubmit: async () => {
        },
    });

    async function fetchCreditRedemptionNoteDetailAPI() {
        setLoading(true);
        if (id) {
            let aryAPICreditRedemptionNote: any = [
                getCRNWithId(id),
                getPreviewReturnRedemption({ currentBranchId: getBranchId(), salesOrderId: id }),
                getReferenceDocument(id)
            ];
            let resultAPI = await Promise.all(aryAPICreditRedemptionNote);
            if (resultAPI[1]) {
                setReturnRedemptionPreview(resultAPI[1].status === Constants.success ? resultAPI[1].data : undefined);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!checkPermission([PermissionConstants.ManageCreditRedemptionNote], true)) {
            return;
        }

        fetchCreditRedemptionNoteDetailAPI();

        let indexViewContractLiabilityTransaction = _.findIndex(burgerMenuListContent, { label: `${intl.formatMessage({ id: "ViewContractLiabilityTransaction" })}` })
        if (viewAction && indexViewContractLiabilityTransaction < 0) {
            burgerMenuListContent.push({
                label: `${intl.formatMessage({ id: "ViewContractLiabilityTransaction" })}`,
                onFunction: () => {
                    setBlnReferenceDocumentTransactionModal(true);
                }
            })
        }

        return (() => {
            if (id) {
                setCRNDetail(undefined);
                setReferenceDocumentList([]);
            }
        })
    }, []);

    useEffect(() => {
        if (successMessageWithoutNavigation) {
            setLocalSuccessMessage("success triggred");
            if (showRefundPrompt) {
                setShowRefundPrompt(false);

                setTimeout(()=> {
                    history.push(`/${RoutesList.creditNote}`)
                    setLocalSuccessMessage("");
                }, Constants.timeoutNavigation)
            }
        }
    }, [successMessageWithoutNavigation])

    const displayCustomRowData = (colSpan, label, value) => {
        return (
            <tr>
                {colSpan !== 0 ? <td colSpan={colSpan} /> : undefined}
                <td className="text-end" style={{ fontWeight: 'bold', width: colSpan === 0 ? '70%' : undefined }}>
                    {label}
                </td>
                <td className="text-end">
                    {value}
                </td>
            </tr>
        )
    }

    const onClickUpdateSubBrand = (values) => {
        const valuesTemp: any = _.cloneDeep(values);
        async function fetchUpdateSubBrandAPI() {
            setLoading(true);
            let resultUpdateSubBrand = await updateCRNSubBrand(valuesTemp)
            setLoading(false);
            if (resultUpdateSubBrand.status === Constants.success) {
                toast.success(intl.formatMessage({id: "CRNSubBrandUpdateSuccessfully"}))
                setBlnUpdateSubBrand(false);
                history.push(`/${RoutesList.creditRedemptionNote}/view/${id}`);
                fetchCreditRedemptionNoteDetailAPI();
            }
        }
        if (blnUpdateSubBrand) {
            fetchUpdateSubBrandAPI();
        }
    }

    return (
        <div>
            <DetailViewLayout
                title={displayTitle}
                breadCrumbList={breadCrumbList}
                burgerMenuList={burgerMenuListContent}
                blnShowPrint={true}>
                {loading ? (
                    <Loading />
                ) : (
                    <div>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="invoice-title margin-bottom-16 ">
                                            <Row style={{ alignItems: 'center' }}>
                                                <Col xs="6">
                                                    <div className="logo-lg" style={{ lineHeight: '20px' }}>
                                                        <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                                            {intl.formatMessage({ id: "CreditRedemptionNote" })}
                                                        </span>
                                                        <br />
                                                        <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold text-muted">
                                                            {crnFullDetail.documentNo}
                                                        </h3>
                                                        <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                                            {crnFullDetail.businessEntityName} {`${crnFullDetail.companyNumber ? `(${crnFullDetail.companyNumber})` : ""}`}
                                                        </h4>
                                                        <div className="flex-align-items-center-without-height">
                                                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                                                {crnFullDetail.branchName} {crnFullDetail.subBrandName ? `(${crnFullDetail.subBrandName})` : ""}
                                                            </h4>
                                                            {
                                                                !blnUpdateSubBrand
                                                                &&
                                                                crnFullDetail.subBrandName
                                                                &&
                                                                (crnFullDetail.status === Constants.pending || crnFullDetail.status === Constants.inProgress || crnFullDetail.status === Constants.completed)
                                                                &&
                                                                blnPermissionUpdateCreditRedemptionNote
                                                                &&
                                                                <TableActionButton
                                                                    to={"#"}
                                                                    type={'edit'}
                                                                    className={"d-print-none"}
                                                                    iconReplaceUI={<PencilIcon size={18} id="updateSubBrand" />}
                                                                    iconId={"updateSubBrand"}
                                                                    iconToolTipText={intl.formatMessage({ id: "UpdateSubBrand" })}
                                                                    onClick={() => setBlnUpdateSubBrand(true)} />
                                                            }
                                                        </div>
                                                        <div className="flex-align-items-center-without-height">
                                                            {
                                                                blnUpdateSubBrand
                                                                &&
                                                                <Col xl={"3"} md={"6"} xs={"12"} style={{ marginTop: "0.25rem", marginBottom: "-0.75rem" }}>
                                                                    <DropDownWithTitle
                                                                        name={"subBrandId"}
                                                                        title={""}
                                                                        specifyReturnFieldName={[{ "field": "subBrandId", "value": "id" }, { "field": "subBrandName", "value": "name" }]}
                                                                        labelField={"name"}
                                                                        valueField={"id"}
                                                                        options={returnSubBrandList()}
                                                                        disabled={false}
                                                                        initialLabel={validationForUpdateSubBrand.values.subBrandName}
                                                                        initialValue={validationForUpdateSubBrand.values.subBrandId}
                                                                        blnHideTitle={true}
                                                                        validationRequired={true}
                                                                        validation={validationForUpdateSubBrand} />
                                                                </Col>
                                                            }
                                                            {
                                                                blnUpdateSubBrand
                                                                &&
                                                                <div className="margin-left-8">
                                                                    <TableActionButton
                                                                        to={"#"}
                                                                        type={'save'}
                                                                        onClick={async () => { onClickUpdateSubBrand(validationForUpdateSubBrand.values) }} />
                                                                </div>
                                                            }
                                                            {
                                                                blnUpdateSubBrand
                                                                &&
                                                                <div className="margin-left-8">
                                                                    <TableActionButton
                                                                        to={"#"}
                                                                        type={'cancel'}
                                                                        onClick={async () => {
                                                                            setBlnUpdateSubBrand(false)
                                                                            validationForUpdateSubBrand.setFieldValue("subBrandId", crnFullDetail.subBrandId)
                                                                            validationForUpdateSubBrand.setFieldValue("subBrandName", crnFullDetail.subBrandName)
                                                                        }} />
                                                                </div>
                                                            }
                                                        </div>
                                                        <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                                            {moment(crnFullDetail.issueDate).format(Constants.displayDateFormat)}
                                                        </h4>
                                                        <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                                            {moment(crnFullDetail.issueDate).format(Constants.displayTimeFormat)}
                                                        </h4>
                                                        <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                                                            {intl.formatMessage({ id: "Customer" })}:                             {
                                                                blnPermissionViewCustomer ?
                                                                    <Link to={crnFullDetail.customerId ? `/${RoutesList.customer}/view/${crnFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                                                        <Label className="margin-bottom-0 pointer-clickable">{crnFullDetail.customerName || Constants.emptyData}</Label>
                                                                    </Link>
                                                                    :
                                                                    `${crnFullDetail.customerName}`
                                                            }
                                                        </h4>
                                                    </div>
                                                </Col>
                                                <Col xs="6" className="text-end text-muted">
                                                    <address>
                                                        {
                                                            crnFullDetail.consultants &&
                                                            crnFullDetail.consultants.length > 0 &&
                                                            <>
                                                                &nbsp;&nbsp;
                                                                <strong className="margin-top-16 margin-bottom-2 font-size-16 ">{intl.formatMessage({ id: "Consultant" })}:</strong>
                                                                {
                                                                    crnFullDetail.consultants.map((value, index) => (
                                                                        <div key={`${value}_${index}`}>
                                                                            <span className="margin-bottom-0 font-weight-bold" style={{ fontSize: '15px', marginTop: '5px' }}>
                                                                                <>{index + 1}. {value}</>
                                                                            </span>
                                                                            {index !== crnFullDetail.consultants.length - 1 && <br />}
                                                                        </div>
                                                                    ))
                                                                }
                                                            </>
                                                        }
                                                    </address>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Borderline />
                                        <Row>
                                            <Col xs="6">
                                                <address className="font-size-14">
                                                    <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                                                    <br />
                                                    <div className="" style={{ marginTop: '8px' }}>
                                                        <GeneralStatus
                                                            status={crnFullDetail.status}
                                                            statusColorFunction={salesOrderStatusColor}
                                                            blnDisableCenter />
                                                    </div>
                                                </address>
                                            </Col>
                                        </Row>
                                        <div className="py-2 mt-4">
                                            <h3 className="font-size-15 font-weight-bold">
                                                {intl.formatMessage({ id: "RedemptionSummary" })}
                                            </h3>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>{intl.formatMessage({ id: "Name" })}</th>
                                                        <th className={"text-center"}>{intl.formatMessage({ id: "Type" })}</th>
                                                        <th className={"text-center"}>{intl.formatMessage({ id: "Quantity" })}</th>
                                                        <th className={"text-center"}>{intl.formatMessage({ id: "Status" })}</th>
                                                        <th className="text-center">{intl.formatMessage({ id: "UnitPrice" })}</th>
                                                        <th className="text-center">{intl.formatMessage({ id: "DiscountedPrice" })}</th>
                                                        <th className="text-center">{intl.formatMessage({ id: "Tax" })}</th>
                                                        <th className="text-end">{intl.formatMessage({ id: "Total" })}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        crnFullDetail.crnDetails.map((valueCRNRequestDetailTemp, key) => (
                                                            <tr key={key}>
                                                                <td>{getPurchaseTypeIcon(valueCRNRequestDetailTemp.purchaseType)}{valueCRNRequestDetailTemp.purchaseName}</td>
                                                                <td className={"text-center"}>{valueCRNRequestDetailTemp.purchaseType}</td>
                                                                <td className={"text-center"}>{valueCRNRequestDetailTemp.quantity}</td>
                                                                <td className={"text-center"}>
                                                                    <GeneralStatus
                                                                        status={valueCRNRequestDetailTemp.status}
                                                                        statusColorFunction={purchasedItemStatusColor}
                                                                        blnDisableCenter />
                                                                </td>
                                                                <td className="text-center">{returnPriceWithCurrency(valueCRNRequestDetailTemp.originalPrice)}</td>
                                                                <td className="text-center">{returnPriceWithCurrency(valueCRNRequestDetailTemp.discountAmount)}</td>
                                                                <td className="text-center">{returnPriceWithCurrency(valueCRNRequestDetailTemp.taxAmount)}</td>
                                                                <td className="text-end">{returnPriceWithCurrency(valueCRNRequestDetailTemp.totalPrice)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    <>
                                                        <tr>
                                                            <td colSpan={6} />
                                                            <td className="text-end" style={{ fontWeight: 'bold', minWidth: '150px' }}>
                                                                <div>
                                                                    {`${intl.formatMessage({ id: "SubTotal" })}`}
                                                                </div>
                                                                <div>
                                                                    {`${intl.formatMessage({ id: "SubTotalDiscount" })}`}
                                                                </div>
                                                                <div>
                                                                    {`${intl.formatMessage({ id: "SubTotalTax" })}`}
                                                                </div>
                                                                {
                                                                    Constants.supportMalaysiaRounding
                                                                    &&
                                                                    <div>
                                                                        {`${intl.formatMessage({ id: "Rounding" })}`}
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td className="text-end" style={{ minWidth: '150px' }}>
                                                                <div>
                                                                    {`${returnPriceWithCurrency(crnFullDetail.subTotalAmount)}`}
                                                                </div>
                                                                <div>
                                                                    {`${returnPriceWithCurrency(crnFullDetail.discountAmount)}`}
                                                                </div>
                                                                <div>
                                                                    {`${returnPriceWithCurrency(crnFullDetail.taxAmount)}`}
                                                                </div>
                                                                {
                                                                    Constants.supportMalaysiaRounding
                                                                    &&
                                                                    <div>
                                                                        {`${returnPriceWithCurrency(crnFullDetail.roundingAmount)}`}
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        {displayCustomRowData(6, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(crnFullDetail.totalAmount))}
                                                    </>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="py-2 mt-3">
                                            <h3 className="font-size-15 font-weight-bold">
                                                {intl.formatMessage({ id: "Note" })} :
                                            </h3>
                                        </div>
                                        <GeneralTextAreaForModal
                                            title=""
                                            name="note"
                                            className="margin-top-0"
                                            row={5}
                                            disabled={true}
                                            field={crnFullDetail}
                                            setFieldValue={() => { }} />
                                        <SignaturePad
                                            title={intl.formatMessage({ id: "CustomerSignature" })}
                                            successMessage={localSuccessMessage}
                                            validation={validation}
                                            disabled={true}
                                            isSubmitting={false} />
                                        <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                                            {
                                                blnPermissionConvertExchangedCredit
                                                &&
                                                (returnRedemptionPreview && returnRedemptionPreview.isAllowExchangeToCredit)
                                                &&
                                                !Boolean(successMessage)
                                                &&
                                                !Boolean(localSuccessMessage)
                                                &&
                                                <MyButton
                                                    type="button"
                                                    class={`btn btn-primary margin-right-8`}
                                                    style={{ width: '200px' }}
                                                    content={intl.formatMessage({ id: "ReturnRedemption" })}
                                                    disable={localLoading || Boolean(localSuccessMessage) || loading}
                                                    loading={localLoading}
                                                    onClick={() => {
                                                        setReturnRedemptionModal(true)
                                                    }} />
                                            }
                                            {
                                                blnPermissionCreateCreditNote
                                                &&
                                                crnFullDetail.isAllowRefund
                                                &&
                                                !Boolean(localSuccessMessage)
                                                && 
                                                <MyButton
                                                type="button"
                                                class={`btn btn-info margin-right-8`}
                                                content={intl.formatMessage({ id: "Refund" })}
                                                disable={localLoading || Boolean(localSuccessMessage) || loading}
                                                loading={localLoading}
                                                onClick={() => {
                                                    setShowRefundPrompt(true);
                                                }}/>
                                            }
                                            {
                                                !localSuccessMessage &&
                                                <GeneralSubmitAndCancelBtn
                                                    className="width-100-percentage"
                                                    successMessage={localSuccessMessage}
                                                    viewAction={false}
                                                    hidePrimaryButton={true}
                                                    showPrint={true}
                                                    cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                                                    cancelButtonBesidePrint={true}
                                                />
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {
                            referenceDocumentList.length > 0
                            &&
                            <Row className="d-print-none">
                                <div>
                                    <Col className="standard-layout">
                                        <h5 className="mb-4">{intl.formatMessage({ id: "ReferenceDocuments" })}</h5>
                                        <PaginationTableWithoutApi 
                                            title={intl.formatMessage({ id: "ReferenceDocuments" })}
                                            headerClassName={"table-light"}
                                            options={referenceDocumentList}
                                            columns={ReferenceDocumentColumns}
                                            pageSize={Constants.pageSizeForReferenceDocuments}
                                            keyField={"randomId"}/>
                                    </Col>
                                </div>
                            </Row>
                        }
                    </div>
                )}
            </DetailViewLayout>
            {
                returnRedemptionModal
                &&
                <ExchangeToCreditModal
                    blnShow={returnRedemptionModal}
                    setModal={setReturnRedemptionModal}
                    exchangeToCreditPreview={returnRedemptionPreview!}
                    addExchangeToCredit={addReturnRedemption}
                    salesOrderId={id}
                    blnReturnRedemption={true} />
            }
            {
                showRefundPrompt
                &&
                <ActionPromptModalWithRemarkModal 
                    title={`${intl.formatMessage({ id: "ConfirmToProceedRefund" })}`}
                    remarkTitle={`${intl.formatMessage({ id: "Reason" })}`}
                    showPrompt={showRefundPrompt}
                    setShowPrompt={setShowRefundPrompt}
                    remarkRequired={true}
                    localLoading={localLoading}
                    onActionClick={async (reason) => {
                        setLocalLoading(true);
                        let resutlAddCreditNote =  await addCreditNote({
                            salesOrderId: crnFullDetail.id, 
                            requestBranchId: getBranchId(),
                            reason: reason
                        });
                        if (resutlAddCreditNote.status === Constants.success) {
                            setShowRefundPrompt(false);
                            setLocalSuccessMessage("success triggred");
                            setTimeout(()=> {
                              history.push(`/${RoutesList.creditNote}`)
                            }, Constants.timeoutNavigation)
                          }
                        setLocalLoading(false);
                    }}/>
            }
            {
                blnReferenceDocumentTransactionModal
                &&
                <ReferenceDocumentTransactionModal
                    blnShow={blnReferenceDocumentTransactionModal}
                    setModal={setBlnReferenceDocumentTransactionModal}
                    documentId={id}
                    documentType={Constants.creditRedemptionNote}/>
            }
        </div>
    )
}

export default observer(CreditRedemptionNoteDetail);