import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import moment from "moment";
import { checkPermission, returnPriceWithCurrency, newSpaceBeforeCapitalLetter, getBranchId, arraySpliceInAllValue, comparePathForPagination, comparePathForSetParams, returnAdvancedSearchText, returnSubBrandList, creditRedemptionNoteRequestStatusColor } from "../../app/common/function/function";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { DateRangeInterface } from "../../app/models/common";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import MyButton from "../../app/components/form/MyButton";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { PaginationRequestBody } from "../../app/models/pagination";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { SubBrandObject } from "../../app/models/brand";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";

const CreditRedemptionNoteRequest = () => {
  const intl = useIntl();
  //Use Store
  const { crnRequestStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { crnRequestList, crnRequestPaginationParams, getCRNRequest, setCRNRequestPaginationParams } = crnRequestStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedCreditRedemptionNoteRequestStatus, setSelectedCreditRedemptionNoteRequestStatus] = useState<string>(AllStatus);
  const [selectedCreditRedemptionNoteRequestStatusId, setSelectedCreditRedemptionNoteRequestStatusId] = useState<number | string | undefined>(" ");
  const [creditRedemptionNoteRequestStatusList, setCreditRedemptionNoteRequestStatusList] = useState<StaticReferenceDataObject[]>([]);
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const allCustomer = `${intl.formatMessage({ id: "AllCustomer" })}`;
  const [selectedCustomer, setSelectedCustomer] = useState<string>(allCustomer);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({fromDate: "", toDate: ""});
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [blnAdvancedSearch, setBlnAdvancedSearch] = useState(false);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const [triggerSearch, setTriggerSearch] = useState(0);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  //Default Sorting Type
  const defaultSorted = [];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      documentNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let creditRedemptionNoteRequestRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: blnAdvancedSearch ? "" : getBranchId(),
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    documentNo: validation.values.documentNo,
    status: selectedCreditRedemptionNoteRequestStatusId,
    statusName: selectedCreditRedemptionNoteRequestStatus,
    subBrandName: selectedSubBrandName,
    subBrandId: selectedSubBrandId,
    ...selectedDateRange && {dateRange: selectedDateRange}
  }

  useEffect(() => {
    async function fetchCreditRedemptionNoteRequestListAPI() {
      setLocalLoading(true)
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.creditRedemptionNoteRequest)
      if (blnBackNavigation) {
        if (crnRequestPaginationParams) {
          setCurrentPage(crnRequestPaginationParams.pageNumber);
          setSelectedDateRange(crnRequestPaginationParams.dateRange || {fromDate: "", toDate: ""});
          setSelectedCreditRedemptionNoteRequestStatus(crnRequestPaginationParams.statusName || "");
          setSelectedCreditRedemptionNoteRequestStatusId(crnRequestPaginationParams.status);
          setSelectedCustomer(crnRequestPaginationParams.customerName || "");
          setSelectedCustomerId(crnRequestPaginationParams.customerId || "");
          setSelectedSubBrandName(crnRequestPaginationParams.subBrandName || AllSubBrand);
          setSelectedSubBrandId(crnRequestPaginationParams.subBrandId || " ");
          validation.setValues((values) => ({
            ...values,
            documentNo: crnRequestPaginationParams.documentNo || "",
          }))
          creditRedemptionNoteRequestRequestBody = crnRequestPaginationParams;
        }
      }
      else {
        setCRNRequestPaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchCreditRedemptionNoteRequestAPI(false),
        getStaticReferenceWithType(Constants.creditRedemptionNoteRequestStatus, AllStatus),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setCreditRedemptionNoteRequestStatusList(resultAPI[1]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCreditRedemptionNoteRequest], true)) {
      return;
    }

    fetchCreditRedemptionNoteRequestListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.creditRedemptionNoteRequest)
        if (!blnSetParams) {
          setCRNRequestPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if ((selectedCreditRedemptionNoteRequestStatusId || selectedCreditRedemptionNoteRequestStatusId === 0) && !initialCallAPI) {
      fetchCreditRedemptionNoteRequestAPI(true);
    }
  }, [selectedCreditRedemptionNoteRequestStatusId, triggerSearch, selectedSubBrandId, selectedDateRange, selectedCustomerId])

  async function fetchCreditRedemptionNoteRequestAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCRNRequest(creditRedemptionNoteRequestRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSelect = (row) => {
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);
    setBlnAdvancedSearch(true);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const CreditRedemptionNoteRequestColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      style: {
        width: "15%"
      },
    },
    {
      dataField: "requestedOn",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.requestedOn).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.requestedOn).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        blnPermissionManageCustomer
        ?
        <div className="text-center">
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
        </div>
        :
        <div className="text-center">{row.customerName}</div>
      ),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "Total" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.totalAmount)}</div>
      ),
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      hidden: returnSubBrandList().length === 0,
      formatter: (cellContent, row) => (
        <div className="text-center">
          {newSpaceBeforeCapitalLetter(row.subBrandName)}
        </div>
      )
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={creditRedemptionNoteRequestStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.creditRedemptionNoteRequest}/view/${row.id}`}
              type="view"/>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CreditRedemptionNoteRequest" }) })}
        title={intl.formatMessage({ id: "CreditRedemptionNoteRequest" })}>
        {
          !initialCallAPI
          &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    labelField="name"
                    valueField="id"
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    onChange={(value) => {
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                    validationRequired={true}
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "CreditRedemptionNoteRequest" }) })}
                  name="status"
                  options={creditRedemptionNoteRequestStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedCreditRedemptionNoteRequestStatus}
                  initialValue={selectedCreditRedemptionNoteRequestStatusId}
                  setFieldLabel={setSelectedCreditRedemptionNoteRequestStatus}
                  setFieldValue={setSelectedCreditRedemptionNoteRequestStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "DocumentNo" })}
                  name="documentNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterDocumentNo" })}
                  onBlurFunction={() => {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  validationRequired={false}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange} />
              </DoubleColumnRowParent>
              <Col xl={'5'} lg={"8"} md={"8"} xs={'12'}>
                <div className={`flex-direction-row ${Constants.innerWidthCompare > windowSize.innerWidth ? "mb-3" : ""}`}>
                  {
                    displayCustomerDropdown &&
                    <GeneralSelectDropdown
                      title={intl.formatMessage({ id: "Customer" })}
                      name="customer"
                      className="flex-1 margin-right-12"
                      placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                      options={customerDropdownList}
                      labelField="preferredName"
                      valueField="id"
                      initialLabel={selectedCustomer}
                      initialValue={selectedCustomerId}
                      setFieldLabel={setSelectedCustomer}
                      setFieldValue={setSelectedCustomerId}
                      disabled={localLoading || loading}
                      onChange={() => {
                        clearCustomerDropdownList();
                        setCurrentPage(1);
                        setErrorMessage("");
                        setSuccessMessage("");
                        setBlnAdvancedSearch(false);
                      }}
                      blnSupportCustomSearch={true}
                      onChangeCustomSearch={(value)=> {
                        fetchCustomerDropdown(value);
                      }}
                      onMenuOpenFunction={()=> {
                        clearCustomerDropdownList();
                        setLoadingCustomer(false);
                      }}
                      loadingCustomSearch={loadingCustomer}
                      setLoadingCustomSearch={setLoadingCustomer}
                    />
                  }
                  <>
                  <MyButton
                    type="button"
                    class={`btn btn-primary margin-top-28`}
                    content={intl.formatMessage({ id: returnAdvancedSearchText() }).toUpperCase()}
                    loading={localLoading || loading}
                    disable={localLoading || loading}
                    onClick={() => { setCustomerSearchModal(true) }}
                  />
                  </>
                </div>
              </Col>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CreditRedemptionNoteRequestColumns}
              data={crnRequestList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CreditRedemptionNoteRequest" }) })}
              objSorted={defaultSorted}
              status={selectedCreditRedemptionNoteRequestStatusId}
              statusName={selectedCreditRedemptionNoteRequestStatus}
              branchId={blnAdvancedSearch ? "" : getBranchId()}
              customerId={selectedCustomerId}
              customerName={selectedCustomer}
              documentNo={validation.values.documentNo}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName}
              dateRange={selectedDateRange}
              requestAPI={getCRNRequest}/>
        }
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          blnHideOtherBranch={false}
          blnHideAccessGranted={true}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect} />
      }
    </div>
  );
}

export default observer(CreditRedemptionNoteRequest);
