import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import TableWithSelectAllCheckBoxAndInput from "../../../app/components/table/TableWithSelectAllCheckBoxAndInput";
import { Constants } from "../../../app/constants/Constants";
import { checkPermission, getBranchId, returnCurrency, returnPriceWithCurrency, returnThousandSeperator } from "../../../app/common/function/function";
import GeneralTextArea from "../../../app/components/form/GeneralTextArea";
import { RoutesList } from "../../../app/constants/RoutesList";
import { history } from "../../..";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { isTablet } from "react-device-detect";
import { ExchangeToCreditPreviewObject } from "../../../app/models/exchangeToCredit";

interface Props {
  blnShow: boolean;
  setModal: Function;
  exchangeToCreditPreview: ExchangeToCreditPreviewObject;
  addExchangeToCredit: Function;
  salesOrderId: string;
  blnReturnRedemption?: boolean;
}

export default observer(function ExchangeToCreditModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage, setErrorMessage } = commonStore;
  const [loadingConvert, setLoadingConvert] = useState(false);
  const [totalItemizedExchangeToCreditAmount, setTotalItemizedExchangeToCreditAmount] = useState(0);
  const [blnLocalSuccess, setBlnLocalSuccess]= useState(false);
  const blnPermissionConvertExchangeToCredit = checkPermission([PermissionConstants.ConvertExchangeToCredit]);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "Type" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "purchaseType",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "Qty" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "quantity",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "BalSessions" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "remainingSession",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "BalProduct" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "remainingProduct",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "BalOptionalProductCost" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "remainingOptionalProductCost",
        inputType: "number",
        inputGroup: true,
        inputGroupText: returnCurrency(),
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "ExchangeableAmount" }).toUpperCase(),
        type: "input",
        xl: "3",
        inputName: "exchangeAmount",
        inputType: "number",
        inputGroup: true,
        inputGroupText: returnCurrency(),
        disabled: true
      },
    ];

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      salesOrderDetail: [],
      reason: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const hideModal = () => {
    validationLocal.setFieldValue("salesOrderDetail", initExchangeToCreditCreditList(false));
    props.setModal(false);
  }

  useEffect(() => {
    if (props.exchangeToCreditPreview) {
      if (props.exchangeToCreditPreview.exchangeToCreditDetails && props.exchangeToCreditPreview.exchangeToCreditDetails.length > 0) {
        validationLocal.setFieldValue("salesOrderDetail", initExchangeToCreditCreditList());
      }
    }
  }, [props.exchangeToCreditPreview])

  useEffect(() => {
    if (validationLocal.values.salesOrderDetail) {
      calculateItemizedItemExchangeToCreditAmount()
    }
  }, [validationLocal.values.salesOrderDetail])

  const blnExchangeToCreditPartial = !props.exchangeToCreditPreview.isAllowItemized;

  const initExchangeToCreditCreditList = (blnExchangeToCredit?: boolean) => {
    let valueTemp: any = props.exchangeToCreditPreview.exchangeToCreditDetails;
    let salesOrderDetailTemp = valueTemp.map((value) => {
      return {
        ...value,
        remainingSession: value.remainingSession || Constants.emptyData,
        remainingProduct: value.remainingProduct || Constants.emptyData,
        remainingOptionalProductCost: value.remainingOptionalProductCost !== null ? value.remainingOptionalProductCost : 0,
        isActive: blnExchangeToCredit || !props.exchangeToCreditPreview.isAllowItemized
      }
    })
    return salesOrderDetailTemp;
  }

  const calculateItemizedItemExchangeToCreditAmount = () => {
    let valueTemp: any = validationLocal.values.salesOrderDetail;
    let totalAmountTemp = 0;
    for (var a = 0; a < valueTemp.length; a++) {
      if (valueTemp[a].isActive && valueTemp[a].isActive === true) {
        totalAmountTemp += valueTemp[a].exchangeAmount;
      }
    }
    setTotalItemizedExchangeToCreditAmount(totalAmountTemp)
  }

  const fetchConvertCreditApi = async (value) => {
    setLoadingConvert(true);
    let result = await props.addExchangeToCredit({ requestBranchId: getBranchId(), salesOrderId: props.salesOrderId, reason: validationLocal.values.reason, salesOrderDetailIds: value });
    if (result.status === Constants.success) {
      setBlnLocalSuccess(true);
      setTimeout(() => {
        if (result.type === Constants.exchangeToCredit) {
          history.push(`/${RoutesList.exchangeToCredit}`);
        }
        else {
          history.push(`/${RoutesList.returnRedemption}`);
        }
      }, Constants.dismissSuccessMessage)
    }
    setLoadingConvert(false);
  }

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <Col xl="8">
          <h6 className="m-0 text-end" style={{ fontSize: '15px', fontWeight: 'bold' }}>
            {label}
          </h6>
        </Col>
        <Col xl="4">
          <h6 className="text-end" style={{ fontSize: '15px' }}>
            {value}
          </h6>
        </Col>
      </Row>
    )
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingConvert && !successMessage && !blnLocalSuccess) {
            hideModal();
          }
        }}
        size={"xl"}
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered>
        {
          !props.exchangeToCreditPreview
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: blnExchangeToCreditPartial ? props.blnReturnRedemption ? "ReturnRedemption" : "ExchangeToCredit" : props.blnReturnRedemption ? "ReturnableItem" : "ExchangeableItem" })}</h5>
                </div>
                {
                  !loadingConvert && !successMessage && !blnLocalSuccess
                  &&
                  <button
                    type="button"
                    onClick={() => { hideModal(); }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                {
                  blnExchangeToCreditPartial &&
                  <>
                    <p style={{ fontSize: "15px" }}>{intl.formatMessage({ id: "PartialExchangeToCreditMessage" })}</p> 
                    <p className='' style={{ color: 'red', fontSize: "15px" }}>*{intl.formatMessage({ id: "PleaseReferBelow" })}</p>
                  </>
                }
                {
                  !blnExchangeToCreditPartial &&
                  <div className="">
                    <TableWithSelectAllCheckBoxAndInput
                      name="salesOrderDetail"
                      title=""
                      className="margin-top--8"
                      blnEditable={true}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                      data={validationLocal.values.salesOrderDetail}
                      validation={validationLocal}
                      selectAllTextFieldDisplay={"purchaseName"}
                      selectAllTextFieldDisplayXLSize={2.5}
                      iconTypeName={"purchaseType"}
                      blnTableViewCss={true}
                      disabled={blnExchangeToCreditPartial || loadingConvert || Boolean(successMessage) || blnLocalSuccess} />
                  </div>
                }
                <GeneralTextArea
                  title={intl.formatMessage({ id: "Reason" })}
                  className='mt-4'
                  name="reason"
                  row={5}
                  disabled={loadingConvert || Boolean(successMessage) || blnLocalSuccess}
                  validationRequired={true}
                  validation={validationLocal} />
                <hr />
                <Col className="mt-3">
                  {displayHeaderRowData(`${intl.formatMessage({ id: props.blnReturnRedemption ? "SubtotalReturnableAmount" : "SubtotalExchangeableAmount" })}`, returnPriceWithCurrency(!props.exchangeToCreditPreview.isAllowItemized ? props.exchangeToCreditPreview.totalExchangeToCreditAmount : totalItemizedExchangeToCreditAmount))}
                  {displayHeaderRowData(`${intl.formatMessage({ id: "CreditEarned" })}`, returnThousandSeperator(!props.exchangeToCreditPreview.isAllowItemized ? props.exchangeToCreditPreview.totalExchangeToCreditAmount : totalItemizedExchangeToCreditAmount))}
                </Col>
                <div className="mb-3 mt-4 flex-right">
                  {
                    blnPermissionConvertExchangeToCredit
                    &&
                    !Boolean(successMessage)
                    &&
                    !blnLocalSuccess
                    &&
                    <MyButton
                      type="button"
                      class="btn btn-primary"
                      style={{ width: '20%' }}
                      content={intl.formatMessage({ id: "Confirm" })}
                      onClick={() => {
                        if (!validationLocal.values.reason) {
                          setErrorMessage(`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })}`)
                          return;
                        }
                        if (!blnExchangeToCreditPartial) {
                          let valueTemp: any = validationLocal.values.salesOrderDetail;
                          let selectedPreviewExchangeToCreditList: any = [];
                          for (var a = 0; a < valueTemp.length; a++) {
                            if (valueTemp[a].isActive && valueTemp[a].isActive === true) {
                              selectedPreviewExchangeToCreditList.push(valueTemp[a].salesOrderDetailId)
                            }
                          }
                          if (selectedPreviewExchangeToCreditList && selectedPreviewExchangeToCreditList.length > 0) {
                            fetchConvertCreditApi(selectedPreviewExchangeToCreditList)
                          } else {
                            setErrorMessage(`${intl.formatMessage({ id: "NoItemSelected" }, { moduleName: intl.formatMessage({ id: "Item" }) })}`)
                          }
                        }
                        else {
                          fetchConvertCreditApi([])
                        }
                      }}
                      loading={loadingConvert}
                      disable={loadingConvert || Boolean(successMessage) || blnLocalSuccess}
                    />
                  }
                </div>
              </div>
            </div>
        }
      </Modal>
  )
})