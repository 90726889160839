import React, { useState, useEffect } from "react";
import { Col, FormFeedback, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { checkPermission, customerAccessRequestStatusColor, getBranchId } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Constants } from "../../../app/constants/Constants";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import Borderline from "../../../app/components/form/BorderLine";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import moment from "moment";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { RoutesList } from "../../../app/constants/RoutesList";
import CancelModal from "../../../app/components/modal/CancelModal";
interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedCustomerAccessRequestDetail: any;
  setSelectedCustomerAccessRequestDetail: Function;
  updateCustomerAccessRequest?: Function;
}

export default observer(function CustomerAccessRequestDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage, setShowCancelPrompt } = commonStore;
  const [loadingReview, setLoadingReview] = useState(false);

  const defaultReviewCustomerRefundDetails = {
    id: "",
    isReview: false,
    workflowAction: "",
  }
  const [reviewCustomerRefundDetails, setReviewCustomerRefundDetails] = useState(defaultReviewCustomerRefundDetails)

  const blnPermissionReviewCustomerAccessRequest = checkPermission([PermissionConstants.ReviewCustomerAccessRequest]);
  const blnPermissionUpdateCustomerAccessRequest = checkPermission([PermissionConstants.UpdateCustomerAccessRequest]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const hideModal = () => {
    setReviewCustomerRefundDetails(defaultReviewCustomerRefundDetails);
    props.setModal(false);
  }

  useEffect(() => {
    if (props.selectedCustomerAccessRequestDetail) {
      validationLocal.setFieldValue("customerId", props.selectedCustomerAccessRequestDetail.customerId);
      validationLocal.setFieldValue("customerName", `${props.selectedCustomerAccessRequestDetail.customerName} (${props.selectedCustomerAccessRequestDetail.customerNo})`);
      validationLocal.setFieldValue("originalBranchName", props.selectedCustomerAccessRequestDetail.originalBranchName);
      validationLocal.setFieldValue("requestBranchName", props.selectedCustomerAccessRequestDetail.requestBranchName);
      validationLocal.setFieldValue("from", moment(props.selectedCustomerAccessRequestDetail.from).format(Constants.displayDateAndTimeFormat));
      validationLocal.setFieldValue("to", moment(props.selectedCustomerAccessRequestDetail.to).format(Constants.displayDateAndTimeFormat));
    }
  }, [props.selectedCustomerAccessRequestDetail])

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerName: "",
      customerId: "",
      requestBranchName: "",
      originalBranchName: "",
      from: "",
      to: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const blnPendingStatus = props.selectedCustomerAccessRequestDetail.accessRequestStatus == Constants.pending;
  const blnIsSameBranchUser = props.selectedCustomerAccessRequestDetail.requestBranchId == getBranchId();

  const onClickCustomerAccessRequest = async (valueAction: string) => {
    let blnIsReview = false;

    if (valueAction !== Constants.cancel) {
      blnIsReview = true;
    }

    let customerAccessRequestTemp = _.cloneDeep({ ...reviewCustomerRefundDetails, id: props.selectedCustomerAccessRequestDetail.id, isReview: blnIsReview, workflowAction: valueAction })
    setReviewCustomerRefundDetails(customerAccessRequestTemp);
    if (props.updateCustomerAccessRequest) {
      setLoadingReview(true);
      await props.updateCustomerAccessRequest(customerAccessRequestTemp)
      setLoadingReview(false);
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingReview && !successMessage) {
            hideModal();
          }
        }}
        size="lg"
        centered>
        {
          !props.selectedCustomerAccessRequestDetail
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div className="flex-direction-row" style={{ flex: 1 }}>
                  <h5 style={{ flex: 1 }} className="modal-title margin-top-0">{intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "CustomerAccessRequest" }) })}</h5>
                  <GeneralStatus
                    className="margin-right-20"
                    status={props.selectedCustomerAccessRequestDetail.accessRequestStatus}
                    statusColorFunction={customerAccessRequestStatusColor} />
                </div>
                {
                  !loadingReview && !successMessage
                  &&
                  <button
                    type="button"
                    onClick={() => {
                      hideModal();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                <Row style={{ fontSize: '14px' }}>

                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <div className="pointer-clickable"
                      onClick={() => {
                        if (blnPermissionManageCustomer) {
                          window.open(`/${RoutesList.customer}/view/${validationLocal.values.customerId}`)
                        }
                      }}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "Customer" })}
                        name="customerName"
                        type="text"
                        disabled={true}
                        validationRequired={true}
                        validation={validationLocal}
                      />
                    </div>
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "OriginalBranch" })}
                      name="originalBranchName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Borderline />
                <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "From" })}
                      name="from"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "To" })}
                      name="to"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Row style={{ fontSize: '14px' }}>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "RequestBranch" })}
                      name="requestBranchName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Borderline />
                {/* } */}
                <div className="mb-2 mt-4 flex-right">
                  {
                    blnIsSameBranchUser
                      ?
                      blnPendingStatus
                      &&
                      blnPermissionUpdateCustomerAccessRequest
                      &&
                      !Boolean(successMessage)
                      &&
                      <MyButton
                        type="button"
                        class={`btn btn-warning margin-right-8`}
                        style={{ width: '20%' }}
                        content={intl.formatMessage({ id: "Cancel" })}
                        onClick={() => {
                          setShowCancelPrompt(true);
                         
                        }}
                        disable={loadingReview || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                      :
                      blnPendingStatus
                      &&
                      blnPermissionReviewCustomerAccessRequest
                      &&
                      !Boolean(successMessage)
                      &&
                      props.selectedCustomerAccessRequestDetail.originalBranchId == getBranchId()
                      &&
                      <>
                        <MyButton
                          type="button"
                          class="btn btn-danger margin-right-8"
                          style={{ width: '20%' }}
                          content={Constants.reject}
                          onClick={() => {
                            onClickCustomerAccessRequest(Constants.reject);
                          }}
                          disable={loadingReview || !blnPendingStatus || Boolean(successMessage)}
                          loading={loadingReview}
                        />
                        <MyButton
                          type="submit"
                          class="btn btn-success"
                          style={{ width: '20%' }}
                          content={Constants.approve}
                          onClick={() => {
                            onClickCustomerAccessRequest(Constants.approve);
                          }}
                          disable={loadingReview || !blnPendingStatus || Boolean(successMessage)}
                          loading={loadingReview}
                        />
                      </>
                  }
                </div>
              </div>
            </div>
        }
        <CancelModal
          onCancelClick={async () => {
            onClickCustomerAccessRequest(Constants.cancel);
          }} />
      </Modal>
  )
})