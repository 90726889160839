import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Label} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import moment from "moment";
import { checkPermission, getBranchUser, getBranchName, getBranchId, arraySpliceInAllValue, paymentAdjustmentStatusColor } from "../../app/common/function/function";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { ObjectForDropdown } from "../../app/models/common";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import ChangePaymentMethodDetailModal from "./Modal/ChangePaymentMethodDetailModal";
import { CustomerChangePaymentMethodListObject } from "../../app/models/customerChangePaymentMethod";
import DeleteModal from "../../app/components/modal/DeleteModal";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";

const CustomerChangePaymentMethod = () => {
  const intl = useIntl();

  //Use Params
  const { id } = useParams();

  //Use Store
  const { customerChangePaymentMethodStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, successMessage, setErrorMessage, setSuccessMessage, setShowDeletePrompt } = commonStore;
  const { customerChangePaymentMethodList, getCustomerChangePaymentMethod, getCustomerChangePaymentMethodWithId, reviewCustomerChangePaymentMethod, deleteCustomerChangePaymentMethod } = customerChangePaymentMethodStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedChangePaymentMethodStatus, setSelectedChangePaymentMethodStatus] = useState<number | string>(AllStatus);
  const [selectedChangePaymentMethodStatusId, setSelectedChangePaymentMethodStatusId] = useState<number | string | undefined>(" ");
  const [changePaymentMethodStatusList, setChangePaymentMethodStatusList] = useState<StaticReferenceDataObject[]>([])
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const allBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : allBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const [selectedRow, setSelectedRow] = useState<CustomerChangePaymentMethodListObject | undefined>(undefined);
  const [changePaymentMethodDetailModal, setChangePaymentMethodDetailModal] = useState(false);
  const [selectedCustomerChangePaymentMethodDetail, setSelectedCustomerChangePaymentMethodDetail] = useState<any>([]);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const blnPermissionDeleteChangePaymentMethod = checkPermission([PermissionConstants.DeleteChangePaymentMethod]);
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerPaymentAdjustmentNo: "",
      customerSalesOrderNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  useEffect(() => {
    async function fetchCustomerChangePaymentMethodListAPI() {
      setLocalLoading(true)
      let aryAPI: any = [
        fetchCustomerChangePaymentMethodAPI(false),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.paymentAdjustmentStatus, AllStatus)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? allBranch : "", true))
      setChangePaymentMethodStatusList(resultAPI[2]);
      setLocalLoading(false);
      setInitialCallAPI(false);

      if (id) {
        await onClickViewDetail(id);
        window.history.replaceState(null, "", `/${RoutesList.changePaymentMethod}`)
      }
    }

    if (!checkPermission([PermissionConstants.ManageChangePaymentMethod], true)) {
      return;
    }

    fetchCustomerChangePaymentMethodListAPI();
  }, [])

  useEffect(() => {
    if ((selectedChangePaymentMethodStatusId || selectedChangePaymentMethodStatusId === 0) && !initialCallAPI) {
      fetchCustomerChangePaymentMethodAPI(true);
    }
  }, [selectedChangePaymentMethodStatusId, selectedBranchId, triggerSearch])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setChangePaymentMethodDetailModal(false);
        fetchCustomerChangePaymentMethodAPI(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  async function fetchCustomerChangePaymentMethodAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCustomerChangePaymentMethod({
      pageNumber: currentPage,
      pageSize: pageSize,
      paymentAdjustmentStatus: selectedChangePaymentMethodStatusId,
      branchId: selectedBranchId,
      customerPaymentAdjustmentNo: validation.values.customerPaymentAdjustmentNo,
      customerSalesOrderNo: validation.values.customerSalesOrderNo,
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: CustomerChangePaymentMethodListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const onClickViewDetail = async (changePaymentMethodId: any) => {
    setLoading(true);
    let resultPreviewRefundCredit = await getCustomerChangePaymentMethodWithId(changePaymentMethodId);
    setSelectedCustomerChangePaymentMethodDetail(resultPreviewRefundCredit)
    setChangePaymentMethodDetailModal(true);
    setLoading(false);
  }

  //Table Content UI
  const CustomerChangePaymentMethodColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerPaymentAdjustmentNo",
      text: intl.formatMessage({ id: "ChangePaymentMethodNo" }).toUpperCase(),
      style: {
        width: "15%"
      },
    },
    {
      dataField: "adjustmentDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.adjustmentDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.adjustmentDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => <>
        <Link to={row.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerSalesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 margin-left-4">{row.customerSalesOrderNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "actionUserName",
      text: intl.formatMessage({ id: "ActionBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.actionUserName || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "ActionDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.actionDate !== Constants.invalidDateTime ? moment(row.actionDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.actionDate !== Constants.invalidDateTime ? moment(row.actionDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={paymentAdjustmentStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to="#"
              onClick={() => onClickViewDetail(row.id)}
              type="viewModal" />
            {
              blnPermissionDeleteChangePaymentMethod
              &&
              row.status == Constants.processing
              &&
              <TableActionButton
                onClick={() => onClickDelete(row)} 
                type={'delete'}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ChangePaymentMethod" }) })}
        title={intl.formatMessage({ id: "ChangePaymentMethod" })}
        breadCrumbList={breadCrumbList}>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "ChangePaymentMethod" }) })}
              name="status"
              options={changePaymentMethodStatusList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedChangePaymentMethodStatus}
              initialValue={selectedChangePaymentMethodStatusId}
              setFieldLabel={setSelectedChangePaymentMethodStatus}
              setFieldValue={setSelectedChangePaymentMethodStatusId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
                // getCustomerWithLoading(value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralInput
              title={intl.formatMessage({ id: "ChangePaymentMethodNo" })}
              name="customerPaymentAdjustmentNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterChangePaymentMethodNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralInput
              title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
              name="customerSalesOrderNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CustomerChangePaymentMethodColumns}
              data={customerChangePaymentMethodList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ChangePaymentMethod" }) })}
              objSorted={defaultSorted}
              paymentAdjustmentStatus={selectedChangePaymentMethodStatusId}
              branchId={selectedBranchId}
              customerPaymentAdjustmentNo={validation.values.customerPaymentAdjustmentNo}
              customerSalesOrderNo={validation.values.customerSalesOrderNo}
              requestAPI={getCustomerChangePaymentMethod} />
        }
      </ListViewLayout>
      <DeleteModal
        displayName={selectedRow?.customerPaymentAdjustmentNo}
        onDeleteClick={async () => {
          setLoading(true);
          await deleteCustomerChangePaymentMethod(selectedRow?.id || "", selectedRow?.customerPaymentAdjustmentNo || "");
          let currentPageTemp = currentPage;
          if (customerChangePaymentMethodList.length === 1 && currentPage !== 1) {
            setCurrentPage((value) => value - 1)
            currentPageTemp = currentPageTemp - 1;
          }
          await getCustomerChangePaymentMethod({ pageNumber: currentPageTemp, pageSize: pageSize })
          setLoading(false);
        }} />
      {
        changePaymentMethodDetailModal &&
        <ChangePaymentMethodDetailModal
          blnShow={changePaymentMethodDetailModal}
          setModal={setChangePaymentMethodDetailModal}
          selectedCustomerChangePaymentMethodDetail={selectedCustomerChangePaymentMethodDetail}
          setSelectedCustomerChangePaymentMethodDetail={setSelectedCustomerChangePaymentMethodDetail}
          reviewCustomerChangePaymentMethod={reviewCustomerChangePaymentMethod}
        />
      }
    </div>
  );
}

export default observer(CustomerChangePaymentMethod);
