import { DateRangeInterface } from "./common";
export interface Pagination{
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
}

export interface PaginationRequestBody {
  pageNumber: number;
  pageSize: number;
  year?: number;
  month?: number;
  branchId?: string;
  productId?: string;
  productType?: number | string;
  businessEntityId?: string;
  paymentStatus?: number | string;
  deliverOrderStatus?: number | string;
  stockRequestStatus?: number | string;
  inboundNoticeStatus?: number | string;
  purchaseOrderType?: number | string;
  purchaseOrderStatus?: number | string;
  purchasePaymentMethod?: number | string;
  gRNStatus?: number | string;
  purchaseDeliverOrderStatus?: number | string;
  stockAdjustmentStatus?: number | string;
  consultantId?: string;
  warehouseId?: string;
  stockInOutStatus?: number | string;
  customerId?: string;
  notificationType?: number | string;
  to?: string;
  entityId?: number | string;
  entityName?: string;
  stockTakeStatus?: number | string;
  auditType?: string | number;
  searchType?: number | string;
  searchValue?: number | string;
  sourceId?: string;
  leadId?: string;
  isBranchStaff?: boolean;
  userRoleIds?: string[];
  dateRange?: DateRangeInterface;
  salesOrderStatus?: number | string;
  exchangeToCreditStatus?: number | string;
  exchangeToCreditStatusName?: number | string;
  leadStatus?: number | string;
  marcomConsultantId?: string;
  creditAdjustmentType?: number | string;
  creditAdjustmentTypeName?: number | string;
  appointmentStatus?: number | string;
  customerName?: string;
  customerMobileNo?: string;
  customerEmail?: string;
  employeeId?: string;
  leaveStatus?: number | string;
  leaveType?: number | string;
  paymentMethodId?: number | string;
  branchIds?: string[];
  consultantIds?: string[];
  customerIds?: string[];
  salesCategoryIds?: string[];
  productIds?: string[];
  serviceIds?: string[];
  packageIds?: string[];
  statuses?: string[];
  includeOtherSalesDetails?: boolean;
  requestBranchId?: string;
  requestBranchName?: string;
  stockReceiveStatus?: number | string;
  stockRequestPurchaserType?: number | string;
  stockMovementType?: number | string;
  movementType?: number | string;
  customerDeliverOrderId?: string;
  expired?: boolean;
  paymentAdjustmentStatus?: number | string;
  customerPaymentAdjustmentNo?: number | string;
  customerSalesOrderNo?: string;
  promoCode?: number | string;
  customerProductReturnStatus?: number | string;
  stockReturnToHQStatus?: number | string;
  extendPackageStatus?: number | string;
  leadName?: string;
  leadMobileNo?: string;
  leadEmail?: string;
  optionType?: number | string;
  salesAdjustmentType?: number | string;
  commissionSchemeType?: number | string;
  salesOrderId?: string;
  salesOrderDetailId?: string;
  customerAccessRequestStatus?: number | string;
  customerNo?: string;
  originalBranchId?: string;
  isFilterClosedLeads?: boolean;
  isFilterExistingCustomer?: boolean;
  isFilterHQStaff?: boolean;
  productName?: string;
  packageName?: string;
  serviceName?: string;
  sKU?: string;
  salesOrderStatusName?: string;
  selectedMonthDisplay?: string;
  deliverOrderStatusName?: string;
  branchName?: string;
  customerProductReturnStatusName?: string;
  notificationTypeName?: string;
  isViewAll?: boolean;
  stockTakeStatusName?: string;
  stockRequestStatusName?: string;
  stockReceiveStatusName?: string;
  stockRequestPurchaserTypeName?: string;
  stockReturnToHQStatusName?: string;
  stockAdjustmentStatusName?: string;
  employeeLeaveRequestBody?: string;
  employeeName?: string;
  leaveStatusName?: string;
  leaveTypeName?: string;
  sourceName?: string;
  leadStatusName?: string;
  marcomConsultantName?: string;
  optionTypeName?: string;
  name?: string;
  employeeNo?: string;
  sourceType?: number | string;
  sourceTypeName?: string;
  serviceTreatmentIds?: string[];
  serviceTreatmentNames?: string[];
  contactNo?: string;
  mediaSourceId?: string;
  mediaSourceName?: string;
  appointmentDateRange?: DateRangeInterface;
  submissionDateRange?: DateRangeInterface;
  customerTransferRequestStatus?: number | string;
  customerTransferRequestStatusName?: string;
  toBranchId?: string;
  fromBranchId?: string;
  identityType?: number | string;
  identityTypeName?: string;
  identityNo?: string;
  salesOrderNo?: string;
  status?: number | string;
  statusName?: string;
  appointmentStatusName?: string;
  appointmentStatusNames?: string[];
  employeeIds?: string[];
  leadNo?: string;
  productTypeName?: string;
  sourceTypes?: string[];
  sourceTypeNames?: string[];
  paymentStatusName?: string;
  madeById?: string;
  madeByName?: string;
  stockRequestNo?: string;
  stockReceiveNo?: string;
  stockLevelStatus?: number | string;
  additionBonusType?: number | string;
  additionBonusTypeName?: string;
  positionId?: string;
  positionName?: string;
  virtualWarehouseStatus?: number | string;
  virtualWarehouseStatusName?: string;
  deliverOrderNo?: string;
  virtualWarehouseNo?: string;
  virtualWarehouseId?: string;
  productRedemptionNo?: string;
  productRedemptionStatus?: number | string;
  productRedemptionStatusName?: string;
  virtualWarehouseCreditExchangeStatus?: number | string;
  virtualWarehouseCreditExchangeStatusName?: string;
  virtualWarehouseCreditExchangeNo?: string;
  productRedemptionNoteReturnNo?: string;
  productRedemptionNoteReturnStatus?: number | string;
  productRedemptionNoteReturnStatusName?: string;
  subBrandId?: string;
  subBrandName?: string;
  subBrandIds?: string[];
  type?: string | number | undefined;
  types?: string[];
  referenceNo?: string;
  redemptionId?: string;
  batchJobId?: string;
  requestType?: number | string;
  requestTypeName?: string;
  fromSubmissionDate?: string;
  toSubmissionDate?: string;
  hasStockReceive?: boolean;
  customerNoOrName?: string;
  allBranchAccess?: boolean;
  salesAdjustmentTypes?: string[];
  salesCategoryName?: string;
  invoiceNo?: string;
  detailItemName?: string;
  purchaseType?: number | string;
  purchaseTypeName?: string;
  brandName?: string;
  businessEntityName?: string;
  isActive?: boolean;
  packageId?: string;
  customerBranchId?: string;
  customerInvoiceNo?: string;
  categoryIds?: string[];
  productTypes?: string[];
  isFilterActiveStaff?: boolean;
  isIncludeCreditPayment?: boolean;
  isAppolousPayment?: boolean;
  orderByProperties?: OrderByPropertiesObject[];
  categoryId?: string;
  categoryName?: string;
  salesCategoryId?: string;
  subBranchId?: string;
  isFilterActiveRequestsOnly?: boolean;
  serviceTreatmentId?: string;
  serviceTreatmentName?: string;
  isOptionalProduct?: boolean;
  gender?: number | string;
  genderName?: string;
  raceName?: string;
  raceId?: string;
  isProductOnly?: boolean;
  showCompleted?: boolean;
  departmentName?: string;
  departmentId?: string;
  modelNumber?: string;
  consumptionType?: number | string;
  consumptionTypeName?: string;
  serialNumber?: string;
  machineId?: string;
  machineUsageType?: number | string;
  promotionCode?: string;
  passcode?: number | string;
  closingDate?: string;
  groupCodeId?: string;
  groupCodeName?: string;
  isConfirmed?: boolean;
  purchaseBranchId?: string;
  salesOrderNos?: string;
  isExcludeProduct?: boolean;
  isIncludeEarnSales?: boolean;
  redemptionFromDate?: string;
  redemptionToDate?: string;
  salesOrderFromDate?: string;
  salesOrderToDate?: string;
  salesType?: number | string;
  clType?: number | string;
  templateType?: number;
  joinedFromDate?: string;
  joinedToDate?: string;
  categoryType?: number | string;
  categoryTypeName?: string;
  paymentFromDate?: string;
  paymentToDate?: string;
  submissionFromDate?: string;
  submissionToDate?: string;
  changePaymentModeNo?: string;
  requestFromDate?: string;
  requestToDate?: string;
  submissionDate?: string | null;
  preparedFromDate?: string;
  preparedToDate?: string;
  isSponsorProfile?: boolean;
  firstAppointmentDateRange?: DateRangeInterface;
  lastAppointmentDateRange?: DateRangeInterface;
  isFirstAppointmentOnly?: boolean;
  lastVisitFromDate?: string;
  lastVisitToDate?: string;
  isFromLeadTurnUpSummary?: boolean;
  creditType?: number | string;
  contractLiabilityNo?: string;
  currentBranchId?: string;
  purchasedCreditId?: string;
  documentNo?: string;
  referenceDocumentNo?: string;
  documentId?: string;
  documentType?: string;
  fromDate?: string;
  toDate?: string;
}

export interface OrderByPropertiesObject {
    orderByDirection: string;
    orderByPropertyName: string;
}

export class PaginatedResult<T>{
    data : T;
    pagination: Pagination;

    constructor(data:T, pagination:Pagination){
        this.data = data;
        this.pagination = pagination;
    }
}
export class PagingParams {
    pageNumber;
    pageSize;

    constructor(pageNumber = 1, pageSize = 2){
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
    }
}