import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { StockMovementListObject } from '../../app/models/stockMovement';
import DeleteModal from "../../app/components/modal/DeleteModal";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import _ from "lodash";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnRouteFromModule } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import { Link } from "react-router-dom"
import { RoutesList } from "../../app/constants/RoutesList";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import BookArrowRightOutlineIcon from "mdi-react/BookArrowRightOutlineIcon";
import BookEditIcon from "mdi-react/BookEditIcon";
import BookEditOutlineIcon from "mdi-react/BookEditOutlineIcon";
import BookCheckOutlineIcon from "mdi-react/BookCheckOutlineIcon";


const StockMovement = () => {
  const intl = useIntl();
  //Use Store
  const { stockMovementStore, branchStore, productStore, staticReferenceStore, commonStore } = useStore();
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, setErrorMessage, setSuccessMessage, windowSize } = commonStore;
  const { stockMovementList, getStockMovement, deleteStockMovement } = stockMovementStore;
  const { stockMovementType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<StockMovementListObject | undefined>(undefined);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [productListFinal, setProductListFinal] = useState<ObjectForDropdown[]>([]);
  const AllProduct = `${intl.formatMessage({ id: "AllProduct" })}`;
  const [selectedProductName, setSelectedProductName] = useState(AllProduct);
  const [selectedProductId, setSelectedProductId] = useState(" ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedStockMovementType, setSelectedStockMovementType] = useState<number | string>(AllType);
  const [selectedStockMovementTypeId, setSelectedStockMovementTypeId] = useState<number | string | undefined>(" ");
  const [stockMovementTypeList, setStockMovementTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const blnHQStaff = !getBranchUser();
  const [blnDisplayBalQuantity, setBlnDisplayBalQuantity] = useState(true);
  const [blnDisplayActualQuantity, setBlnDisplayActualQuantity] = useState(true);
  const [blnDisplayReservedQuantity, setBlnDisplayReservedQuantity] = useState(true);
  const [blnDisplayBalReservedQuantity, setBlnDisplayBalReservedQuantity] = useState(true);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    async function fetchAppointmentAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getProductDropdown(),
        getStockMovement({ pageNumber: currentPage, pageSize: pageSize }),
        getStaticReferenceWithType(Constants.stockMovementType, AllType)
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setProductListFinal(arraySpliceInAllValue(resultAPI[1], AllProduct, false, "name", "id"));
      setStockMovementTypeList(resultAPI[3]);
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageStockMovement], true)) {
      return;
    }

    fetchAppointmentAPI();
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchStockMovementAPI(true);
    }
  }, [selectedBranchId, selectedProductId, selectedDateRange, selectedStockMovementTypeId])

  async function fetchStockMovementAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getStockMovement({
      pageNumber: currentPage,
      pageSize: pageSize,
      branchId: selectedBranchId == AllBranch ? "" : selectedBranchId,
      productId: selectedProductId == AllProduct ? "" : selectedProductId,
      stockMovementType: selectedStockMovementTypeId,
      dateRange: selectedDateRange
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const StockMovementColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.actionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.actionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    // {
    //   dataField: "actionUserName",
    //   text: intl.formatMessage({ id: "Employee" }).toUpperCase(),
    //   formatter: (cellContent, row) => (
    //       <div className="">
    //         {row.actionUserName || Constants.emptyData}
    //       </div>
    //   ),
    // },
    {
      dataField: "referenceEntityName",
      text: intl.formatMessage({ id: "Reference" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => {
        let displayReferenceEntityName = newSpaceBeforeCapitalLetter(row.referenceEntityName);
        if (row.referenceEntity === Constants.manualStockOut) {
          return (
            <div>
              {newSpaceBeforeCapitalLetter(row.referenceEntity) || Constants.emptyData}
            </div>
          )
        }
        else {
          if (row.referenceEntityId === Constants.emptyId){
            return (
              <div>
                {displayReferenceEntityName || Constants.emptyData}
              </div>
            )
          }
          else {
            if (row.referenceEntity){
              let routePath = returnRouteFromModule(row.referenceEntity);
              if (routePath !== undefined){
                let finalRoutePath = `/${routePath}/view/${row.referenceEntityId}`;
                return (
                  <Link className="d-block text-primary mb-2 h6"
                    to={finalRoutePath}
                    target={Constants.blank}
                    rel="noopener noreferrer">
                    {displayReferenceEntityName}
                  </Link>
                )
              }
              else {
                return (
                  <div>
                    {displayReferenceEntityName || Constants.emptyData}
                  </div>
                )
              }
            }
          }
        }
      },
    },
    {
      dataField: "moveFrom",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
    },
    {
      dataField: "moveTo",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
      formatter: (cellContent, row) => (
          <div className="">
            {row.moveTo || Constants.emptyData}
          </div>
      ),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.quantity)}
            statusBackgroundColor={Math.sign(row.quantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    {
      dataField: "balanceQuantity",
      text: intl.formatMessage({ id: "BalanceQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      hidden: !blnDisplayBalQuantity,
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
              status={(row.balanceQuantity)}
              statusBackgroundColor={Math.sign(row.balanceQuantity) == -1 ? Constants.red : Constants.lightgreen} />          
        </>
      ),
    },
    {
      dataField: "reservedQuantity",
      text: intl.formatMessage({ id: "ReservedQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      hidden: !blnDisplayReservedQuantity,
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.reservedQuantity)}
            statusBackgroundColor={Math.sign(row.reservedQuantity) == -1 ? Constants.peach : Constants.tabBlue } />
        </>
      ),
    },
    {
      dataField: "balanceReservedQuantity",
      text: intl.formatMessage({ id: "BalanceReservedQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      hidden: !blnDisplayBalReservedQuantity,
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
              status={(row.balanceReservedQuantity)}
              statusBackgroundColor={Math.sign(row.balanceReservedQuantity) == -1 ? Constants.peach : Constants.tabBlue} />          
        </>
      ),
    },
    {
      dataField: "actualQuantity",
      text: intl.formatMessage({ id: "PhysicalQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      hidden: !blnDisplayActualQuantity,
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus  
              status={(row.actualQuantity)}
              statusBackgroundColor={Math.sign(row.actualQuantity) == -1 ? Constants.palepink : Constants.darkgreen} />
        </>
      ),
    },
    {
      dataField: "stockMovementType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{newSpaceBeforeCapitalLetter(row.stockMovementType)}</div>
      ),
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Note" }).toUpperCase(),
    }
  ];

  const refreshTable = () => {
    setLoading(true);
    setTimeout(()=> {
      setLoading(false);
    }, 100)
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockMovement" }) })}
        title={intl.formatMessage({ id: "StockMovement" })}>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || !blnHQStaff}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Product" })}
              name="productName"
              labelField={"name"}
              valueField={"id"}
              options={productListFinal}
              initialLabel={selectedProductName}
              initialValue={selectedProductId}
              setFieldLabel={setSelectedProductName}
              setFieldValue={setSelectedProductId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "StockMovementType" })}
              name="stockMovementType"
              options={stockMovementTypeList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedStockMovementType}
              initialValue={selectedStockMovementTypeId}
              setFieldLabel={setSelectedStockMovementType}
              setFieldValue={setSelectedStockMovementTypeId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange} />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <Col xl={'6'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-8" : "margin-bottom-4"}`}>
            <Label>
              <Label className="margin-bottom-0">{`${intl.formatMessage({ id: "DisplayOption" })} (${intl.formatMessage({ id: "Multiselect" })})`}</Label>
            </Label>
            <div className="d-flex mb-3" role="group">
              <input type="checkbox" className="btn-check" name="blnDisplayBalQuantity" id="blnDisplayBalQuantity" autoComplete="off" disabled={localLoading || loading} defaultChecked={blnDisplayBalQuantity}
                onClick={() => {
                  if(blnDisplayBalQuantity){
                    setBlnDisplayBalQuantity(false);
                    refreshTable();
                  }else{
                    setBlnDisplayBalQuantity(true);
                    refreshTable();
                  }
                }} />
              <label className="btn btn-outline-primary btn-label" style={{zIndex: 0, minWidth: Constants.actionButtonWidth, maxWidth: Constants.searchBarWidth, marginRight: '12px' }} htmlFor="blnDisplayBalQuantity">
                <BookEditOutlineIcon className="label-icon" style={{padding: "5px"}}/>{intl.formatMessage({ id: "BalanceQuantity" })}
              </label>

              <input type="checkbox" className="btn-check" name="blnDisplayReservedQuantity" id="blnDisplayReservedQuantity" autoComplete="off" disabled={localLoading || loading} defaultChecked={blnDisplayReservedQuantity}
                onClick={() => {
                  if(blnDisplayReservedQuantity){
                    setBlnDisplayReservedQuantity(false);
                    refreshTable();
                  }
                  else{
                    setBlnDisplayReservedQuantity(true);
                    refreshTable();
                  }
                }} />
              <label className="btn btn-outline-primary btn-label" style={{zIndex: 0, minWidth: Constants.actionButtonWidth, maxWidth: Constants.searchBarWidth, marginRight: '12px' }} htmlFor="blnDisplayReservedQuantity">
                <BookArrowRightOutlineIcon className="label-icon" style={{padding: "5px"}}/>{intl.formatMessage({ id: "ReservedQuantity" })}
              </label>

              <input type="checkbox" className="btn-check" name="blnDisplayBalReservedQuantity" id="blnDisplayBalReservedQuantity" autoComplete="off" disabled={localLoading || loading} defaultChecked={blnDisplayBalReservedQuantity}
                onClick={() => {
                  if(blnDisplayBalReservedQuantity){
                    setBlnDisplayBalReservedQuantity(false);
                    refreshTable();
                  }
                  else{
                    setBlnDisplayBalReservedQuantity(true);
                    refreshTable();
                  }
                }} />
              <label className="btn btn-outline-primary btn-label" style={{zIndex: 0, minWidth: Constants.actionButtonWidth, maxWidth: '250px', marginRight: '12px' }} htmlFor="blnDisplayBalReservedQuantity">
                <BookEditOutlineIcon className="label-icon" style={{padding: "5px"}}/>{intl.formatMessage({ id: "BalanceReservedQuantity" })}
              </label>

              <input type="checkbox" className="btn-check" name="blnDisplayActualQuantity" id="blnDisplayActualQuantity" autoComplete="off" disabled={localLoading || loading} defaultChecked={blnDisplayActualQuantity}
                onClick={() => {
                  if(blnDisplayActualQuantity){
                    setBlnDisplayActualQuantity(false);
                    refreshTable();
                  }
                  else{
                    setBlnDisplayActualQuantity(true);
                    refreshTable();
                  }
                }} />
              <label className="btn btn-outline-primary btn-label" style={{zIndex: 0, minWidth: Constants.actionButtonWidth, maxWidth: Constants.searchBarWidth }} htmlFor="blnDisplayActualQuantity">
                <BookCheckOutlineIcon className="label-icon" style={{padding: "5px"}}/>{intl.formatMessage({ id: "PhysicalQuantity" })}
              </label>
            </div>
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={StockMovementColumns}
              data={stockMovementList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockMovement" }) })}
              objSorted={defaultSorted}
              branchId={selectedBranchId}
              productId={selectedProductId}
              stockMovementType={selectedStockMovementTypeId}
              dateRange={selectedDateRange}
              requestAPI={getStockMovement} />
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteStockMovement(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (stockMovementList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getStockMovement({ pageNumber: currentPageTemp, pageSize: pageSize, branchId: selectedBranchId, productId: selectedProductId })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(StockMovement);
