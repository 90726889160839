import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, getBranchId, getBranchName, arraySpliceInAllValue, firstDayOfMonthToCurrentDay, dailySalesRecordTypeListHandling, getYears, getMonths, returnSubBrandList, returnDateRangeByYearAndMonthChecking } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import _ from "lodash";
import Loading from "../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import { Link } from 'react-router-dom';
import { PaginationRequestBody } from "../../app/models/pagination";
import TableActionButton from "../../app/components/table/TableActionButton";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { DSRAdjustmentListObject } from "../../app/models/dsrAdjustment";
import MyAddButton from "../../app/components/form/MyAddButton";
import DSRAdjustmentDetailModal from "./Modal/DSRAdjustmentDetailModal";

const DSRAdjustment = () => {
  const intl = useIntl();
  //Use Store
  const { dsrAdjustmentStore, branchStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, windowSize, setSuccessMessage, setErrorMessage, setShowDeletePrompt } = commonStore;
  const { dsrAdjustmentList, deleteDSRAdjustment, getDSRAdjustment, addDSRAdjustment } = dsrAdjustmentStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<DSRAdjustmentListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [localLoadingDateRange, setLocalLoadingDateRange] = useState(false);

  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedDailySalesRecordType, setSelectedDailySalesRecordType] = useState<number | string>(AllType);
  const [selectedDailySalesRecordTypeId, setSelectedDailySalesRecordTypeId] = useState<number | string | undefined>(" ");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const dateRangeLimit = {
    fromDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
    toDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
  }
  const [dailySalesRecordTypeList, setDailySalesRecordTypeList] = useState<StaticReferenceDataObject[]>([])
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const blnPermissionCreateDSRAdjustment= checkPermission([PermissionConstants.CreateDSR]);
  const blnPermissionDeleteDSRAdjustment = checkPermission([PermissionConstants.DeleteDSR]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);

  const [DSRAdjustmentModal, setDSRAdjustmentModal] = useState(false);

  let dsrAdjustmentRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    customerId: selectedCustomerId,
    type: selectedDailySalesRecordTypeId,
    dateRange: selectedDateRange
  }
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths())
    
    async function fetchDSRAdjustmentListAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getDSRAdjustment(dsrAdjustmentRequestBody),
        getStaticReferenceWithType(Constants.dailySalesRecordTypeDropdownFilter, AllType)
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setDailySalesRecordTypeList(dailySalesRecordTypeListHandling(resultAPI[2], true));

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageDSR], true)) {
      return;
    }

    fetchDSRAdjustmentListAPI();
  }, []);

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      if (!selectedCustomerId) {
        fetchDSRAdjustment(true);
      }
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
    }
  }, [selectedBranchId])

  useEffect(()=> {
    if ((selectedDailySalesRecordTypeId || selectedDailySalesRecordTypeId === 0 ) && !initialCallAPI) {
      fetchDSRAdjustment(true);
    }
  }, [selectedCustomerId, selectedDailySalesRecordTypeId, selectedDateRange])

  useEffect(()=> {
    const selectedYearAndMonthDateFormat = moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat);
    setSelectedDateRange(returnDateRangeByYearAndMonthChecking(selectedYearAndMonthDateFormat));
    setLocalLoadingDateRange(true);
    setTimeout(()=> {
      setLocalLoadingDateRange(false);
    }, 100)
  }, [selectedYear, selectedMonthValue])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setDSRAdjustmentModal(false);
        fetchDSRAdjustment(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  async function fetchDSRAdjustment(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getDSRAdjustment(dsrAdjustmentRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const DSRAdjustmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase()
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            row.customerName || Constants.emptyData
          }
        </div>
      ),
    },
    {
      dataField: "joinedDate",
      text: intl.formatMessage({ id: "JoinedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.joinedDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.joinedDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "type",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {newSpaceBeforeCapitalLetter(row.type)}
        </>
      ),
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "SalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {
            blnPermissionManageCustomerSalesOrder && row.salesOrderId
            ?
            <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.salesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            row.salesOrderNo || Constants.emptyData
          }
        </div>
      ),
    },
    {
      ...returnSubBrandList().length > 0
      &&
      {
        dataField: "subBrandName",
        text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div>
            {newSpaceBeforeCapitalLetter(row.subBrandName) || Constants.emptyData}
          </div>
        )
      }
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "TotalPayment" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.totalPayment)}
        </div>
      ),
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "CreatedAt" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.createdAt ? moment(row.createdAt).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.createdAt ? moment(row.createdAt).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      dataField: "isManualCreation",
      text: intl.formatMessage({ id: "Manual" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isManualCreation ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              blnPermissionDeleteDSRAdjustment
              &&
              <TableActionButton
                type="delete"
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    }
  ];

  const onClickDelete = async (row: DSRAdjustmentListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "DSRAdjustment" }) })}
        title={intl.formatMessage({ id: "DSRAdjustment" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionCreateDSRAdjustment
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "DSRAdjustment" })}
            onClick={() => {
              setDSRAdjustmentModal(true);
            }}
            disable={localLoading || loading} />
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          {
            displayCustomerDropdown &&
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Customer" })}
                name="customer"
                placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                options={customerDropdownList}
                labelField="preferredName"
                valueField="id"
                initialLabel={selectedCustomer}
                initialValue={selectedCustomerId}
                setFieldLabel={setSelectedCustomer}
                setFieldValue={setSelectedCustomerId}
                disabled={localLoading || loading}
                onChange={() => {
                  clearCustomerDropdownList();
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                blnSupportCustomSearch={true}
                onChangeCustomSearch={(value)=> {
                  fetchCustomerDropdown(value);
                }}
                onMenuOpenFunction={()=> {
                  clearCustomerDropdownList();
                  setLoadingCustomer(false);
                }}
                loadingCustomSearch={loadingCustomer}
                setLoadingCustomSearch={setLoadingCustomer}
              />
            </DoubleColumnRowParent>
          }
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Type" })}
              name="dailySalesRecordType"
              options={dailySalesRecordTypeList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedDailySalesRecordType}
              initialValue={selectedDailySalesRecordTypeId}
              setFieldLabel={setSelectedDailySalesRecordType}
              setFieldValue={setSelectedDailySalesRecordTypeId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        <Row>
        <Col xl={"3"}>
          <GeneralSelectDropdown
            title={intl.formatMessage({ id: "Years" })}
            name="years"
            options={yearList}
            initialLabel={selectedYear}
            initialValue={selectedYear}
            setFieldValue={setSelectedYear}
            disabled={localLoading || loading}
            validationRequired={true}
            onChange={() => {
              setCurrentPage(1);
              setErrorMessage("");
              setSuccessMessage("");
            }}
          />
         </Col>
          <Col xl={"3"}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </Col>
          <Col xl={"3"}>
            {
              localLoadingDateRange
              ?
              <Loading/>
              :
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRange" })}
                name={"DateRange"}
                disabled={localLoading || loading}
                initialValue={selectedDateRange}
                minMaxDateObj={dateRangeLimit}
                validationRequired={true}
                onChangeFunction={onSelectDateRange}/>
            }
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={DSRAdjustmentColumns}
              keyField={"id"}
              data={dsrAdjustmentList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "DSRAdjustment" }) })}
              objSorted={defaultSorted}
              requestAPI={getDSRAdjustment}
              branchId={selectedBranchId}
              customerId={selectedCustomerId}
              type={selectedDailySalesRecordTypeId}
              dateRange={selectedDateRange}
              hideSearchBar={true} />
        }
        <DeleteModal
          displayName={`${selectedRow?.customerName} - ${selectedRow?.salesOrderNo} - ${selectedRow?.type}`}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteDSRAdjustment(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (dsrAdjustmentList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getDSRAdjustment({  ...dsrAdjustmentRequestBody, pageNumber: currentPageTemp })
            setLoading(false);
          }} />
                {
        DSRAdjustmentModal &&
        <DSRAdjustmentDetailModal
          blnShow={DSRAdjustmentModal}
          setModal={setDSRAdjustmentModal}
          optionYearList={yearList}
          optionMonthList={monthList}
          addDSRAdjustment={addDSRAdjustment}
        />
      }
      </ListViewLayout>
    </div>
  );
}

export default observer(DSRAdjustment);
