import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, IncludesLocationPathName, returnPriceWithCurrency, changePaymentModeStatusColor, returnBlobImage } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralAttachment from "../../app/components/form/GeneralAttachment";
import { GeneralAttachmentListInterface } from "../../app/models/common";
import { history } from "../..";
import { Link } from "react-router-dom";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import SignaturePad from "../../app/components/form/SignaturePad";
import MyButton from "../../app/components/form/MyButton";
import { ChangePaymentModeReviewFinalApprovalObject, ChangePaymentModeReviewFirstApprovalObject, ChangePaymentModeUpdateAfterCompletedObject } from "../../app/models/changePaymentMode";
import CancelOrRejectChangePaymentMode from "./Modal/CancelOrRejectChangePaymentMode";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";

const ChangePaymentModeDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { changePaymentModeStore, commonStore } = useStore();
  const { successMessageWithoutNavigation, loading, setLoading } = commonStore;
  const { changePaymentModeDetail, setChangePaymentModeDetail, getChangePaymentModeWithId, signChangePaymentMode, cancelChangePaymentMode, reviewFirstApprovalChangePaymentMode, reviewFinalApprovalChangePaymentMode, updateChangePaymentModeAfterCompleted } = changePaymentModeStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const editCompletedAction = IncludesLocationPathName("editcompleted");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "ChangePaymentMode" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "ChangePaymentMode" }), urlPath: RoutesList.changePaymentMode }];
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionUpdateChangePaymentMode = checkPermission([PermissionConstants.UpdateChangePaymentMode]);
  const blnPermissionReviewChangePaymentModeFirstApproval = checkPermission([PermissionConstants.ReviewChangePaymentModeFirstApproval]);
  const blnPermissionReviewChangePaymentModeFinalApproval = checkPermission([PermissionConstants.ReviewChangePaymentModeFinalApproval]);
  const [cancelChangePaymentModeModal, setCancelChangePaymentModeModal] = useState(false);
  const [rejectFirstApprovalChangePaymentModeModal, setRejectFirstApprovalChangePaymentModeModal] = useState(false);
  const [rejectFinalApprovalChangePaymentModeModal, setRejectFinalApprovalChangePaymentModeModal] = useState(false);
  const [managerWorkflowAction, setManagerWorkflowAction] = useState("");
  const [financeWorkflowAction, setFinanceWorkflowAction] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const paymentDetailWidth = "250px";
  
  const changePaymentModeFullDetail = changePaymentModeDetail || {
    changePaymentModeNo: "",
    branchId: "",
    branchName: "",
    customerId: "",
    customerName: "",
    customerIdentityNo: "",
    customerContactNo: "",
    status: "",
    preparedBy: "",
    preparedOn: "",
    signedBy: "",
    signedOn: "",
    remark: "",
    customerSignature: "",
    firstApproverSignature: "",
    reviewedBy: "",
    reviewedOn: "",
    actionedBy: "",
    actionedOn: "",
    supportingDocumentFileIds: [],
    supportingDocumentAttachments: [],
    oldPaymentDetails: [],
    newPaymentDetails: [],
    financeTransactionFileIds: [],
    financeTransactionAttachments: [],
    financeRemark: "",
    transactionDate: "",
    isCompletedRecordUpdated: false
  }

  const blnCompletedRecordUpdated = editCompletedAction ? changePaymentModeFullDetail.isCompletedRecordUpdated : true;
  const blnPendingSignStatus = changePaymentModeFullDetail.status == Constants.pendingSign;
  const blnPendingFirstApprovalStatus = changePaymentModeFullDetail.status == Constants.pendingFirstApproval;
  const blnPendingFinalApprovalStatus = changePaymentModeFullDetail.status == Constants.pendingFinalApproval;
  const blnCompletedStatus = changePaymentModeFullDetail.status == Constants.completed;
  const blnRejectedStatus = changePaymentModeFullDetail.status == Constants.rejected;
  const blnReviseRequiredStatus = changePaymentModeFullDetail.status == Constants.reviseRequired;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      arySupportingDocumentAttachments: [],
      aryFinanceTransactionAttachments: [],
      customerSignature: "",
      customerSignatureError: "",
      firstApproverSignature: "",
      firstApproverSignatureError: "",
      financeRemark: ""
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  async function fetchChangePaymentModeDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getChangePaymentModeWithId(id),
      ];
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[0]) {
        if (resultAPI[0].status === Constants.draft || (editCompletedAction && (resultAPI[0].status !== Constants.completed || (resultAPI[0].status === Constants.completed && resultAPI[0].isCompletedRecordUpdated)))) {
          history.push(`/${RoutesList.notFound}`)
          setLoading(false);
          return;
        }

        if (resultAPI[0].supportingDocumentAttachments) {
          let arySupportingDocumentAttachmentsTemp : GeneralAttachmentListInterface[]  = [];
          resultAPI[0].supportingDocumentAttachments.map((valueSupportingDocumentAttachmentTemp)=> {
            arySupportingDocumentAttachmentsTemp.push({
              id: valueSupportingDocumentAttachmentTemp.attachmentFileId,
              preview: "",
              path: valueSupportingDocumentAttachmentTemp.attachmentFileName,
              webUrl: valueSupportingDocumentAttachmentTemp.attachmentWebUrl,
            })
          })
          validation.setFieldValue("arySupportingDocumentAttachments", arySupportingDocumentAttachmentsTemp);
        }

        if (resultAPI[0].financeTransactionAttachments) {
          let aryFinanceTransactionAttachmentsTemp : GeneralAttachmentListInterface[]  = [];
          resultAPI[0].financeTransactionAttachments.map((valueFinanceTransactionAttachmentTemp)=> {
            aryFinanceTransactionAttachmentsTemp.push({
              id: valueFinanceTransactionAttachmentTemp.attachmentFileId,
              preview: "",
              path: valueFinanceTransactionAttachmentTemp.attachmentFileName,
              webUrl: valueFinanceTransactionAttachmentTemp.attachmentWebUrl,
            })
          })
          validation.setFieldValue("aryFinanceTransactionAttachments", aryFinanceTransactionAttachmentsTemp);
        }

        validation.setFieldValue("customerSignature", resultAPI[0].customerSignature);
        validation.setFieldValue("firstApproverSignature", resultAPI[0].firstApproverSignature);
        validation.setFieldValue("financeRemark", resultAPI[0].financeRemark);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageChangePaymentMode], true)) {
      return;
    }

    fetchChangePaymentModeDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setChangePaymentModeDetail(undefined);
      }
    })
  }, []);

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <div>
          <h6 className="m-0 text-muted">
            {label}
          </h6>
        </div>
        <div>
          <h6 className="text-muted" style={{whiteSpace: "pre-wrap"}}>
            {value}
          </h6>
        </div>
      </Row>
    )
  }

  const displayPaymentDetails = (label, value) => {
    return (
      <div className="flex-direction-row mb-2">
        <div style={{minWidth: paymentDetailWidth}}>
          <Label className="mb-0">
            {label}
          </Label>
        </div>
        <div style={{minWidth: paymentDetailWidth, borderBottom: "1px solid"}}>
          <Label className="mb-0">
            {`: `} 
            <Label className="mb-0">
              {value}
            </Label>
          </Label>
        </div>
      </div>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={[]}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="changePaymentMode-title margin-bottom-16 ">
                      <Row>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "ChangePaymentMode" })}
                            </span>
                            <br />
                            <h3 className="font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {changePaymentModeFullDetail.branchName}
                            </h3>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "DocumentNo" })}:`, changePaymentModeFullDetail.changePaymentModeNo)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "Customer" })}:`, 
                              blnPermissionViewCustomer 
                              ?
                              <Link to={changePaymentModeFullDetail.customerId ? `/${RoutesList.customer}/view/${changePaymentModeFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 pointer-clickable">{changePaymentModeFullDetail.customerName || Constants.emptyData}</Label>
                              </Link>
                              :
                              `${changePaymentModeFullDetail.customerName}`
                            )}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "IdentityNo" })}:`, changePaymentModeFullDetail.customerIdentityNo)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "MobileNo" })}:`, changePaymentModeFullDetail.customerContactNo)}
                          </div>
                        </Col>
                        <Col xs="6" className="text-end">
                          {displayHeaderRowData(`${intl.formatMessage({ id: "PreparedBy" })}:`, `${changePaymentModeFullDetail.preparedBy}\n${moment(changePaymentModeFullDetail.preparedOn).format(Constants.displayDateAndTimeFormat)}`)}
                          {
                            changePaymentModeFullDetail.actionedBy
                            &&
                            <>
                              <br/>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "ActionBy" })}:`, `${changePaymentModeFullDetail.actionedBy}\n${moment(changePaymentModeFullDetail.actionedOn).format(Constants.displayDateAndTimeFormat)}`)}
                            </>
                          }
                        </Col>
                      </Row>
                    </div>
                    <hr/>
                    <Col xs="6">
                      <address>
                        <div className="margin-top-8">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          <br />
                          <div style={{ marginTop: '8px' }}>
                            <GeneralStatus 
                              status={changePaymentModeFullDetail.status}
                              statusColorFunction={changePaymentModeStatusColor}
                              blnDisableCenter/>
                          </div>
                        </div>
                      </address>
                    </Col>
                    {/* <div className="py-2 mt-4">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "1stPaymentDetails" })}
                      </h3>
                    </div> */}
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "1stPaymentDetails" })}
                      className="fw-bold margin-top-36"
                      titleSize="16px"
                      paddingBottom="12px"
                      blnSkipMarginTop={true}
                    />
                    {
                      changePaymentModeFullDetail.oldPaymentDetails.map((valueOldPaymentDetailTemp, indexOldPaymentDetail)=> (
                        <label 
                          key={`old_payment_details_${indexOldPaymentDetail}`}
                          className="card-radio mb-0 margin-left-8 card-radio-border-color-grey" 
                          style={{
                            width: "fit-content",
                            pointerEvents: "none"
                          }}>
                          <div className="margin-bottom-8">
                            <u className="">
                              <h5 className={`margin-bottom-0`}>{`${valueOldPaymentDetailTemp.paymentMethodName}${valueOldPaymentDetailTemp.cardType ? ` (${valueOldPaymentDetailTemp.cardType})` : ""}`}</h5>
                            </u>
                            <h5 className="payment-method-sub-text">{`${valueOldPaymentDetailTemp?.paymentMethodOptionName ? `${valueOldPaymentDetailTemp.paymentMethodOptionName}` : ""}${valueOldPaymentDetailTemp.paymentIssuerBankName ? ` - ${valueOldPaymentDetailTemp.paymentIssuerBankName}` : ""}`}</h5>
                          </div>
                          {displayPaymentDetails(intl.formatMessage({ id: "TransactionDate" }), moment(valueOldPaymentDetailTemp.transactionDate).format(Constants.displayDateAndTimeFormat))}
                          {displayPaymentDetails(intl.formatMessage({ id: "Bank" }), valueOldPaymentDetailTemp.bankName)}
                          {displayPaymentDetails(intl.formatMessage({ id: "CardOrAccountNo" }), valueOldPaymentDetailTemp.cardOrAccountNo)}
                          {displayPaymentDetails(intl.formatMessage({ id: "Amount" }), returnPriceWithCurrency(valueOldPaymentDetailTemp.amount))}
                        </label>
                      ))
                    }
                    {/* <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "TransactionDate" })}</th>
                            <th>{intl.formatMessage({ id: "CustomerSalesOrderNo" })}</th>
                            <th>{intl.formatMessage({ id: "PaymentMode" })}</th>
                            <th>{intl.formatMessage({ id: "BankName" })}</th>
                            <th>{intl.formatMessage({ id: "CardOrAccountNo" })}</th>
                            <th>{intl.formatMessage({ id: "Amount" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            changePaymentModeFullDetail.oldPaymentDetails.map((valueOldPaymentDetailTemp, indexOldPaymentDetail)=> (
                              <tr key={`old_payment_details_${indexOldPaymentDetail}`}>
                                <td>{`${moment(valueOldPaymentDetailTemp.transactionDate).format(Constants.displayDateAndTimeFormat)}`}</td>
                                <td>
                                {
                                  blnPermissionManageCustomerSalesOrder
                                  ?
                                  <Link to={valueOldPaymentDetailTemp.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${valueOldPaymentDetailTemp.salesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                                    <Label className="margin-bottom-0 margin-left-4">{valueOldPaymentDetailTemp.salesOrderNo || Constants.emptyData}</Label>
                                  </Link>
                                  :
                                  <Label className="margin-bottom-0 margin-left-4">{valueOldPaymentDetailTemp.salesOrderNo || Constants.emptyData}</Label>
                                }
                                </td>
                                <td>
                                  <p className={`margin-bottom-0`}>{`${valueOldPaymentDetailTemp.paymentMethodName}${valueOldPaymentDetailTemp.cardType ? ` (${valueOldPaymentDetailTemp.cardType} | ${valueOldPaymentDetailTemp.last4CardDigitNo}` : ""}`}</p>
                                  <p className="payment-method-sub-text">{`${valueOldPaymentDetailTemp?.paymentMethodOptionName ? `${valueOldPaymentDetailTemp.paymentMethodOptionName}` : ""}${valueOldPaymentDetailTemp.paymentIssuerBankName ? ` - ${valueOldPaymentDetailTemp.paymentIssuerBankName}` : ""}`}</p>
                                </td>
                                <td>{valueOldPaymentDetailTemp.bankName || Constants.emptyData}</td>
                                <td>{valueOldPaymentDetailTemp.cardOrAccountNo || Constants.emptyData}</td>
                                <td>{returnPriceWithCurrency(valueOldPaymentDetailTemp.amount)}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </div> */}
                    {/* <div className="py-2 mt-4">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "2ndPaymentDetails" })}
                      </h3>
                    </div> */}
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "2ndPaymentDetails" })}
                      className="fw-bold margin-top-36"
                      titleSize="16px"
                      paddingBottom="12px"
                      blnSkipMarginTop={true}
                    />
                    {
                      changePaymentModeFullDetail.newPaymentDetails.map((valueNewPaymentDetailTemp, indexNewPaymentDetail)=> (
                        <label 
                          key={`new_payment_details_${indexNewPaymentDetail}`}
                          className="card-radio mb-0 margin-left-8 card-radio-border-color-grey" 
                          style={{
                            width: "fit-content",
                            pointerEvents: "none"
                          }}>
                          <div className="margin-bottom-8">
                            <u className="">
                              <h5 className={`margin-bottom-0`}>{`${valueNewPaymentDetailTemp.paymentMethodName}${valueNewPaymentDetailTemp.cardType ? ` (${valueNewPaymentDetailTemp.cardType})` : ""}`}</h5>
                            </u>
                            <h5 className="payment-method-sub-text">{`${valueNewPaymentDetailTemp?.paymentMethodOptionName ? `${valueNewPaymentDetailTemp.paymentMethodOptionName}` : ""}${valueNewPaymentDetailTemp.paymentIssuerBankName ? ` - ${valueNewPaymentDetailTemp.paymentIssuerBankName}` : ""}`}</h5>
                          </div>
                          {displayPaymentDetails(intl.formatMessage({ id: "TransactionDate" }), moment(valueNewPaymentDetailTemp.transactionDate).format(Constants.displayDateFormat))}
                          {displayPaymentDetails(intl.formatMessage({ id: "Bank" }), valueNewPaymentDetailTemp.bankName)}
                          {displayPaymentDetails(intl.formatMessage({ id: "CardOrAccountNo" }), valueNewPaymentDetailTemp.cardOrAccountNo)}
                          {displayPaymentDetails(intl.formatMessage({ id: "Amount" }), returnPriceWithCurrency(valueNewPaymentDetailTemp.amount))}
                        </label>
                      ))
                    }
                    {/* <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "PaymentMode" })}</th>
                            <th>{intl.formatMessage({ id: "BankName" })}</th>
                            <th>{intl.formatMessage({ id: "CardOrAccountNo" })}</th>
                            <th>{intl.formatMessage({ id: "Amount" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            changePaymentModeFullDetail.newPaymentDetails.map((valueNewPaymentDetailTemp, indexNewPaymentDetail)=> (
                              <tr key={`new_payment_details_${indexNewPaymentDetail}`}>
                                <td>
                                  <p className={`margin-bottom-0`}>{`${valueNewPaymentDetailTemp.paymentMethodName}${valueNewPaymentDetailTemp.cardType ? ` (${valueNewPaymentDetailTemp.cardType})` : ""}`}</p>
                                  <p className="payment-method-sub-text">{`${valueNewPaymentDetailTemp?.paymentMethodOptionName ? `${valueNewPaymentDetailTemp.paymentMethodOptionName}` : ""}${valueNewPaymentDetailTemp.paymentIssuerBankName ? ` - ${valueNewPaymentDetailTemp.paymentIssuerBankName}` : ""}`}</p>
                                </td>
                                <td>{valueNewPaymentDetailTemp.bankName || Constants.emptyData}</td>
                                <td>{valueNewPaymentDetailTemp.cardOrAccountNo || Constants.emptyData}</td>
                                <td>{returnPriceWithCurrency(valueNewPaymentDetailTemp.amount)}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </div>  */}
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "AdditionalInformation" })}
                      className="fw-bold margin-top-36"
                      titleSize="16px"
                      paddingBottom="12px"
                      blnSkipMarginTop={true}
                    />
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Remark" })}
                      name="remark"
                      row={4}
                      field={changePaymentModeFullDetail}
                      setFieldValue={()=> {}}
                      disabled={true} />
                    <GeneralAttachment
                      title={intl.formatMessage({ id: "SupportingDocuments" })}
                      name="arySupportingDocumentAttachments"
                      className="mt-4"
                      validation={validation}
                      disabled={true}/>
                    <style type="text/css" media="print">{`
                      .page-break-declaration {
                        page-break-before: always;
                      }
                    `}</style>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "DeclarationConsent" })}
                      className="fw-bold margin-top-36 page-break-declaration"
                      titleSize="16px"
                      paddingBottom="12px"
                      blnSkipMarginTop={true}
                    />
                    <p 
                      style={{ 
                        fontSize: '14px', 
                        pageBreakInside: "avoid",
                        whiteSpace:"pre-wrap"
                      }}>
                      {intl.formatMessage({ id: "ChangePaymentModeDeclarationConsentTnC" })}
                    </p>
                    <div className="flex-direction-row flex-wrap">
                      <div>
                      <SignaturePad
                        title={intl.formatMessage({ id: "CustomerSignature" })}
                        className="margin-right-32"
                        successMessage={successMessageWithoutNavigation}
                        validation={validation}
                        disabled={Boolean(changePaymentModeFullDetail.customerSignature) || Boolean(successMessageWithoutNavigation) || !blnPermissionUpdateChangePaymentMode || !blnPendingSignStatus}
                        isSubmitting={localLoading}
                        signatureFieldName={"customerSignature"}
                        errorFieldName={"customerSignatureError"} />
                        {
                          changePaymentModeFullDetail.signedOn
                          &&
                          <p className="mt-2 mb-0" style={{fontSize: "14px"}}>
                            {intl.formatMessage({ id: "Name" })}: {changePaymentModeFullDetail.signedBy}
                            <br/>
                            {intl.formatMessage({ id: "Date" })}: {moment(changePaymentModeFullDetail.signedOn).format(Constants.displayDateAndTimeFormat)}
                          </p>
                        }
                      </div>
                      {
                        !blnPendingSignStatus
                        &&
                        <div>
                          <SignaturePad
                            title={intl.formatMessage({ id: "ManagerInCharge" })}
                            successMessage={successMessageWithoutNavigation}
                            validation={validation}
                            disabled={Boolean(changePaymentModeFullDetail.firstApproverSignature) || Boolean(successMessageWithoutNavigation) || !blnPermissionReviewChangePaymentModeFirstApproval || !blnPendingFirstApprovalStatus}
                            isSubmitting={localLoading}
                            signatureFieldName={"firstApproverSignature"}
                            errorFieldName={"firstApproverSignatureError"} />
                          {
                            changePaymentModeFullDetail.reviewedOn
                            &&
                            <p className="mt-2 mb-0" style={{fontSize: "14px"}}>
                              {intl.formatMessage({ id: "Name" })}: {changePaymentModeFullDetail.reviewedBy}
                              <br/>
                              {intl.formatMessage({ id: "Date" })}: {moment(changePaymentModeFullDetail.reviewedOn).format(Constants.displayDateAndTimeFormat)}
                            </p>
                          }
                        </div>
                      }
                    </div>
                    {
                      (blnPendingFinalApprovalStatus || blnCompletedStatus || blnRejectedStatus)
                      &&
                      <>
                        <LineBreakWithTittle
                          title={intl.formatMessage({ id: "FinanceUsedOnly" })}
                          className="fw-bold margin-top-36"
                          titleSize="16px"
                          paddingBottom="0px"
                          blnSkipMarginTop={true}
                        />
                        <GeneralTextArea
                          title={intl.formatMessage({ id: "Remark" })}
                          name="financeRemark"
                          className="mt-2"
                          row={5}
                          disabled={(blnCompletedStatus && blnCompletedRecordUpdated) || blnRejectedStatus || localLoading || !blnPermissionReviewChangePaymentModeFinalApproval}
                          validation={validation}/>
                        <GeneralAttachment
                          title={intl.formatMessage({ id: "TransactionSlips" })}
                          name="aryFinanceTransactionAttachments"
                          className="mt-4"
                          validation={validation}
                          blnMultipleFile={true}
                          disabled={(blnCompletedStatus && blnCompletedRecordUpdated) || blnRejectedStatus || localLoading || !blnPermissionReviewChangePaymentModeFinalApproval}/>
                      </>
                    }
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      {
                        blnPermissionUpdateChangePaymentMode
                        &&
                        blnPendingSignStatus
                        &&
                        !Boolean(successMessageWithoutNavigation)
                        &&
                        <>
                          <MyButton
                            type="button"
                            class="btn btn-info"
                            content={intl.formatMessage({ id: "Sign" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation) || !validation.values.customerSignature}
                            loading={localLoading}
                            onClick={async () => {
                              const valuesTemp: any = _.cloneDeep(validation.values);
                              if (!valuesTemp.customerSignature) {
                                validation.setFieldValue("customerSignatureError", true)
                              }
                              else {
                                let valuesFinal = {
                                  id: id,
                                  customerSignature: valuesTemp.customerSignature
                                }
                                try {
                                  setLocalLoading(true);
                                  let resultSignChangePaymentMode:any = await signChangePaymentMode(valuesFinal);
                                  if (resultSignChangePaymentMode) {
                                    if (resultSignChangePaymentMode.status) {
                                      if (resultSignChangePaymentMode.status === Constants.success) {
                                        fetchChangePaymentModeDetailAPI();
                                      }
                                    }
                                  }
                                } 
                                finally {
                                  setLocalLoading(false);
                                }
                              }
                            }}
                          />
                          <Link to={`/${RoutesList.changePaymentMode}/edit/${id}`} target={Constants.blank} rel="noopener noreferrer">
                            <MyButton
                              type="button"
                              class="btn btn-success margin-left-8"
                              content={intl.formatMessage({ id: "Edit" }).toUpperCase()}
                              disable={localLoading || Boolean(successMessageWithoutNavigation)}
                              loading={localLoading}
                            />
                          </Link>
                          <MyButton
                            type="button"
                            class="btn btn-warning margin-left-8"
                            content={intl.formatMessage({ id: "Cancel" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                            onClick={async () => {
                              setCancelChangePaymentModeModal(true);
                            }}
                          />
                        </>
                      }
                      {
                        blnPermissionReviewChangePaymentModeFirstApproval
                        &&
                        blnPendingFirstApprovalStatus
                        &&
                        !Boolean(successMessageWithoutNavigation)
                        &&
                        <>
                          <MyButton
                            type="button"
                            class="btn btn-success"
                            content={intl.formatMessage({ id: "Approve" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation) || !validation.values.firstApproverSignature}
                            loading={localLoading}
                            onClick={async () => {
                              const valuesTemp: any = _.cloneDeep(validation.values);
                              if (!valuesTemp.firstApproverSignature) {
                                validation.setFieldValue("firstApproverSignatureError", true)
                              }
                              else {
                                let valuesFinal : ChangePaymentModeReviewFirstApprovalObject = {
                                  id: id,
                                  firstApproverSignature: valuesTemp.firstApproverSignature,
                                  workflowAction: Constants.approve
                                }
                                try {
                                  setLocalLoading(true);
                                  let resultReviewFirstApprovalChangePaymentMode:any = await reviewFirstApprovalChangePaymentMode(valuesFinal);
                                  if (resultReviewFirstApprovalChangePaymentMode) {
                                    if (resultReviewFirstApprovalChangePaymentMode.status) {
                                      if (resultReviewFirstApprovalChangePaymentMode.status === Constants.success) {
                                        fetchChangePaymentModeDetailAPI();
                                      }
                                    }
                                  }
                                } 
                                finally {
                                  setLocalLoading(false);
                                }
                              }
                            }}
                          />
                          <MyButton
                            type="button"
                            class="btn btn-back margin-left-8"
                            style={{ width: '200px' }}
                            content={intl.formatMessage({ id: "RejectAndRevise" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                            onClick={async () => {
                              setManagerWorkflowAction(Constants.rejectAndRevise)
                              setRejectFirstApprovalChangePaymentModeModal(true);
                            }}
                          />
                          {/* <MyButton
                            type="button"
                            class="btn btn-danger margin-left-8"
                            style={{ width: '150px' }}
                            content={intl.formatMessage({ id: "RejectOnly" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                            onClick={async () => {
                              setManagerWorkflowAction(Constants.reject)
                              setRejectFirstApprovalChangePaymentModeModal(true);
                            }}
                          /> */}
                        </>
                      }
                      {
                        blnPermissionReviewChangePaymentModeFinalApproval
                        &&
                        blnPendingFinalApprovalStatus
                        &&
                        !Boolean(successMessageWithoutNavigation)
                        &&
                        <>
                          <MyButton
                            type="button"
                            class="btn btn-success"
                            content={intl.formatMessage({ id: "Approve" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                            onClick={async () => {
                              const valuesTemp: any = _.cloneDeep(validation.values);
                              let financeTransactionFilesTemp : any = [];
                              await Promise.all(valuesTemp.aryFinanceTransactionAttachments.map(async (valueAryFinanceTransactionAttachmentTemp) => {
                                if (valueAryFinanceTransactionAttachmentTemp.preview !== "") {
                                  let fileTemp : any = await returnBlobImage(valueAryFinanceTransactionAttachmentTemp.preview);
                                  let fileWithFileName = new File([fileTemp], valueAryFinanceTransactionAttachmentTemp.path);
                                  financeTransactionFilesTemp.push(fileWithFileName);
                                }
                              }))

                              let valuesFinal : ChangePaymentModeReviewFinalApprovalObject = {
                                id: id,
                                workflowAction: Constants.approve,
                                financeRemark: validation.values.financeRemark,
                                financeTransactionFiles: financeTransactionFilesTemp
                              }
                              
                              try {
                                setLocalLoading(true);
                                let resultReviewFirstApprovalChangePaymentMode: any = await reviewFinalApprovalChangePaymentMode(valuesFinal);
                                if (resultReviewFirstApprovalChangePaymentMode) {
                                  if (resultReviewFirstApprovalChangePaymentMode.status) {
                                    if (resultReviewFirstApprovalChangePaymentMode.status === Constants.success) {
                                      fetchChangePaymentModeDetailAPI();
                                    }
                                  }
                                }
                              } 
                              finally {
                                setLocalLoading(false);
                              }
                            }}
                          />
                          <MyButton
                            type="button"
                            class="btn btn-back margin-left-8"
                            style={{ width: '200px' }}
                            content={intl.formatMessage({ id: "RejectAndRevise" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                            onClick={async () => {
                              setFinanceWorkflowAction(Constants.rejectAndRevise)
                              setRejectFinalApprovalChangePaymentModeModal(true);
                            }}
                          />
                          <MyButton
                            type="button"
                            class="btn btn-danger margin-left-8"
                            style={{ width: '150px' }}
                            content={intl.formatMessage({ id: "RejectOnly" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                            onClick={async () => {
                              setFinanceWorkflowAction(Constants.reject)
                              setRejectFinalApprovalChangePaymentModeModal(true);
                            }}
                          />
                        </>
                      }
                      {
                        blnPermissionUpdateChangePaymentMode
                        &&
                        blnReviseRequiredStatus
                        &&
                        !Boolean(successMessageWithoutNavigation)
                        &&
                        <Link to={`/${RoutesList.changePaymentMode}/edit/${id}`} target={Constants.blank} rel="noopener noreferrer">
                          <MyButton
                            type="button"
                            class="btn btn-success"
                            content={intl.formatMessage({ id: "Edit" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessageWithoutNavigation)}
                            loading={localLoading}
                          />
                        </Link>
                      }
                      {
                        blnPermissionReviewChangePaymentModeFinalApproval 
                        &&
                        blnCompletedStatus
                        && 
                        !blnCompletedRecordUpdated
                        &&
                        <MyButton
                          type="button"
                          class="btn btn-success margin-right-8"
                          content={intl.formatMessage({ id: "Save" })}
                          disable={localLoading || Boolean(successMessageWithoutNavigation)}
                          loading={localLoading}
                          onClick={async ()=> {
                            let financeTransactionFilesTemp: any = [];
                            let financeTransactionFileIdsTemp: any = [];

                            const valuesTemp: any = _.cloneDeep(validation.values);
                            await Promise.all(valuesTemp.aryFinanceTransactionAttachments.map(async (valueAttachmentTemp) => {
                              if (valueAttachmentTemp.preview !== "") {
                                let fileTemp: any = await returnBlobImage(valueAttachmentTemp.preview);
                                let fileWithFileName = new File([fileTemp], valueAttachmentTemp.path);
                                financeTransactionFilesTemp.push(fileWithFileName);
                              }
                              if (valueAttachmentTemp.id) {
                                financeTransactionFileIdsTemp.push(valueAttachmentTemp.id);
                              }
                            }))

                            let valuesFinal : ChangePaymentModeUpdateAfterCompletedObject = {
                              id: id,
                              financeRemark: validation.values.financeRemark,
                              financeTransactionFiles: financeTransactionFilesTemp,
                              financeTransactionFileIds: financeTransactionFileIdsTemp
                            }
                            
                            try {
                              setLocalLoading(true);
                              let resultUpdateChangePaymentModeAfterCompleted: any = await updateChangePaymentModeAfterCompleted(valuesFinal);
                              if (resultUpdateChangePaymentModeAfterCompleted) {
                                if (resultUpdateChangePaymentModeAfterCompleted.status) {
                                  if (resultUpdateChangePaymentModeAfterCompleted.status === Constants.success) {
                                    setTimeout(()=> {
                                      history.push(`/${RoutesList.changePaymentMode}/view/${id}`)
                                    }, Constants.timeoutNavigation)
                                  }
                                }
                              }
                            } 
                            finally {
                              setLocalLoading(false);
                            }
                          }}
                        />
                      }
                      <GeneralSubmitAndCancelBtn
                        className="width-100-percentage"
                        successMessage={successMessageWithoutNavigation}
                        viewAction={false}
                        hidePrimaryButton={true}
                        showPrint={true}
                        cancelButtonBesidePrint={blnCompletedStatus || blnRejectedStatus ? false : true}
                        cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        {
          cancelChangePaymentModeModal
          &&
          <CancelOrRejectChangePaymentMode
            showPrompt={cancelChangePaymentModeModal}
            setShowPrompt={setCancelChangePaymentModeModal}
            title={intl.formatMessage({ id: "CancelChangePaymentModeAlert"}, { changePaymentModeNo: changePaymentModeFullDetail.changePaymentModeNo})}
            buttonTitle={intl.formatMessage({ id: "Cancel" })}
            intl={intl}
            changePaymentModeNo={changePaymentModeFullDetail.changePaymentModeNo}
            loading={localLoading}
            buttonFunction={async (remark)=> {
              try {
                setLocalLoading(true);
                let resultCancelChangePaymentMode = await cancelChangePaymentMode({id: id, remark: remark});
                if (resultCancelChangePaymentMode) {
                  if (resultCancelChangePaymentMode.status) {
                    if (resultCancelChangePaymentMode.status === Constants.success) {
                      fetchChangePaymentModeDetailAPI();
                      setCancelChangePaymentModeModal(false);
                    }
                  }
                }
              }
              finally
              {
                setLocalLoading(false);
              }
            }}/> 
        }
        {
          rejectFirstApprovalChangePaymentModeModal
          &&
          <CancelOrRejectChangePaymentMode
            showPrompt={rejectFirstApprovalChangePaymentModeModal}
            setShowPrompt={setRejectFirstApprovalChangePaymentModeModal}
            title={intl.formatMessage({ id: managerWorkflowAction === Constants.reject ? "RejectChangePaymentModeAlert" : "RejectAndReviseChangePaymentModeAlert"}, { changePaymentModeNo: changePaymentModeFullDetail.changePaymentModeNo})}
            buttonTitle={intl.formatMessage({ id: managerWorkflowAction === Constants.reject ? "Reject" :"RejectAndRevise" })}
            intl={intl}
            changePaymentModeNo={changePaymentModeFullDetail.changePaymentModeNo}
            loading={localLoading}
            buttonFunction={async (remark)=> {
              try {
                setLocalLoading(true);
                let resultCancelChangePaymentMode = await reviewFirstApprovalChangePaymentMode({
                  id: id, 
                  workflowAction: managerWorkflowAction,
                  remark: remark
                });
                if (resultCancelChangePaymentMode) {
                  if (resultCancelChangePaymentMode.status) {
                    if (resultCancelChangePaymentMode.status === Constants.success) {
                      fetchChangePaymentModeDetailAPI();
                      setRejectFirstApprovalChangePaymentModeModal(false);
                    }
                  }
                }
              }
              finally
              {
                setLocalLoading(false);
              }
            }}/> 
        }
        {
          rejectFinalApprovalChangePaymentModeModal
          &&
          <CancelOrRejectChangePaymentMode
            showPrompt={rejectFinalApprovalChangePaymentModeModal}
            setShowPrompt={setRejectFinalApprovalChangePaymentModeModal}
            title={intl.formatMessage({ id: financeWorkflowAction === Constants.reject ? "RejectChangePaymentModeAlert" : "RejectAndReviseChangePaymentModeAlert"}, { changePaymentModeNo: changePaymentModeFullDetail.changePaymentModeNo})}
            buttonTitle={intl.formatMessage({ id: financeWorkflowAction === Constants.reject ? "Reject" :"RejectAndRevise" })}
            intl={intl}
            defaultRemark={validation.values.financeRemark}
            changePaymentModeNo={changePaymentModeFullDetail.changePaymentModeNo}
            loading={localLoading}
            buttonFunction={async (remark)=> {
              try {
                setLocalLoading(true);
                let resultCancelChangePaymentMode = await reviewFinalApprovalChangePaymentMode({
                  id: id, 
                  workflowAction: financeWorkflowAction,
                  financeRemark: remark
                });
                if (resultCancelChangePaymentMode) {
                  if (resultCancelChangePaymentMode.status) {
                    if (resultCancelChangePaymentMode.status === Constants.success) {
                      fetchChangePaymentModeDetailAPI();
                      setRejectFinalApprovalChangePaymentModeModal(false);
                    }
                  }
                }
              }
              finally
              {
                setLocalLoading(false);
              }
            }}/> 
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(ChangePaymentModeDetail);
