import { PaginationRequestBody } from "../models/pagination";
import { RefundNoteListObject, RefundNoteAddObject, RefundNoteUpdateObject, RefundNoteDetailObject, CustomerCreditNoteListObject, RefundNoteReviewRequestBody } from "../models/refundNote";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class refundNoteStore {
  refundNoteList: RefundNoteListObject[] = [];
  refundNoteDetail: RefundNoteDetailObject | undefined = undefined;
  refundNotePaginationParams: PaginationRequestBody | undefined = undefined;
  customerCreditNoteList: CustomerCreditNoteListObject[] = [];
  customerCreditNoteTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.refundNoteList = [];
    this.refundNoteDetail = undefined;
    this.refundNotePaginationParams= undefined;
    this.customerCreditNoteList = [];
    this.customerCreditNoteTotalItems = 0;
  }

  setRefundNoteList = (refundNoteList: RefundNoteListObject[]) => {
    this.refundNoteList = refundNoteList;
  }

  setRefundNoteDetail = (refundNoteDetail: RefundNoteDetailObject | undefined) => {
    this.refundNoteDetail = refundNoteDetail;
  }

  setRefundNotePaginationParams = (refundNotePaginationParams: PaginationRequestBody | undefined) => {
    this.refundNotePaginationParams = refundNotePaginationParams;
  }
  
  getRefundNote = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setRefundNotePaginationParams(PaginationRequestBody);
    try{
      const resultRefundNote = await agent.RefundNote.refundNoteList(PaginationRequestBody);
      runInAction(() => {
        this.refundNoteList = resultRefundNote.data;
        store.commonStore.setTotalItem(resultRefundNote.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.refundNoteList = [];
    }
  }

  getRefundNoteWithId = async (id: string) => {
    try{
      const resultRefundNoteDetail = await agent.RefundNote.refundNoteDetail(id);
      runInAction(() => {
        this.refundNoteDetail = resultRefundNoteDetail;
      });
      return Promise.resolve(resultRefundNoteDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.refundNoteDetail = undefined;
    }
  }

  addRefundNote = async (refundNoteRequestBody: RefundNoteAddObject) => {
    try{
      await agent.RefundNote.addRefundNote(refundNoteRequestBody);
      store.commonStore.setSuccessMessage(`RefundNoteAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateRefundNote = async (refundNoteRequestBody: RefundNoteUpdateObject) => {
    try{
      await agent.RefundNote.updateRefundNote(refundNoteRequestBody);
      store.commonStore.setSuccessMessage(`RefundNoteUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteRefundNote  = async (id: string) => {
    try {
      await agent.RefundNote.deleteRefundNote(id);
      store.commonStore.setSuccessMessage(`RefundNoteDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  completeRefundNote = async (id: string) => {
    try{
      await agent.RefundNote.completeRefundNote(id);
      store.commonStore.setSuccessMessageWithoutNavigation(`RefundNoteCompleteSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  getCustomerCreditNote = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerCreditNote = await agent.RefundNote.customerCreditNoteList(PaginationRequestBody);
      runInAction(() => {
        this.customerCreditNoteList = resultCustomerCreditNote.data;
        this.customerCreditNoteTotalItems = resultCustomerCreditNote.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerCreditNoteList = [];
      this.customerCreditNoteTotalItems = 0;
    }
  }

  reviewRefundNote = async (reviewRefundNoteRequestBody: RefundNoteReviewRequestBody) => {
    try{
      await agent.RefundNote.reviewRefundNote(reviewRefundNoteRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`RefundNoteReviewSuccess`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }
}