import { StaticReferenceObject, StaticReferenceDataObject } from "../models/staticReference";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import _ from "lodash";
import { arraySpliceInAllValue, newSpaceBeforeCapitalLetter } from "../common/function/function";

export default class StaticReferenceStore {
  staticReference: StaticReferenceObject[] = [];
  productType: StaticReferenceDataObject[] = [];
  identityType: StaticReferenceDataObject[] = [];
  genderType: StaticReferenceDataObject[] = [];
  employeeType: StaticReferenceDataObject[] = [];
  titleType: StaticReferenceDataObject[] = [];
  appointmentStatusType: StaticReferenceDataObject[] = [];
  purchaseType: StaticReferenceDataObject[] = [];
  discountType: StaticReferenceDataObject[] = [];
  cardType: StaticReferenceDataObject[] = [];
  paymentStatus: StaticReferenceDataObject[] = [];
  stockRequestMethodType: StaticReferenceDataObject[] = [];
  commissionTypeList: StaticReferenceDataObject[] = [];
  customerDeliverOrderStatus: StaticReferenceDataObject[] = [];
  payrollCategoryType: StaticReferenceDataObject[] = [];
  purchaserType: StaticReferenceDataObject[] = [];
  stockRequestPurchaserType: StaticReferenceDataObject[] = [];
  stockRequestStatus: StaticReferenceDataObject[] = [];
  stockReceiveStatus: StaticReferenceDataObject[] = [];
  inboundNoticeStatus: StaticReferenceDataObject[] = [];
  purchaseOrderStatus: StaticReferenceDataObject[] = [];
  purchaseOrderType: StaticReferenceDataObject[] = [];
  purchasePaymentMethod: StaticReferenceDataObject[] = [];
  createPurchaseOrderType: StaticReferenceDataObject[] = [];
  goodsReceiveNoteStatus: StaticReferenceDataObject[] = [];
  purchaseDeliverOrderStatus: StaticReferenceDataObject[] = [];
  leaveType: StaticReferenceDataObject[] = [];
  notificationType: StaticReferenceDataObject[] = [];
  stockAdjustmentStatus: StaticReferenceDataObject[] = [];
  stockInOutStatus: StaticReferenceDataObject[] = [];
  stockOutReason: StaticReferenceDataObject[] = [];
  controlType: StaticReferenceDataObject[] = [];
  formType: StaticReferenceDataObject[] = [];
  paymentMethodOptionType: StaticReferenceDataObject[] = [];
  transferAccount: StaticReferenceDataObject[] = [];
  eWalletType: StaticReferenceDataObject[] = [];
  stockTakeStatus: StaticReferenceDataObject[] = [];
  debitType: StaticReferenceDataObject[] = [];
  auditLogEntityType: StaticReferenceDataObject[] = [];
  auditType: StaticReferenceDataObject[] = [];
  customerSearchType: StaticReferenceDataObject[] = [];
  appointmentSourceType: StaticReferenceDataObject[] = [];
  salesOrderStatus: StaticReferenceDataObject[] = [];
  calendarViewType: StaticReferenceDataObject[] = [];
  bookingSlotIntervalType: StaticReferenceDataObject[] = [];
  promotionType: StaticReferenceDataObject[] = [];
  redemptionCapType: StaticReferenceDataObject[] = [];
  exchangeToCreditStatus: StaticReferenceDataObject[] = [];
  leadStatus: StaticReferenceDataObject[] = [];
  creditAdjustmentType: StaticReferenceDataObject[] = [];
  commissionEarnType: StaticReferenceDataObject[] = [];
  productCommissionType: StaticReferenceDataObject[] = [];
  transferCreditAdjustmentType: StaticReferenceDataObject[] = [];
  leaveStatus: StaticReferenceDataObject[] = [];
  leavePeriodType: StaticReferenceDataObject[] = [];
  stockMovementType: StaticReferenceDataObject[] = [];
  creditHistoryEntityType: StaticReferenceDataObject[] = [];
  paymentAdjustmentStatus: StaticReferenceDataObject[] = [];
  customerProductReturnType: StaticReferenceDataObject[] = [];
  customerProductReturnStatus: StaticReferenceDataObject[] = [];
  stockReturnToHQStatus: StaticReferenceDataObject[] = [];
  extendPackageStatus: StaticReferenceDataObject[] = [];
  salesAdjustmentType: StaticReferenceDataObject[] = [];
  commissionSchemeType: StaticReferenceDataObject[] = [];
  accessRequestStatus: StaticReferenceDataObject[] = [];
  stockOutProductReasonType: StaticReferenceDataObject[] = [];
  customerTransferStatus: StaticReferenceDataObject[] = [];
  stockLevelStatus: StaticReferenceDataObject[] = [];
  additionBonusType: StaticReferenceDataObject[] = [];
  virtualWarehouseStatus: StaticReferenceDataObject[] = [];
  productRedemptionStatus: StaticReferenceDataObject[] = [];
  virtualWarehouseCreditExchangeStatus: StaticReferenceDataObject[] = [];
  productRedemptionNoteReturnStatus: StaticReferenceDataObject[] = [];
  createSalesAdjustmentType: StaticReferenceDataObject[] = [];
  dailySalesRecordType: StaticReferenceDataObject[] = [];
  batchJobRunningType: StaticReferenceDataObject[] = [];
  subBranchRequestType: StaticReferenceDataObject[] = [];
  customerSubBranchRequestStatus: StaticReferenceDataObject[] = [];
  searchCustomerCriteriaType: StaticReferenceDataObject[] = [];
  appolousSaleStatus: StaticReferenceDataObject[] = [];
  virtualWarehouseMovementType: StaticReferenceDataObject[] = [];
  marcomRecordType: StaticReferenceDataObject[] = [];
  dailySalesRecordTypeDropdownFilter: StaticReferenceDataObject[] = [];
  customerRelationshipType: StaticReferenceDataObject[] = [];
  customerValidateType: StaticReferenceDataObject[] = [];
  defaultFollowUpRemark: StaticReferenceDataObject[] = [];
  leadPlatformType: StaticReferenceDataObject[] = [];
  machineConsumptionType: StaticReferenceDataObject[] = [];
  machineUsageType: StaticReferenceDataObject[] = [];
  clType: StaticReferenceDataObject[] = [];
  earnSalesType: StaticReferenceDataObject[] = [];
  exchangeType : StaticReferenceDataObject[] = [];
  tncCategoryType : StaticReferenceDataObject[] = [];
  cashOutStatus: StaticReferenceDataObject[] = [];
  cashOutTransactionType: StaticReferenceDataObject[] = [];
  changePaymentModeStatus: StaticReferenceDataObject[] = [];
  closeCounterStatus: StaticReferenceDataObject[] = [];
  creditPaymentType: StaticReferenceDataObject[] = [];
  creditType: StaticReferenceDataObject[] = [];
  taxEntityBusinessType : StaticReferenceDataObject[] = [];
  taxEntityIdentityType : StaticReferenceDataObject[] = [];
  creditRedemptionNoteRequestStatus: StaticReferenceDataObject[] = [];
  creditNoteStatus: StaticReferenceDataObject[] = [];
  refundNoteStatus: StaticReferenceDataObject[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.staticReference = [];
    this.productType = [];
    this.identityType = [];
    this.genderType = [];
    this.employeeType = [];
    this.titleType = [];
    this.appointmentStatusType = [];
    this.purchaseType = [];
    this.discountType = [];
    this.cardType = [];
    this.paymentStatus = [];
    this.stockRequestMethodType = [];
    this.commissionTypeList = [];
    this.customerDeliverOrderStatus = [];
    this.payrollCategoryType = [];
    this.purchaserType = [];
    this.stockRequestPurchaserType = [];
    this.stockRequestStatus = [];
    this.stockReceiveStatus = [];
    this.inboundNoticeStatus = [];
    this.purchaseOrderStatus = [];
    this.purchaseOrderType = [];
    this.purchasePaymentMethod = [];
    this.createPurchaseOrderType = [];
    this.goodsReceiveNoteStatus = [];
    this.purchaseDeliverOrderStatus = [];
    this.leaveType = [];
    this.notificationType = [];
    this.stockAdjustmentStatus = [];
    this.stockInOutStatus = [];
    this.stockOutReason = [];
    this.controlType = [];
    this.formType = [];
    this.paymentMethodOptionType = [];
    this.transferAccount = [];
    this.eWalletType = [];
    this.stockTakeStatus = [];
    this.debitType = [];
    this.auditLogEntityType = [];
    this.auditType = [];
    this.customerSearchType = [];
    this.appointmentSourceType = []; 
    this.salesOrderStatus = [];
    this.calendarViewType = [];
    this.bookingSlotIntervalType = [];
    this.promotionType = [];
    this.redemptionCapType = [];
    this.exchangeToCreditStatus = [];
    this.leadStatus = [];
    this.creditAdjustmentType = [];
    this.commissionEarnType = [];
    this.productCommissionType = [];
    this.transferCreditAdjustmentType = [];
    this.leaveStatus = [];
    this.leavePeriodType = [];
    this.stockMovementType = [];
    this.creditHistoryEntityType = [];
    this.paymentAdjustmentStatus = [];
    this.customerProductReturnType = [];
    this.customerProductReturnStatus = [];
    this.stockReturnToHQStatus = [];
    this.extendPackageStatus = [];
    this.salesAdjustmentType = [];
    this.commissionSchemeType = [];
    this.accessRequestStatus = [];
    this.stockOutProductReasonType = [];
    this.customerTransferStatus = [];
    this.stockLevelStatus = [];
    this.additionBonusType = [];
    this.virtualWarehouseStatus = [];
    this.productRedemptionStatus = [];
    this.virtualWarehouseCreditExchangeStatus = [];
    this.productRedemptionNoteReturnStatus = [];
    this.createSalesAdjustmentType = [];
    this.dailySalesRecordType = [];
    this.batchJobRunningType = [];
    this.subBranchRequestType = [];
    this.customerSubBranchRequestStatus = [];
    this.searchCustomerCriteriaType = [];
    this.appolousSaleStatus = [];
    this.virtualWarehouseMovementType = [];
    this.dailySalesRecordTypeDropdownFilter = [];
    this.customerRelationshipType = [];
    this.customerValidateType = [];
    this.defaultFollowUpRemark = [];
    this.leadPlatformType = [];
    this.machineConsumptionType = [];
    this.machineUsageType = [];
    this.clType = [];
    this.earnSalesType = [];
    this.exchangeType = [];
    this.tncCategoryType = [];
    this.cashOutStatus = [];
    this.cashOutTransactionType = [];
    this.changePaymentModeStatus = [];
    this.closeCounterStatus = [];
    this.creditPaymentType = [];
    this.creditType = [];
    this.taxEntityBusinessType = [];
    this.taxEntityIdentityType = [];
    this.creditRedemptionNoteRequestStatus = [];
    this.creditNoteStatus = [];
    this.refundNoteStatus = [];
  }

  getStaticReference = async () => {
    try {
      if (this.staticReference.length < 1) {
        const resultStaticReference = await agent.StaticReference.staticReferenceList();
        runInAction(() => {
          let indexProduct = _.findIndex(resultStaticReference, {staticType: Constants.productType})
          this.productType = indexProduct > -1 ? resultStaticReference[indexProduct].staticReferenceData : []

          let indexIdendity = _.findIndex(resultStaticReference, {staticType: Constants.identityType})
          this.identityType = indexIdendity > -1 ? resultStaticReference[indexIdendity].staticReferenceData : []

          let indexGender = _.findIndex(resultStaticReference, {staticType: Constants.genderType})
          this.genderType = indexGender > -1 ? resultStaticReference[indexGender].staticReferenceData : []

          let indexEmployee = _.findIndex(resultStaticReference, {staticType: Constants.employeeType})
          this.employeeType = indexEmployee > -1 ? resultStaticReference[indexEmployee].staticReferenceData : []

          let indexTitle = _.findIndex(resultStaticReference, {staticType: Constants.titleType})
          this.titleType = indexTitle > -1 ? resultStaticReference[indexTitle].staticReferenceData : []

          let indexAppointmentStatusType = _.findIndex(resultStaticReference, {staticType: Constants.appointmentStatusType})
          if (indexAppointmentStatusType > -1) {
            this.appointmentStatusType = this.processAppointmentStatus(resultStaticReference[indexAppointmentStatusType].staticReferenceData);
          }
          else {
            this.appointmentStatusType = []
          }

          let indexPurchase = _.findIndex(resultStaticReference, {staticType: Constants.purchaseType})
          this.purchaseType = indexPurchase > -1 ? resultStaticReference[indexPurchase].staticReferenceData : []

          let indexDiscountType = _.findIndex(resultStaticReference, {staticType: Constants.discountType})
          this.discountType = indexDiscountType > -1 ? resultStaticReference[indexDiscountType].staticReferenceData : []
          
          let indexCardType = _.findIndex(resultStaticReference, {staticType: Constants.cardType})
          this.cardType = indexCardType > -1 ? resultStaticReference[indexCardType].staticReferenceData : []
          
          let indexPaymentStatus = _.findIndex(resultStaticReference, {staticType: Constants.paymentStatus})
          this.paymentStatus = indexPaymentStatus > -1 ? resultStaticReference[indexPaymentStatus].staticReferenceData : []

          let indexStockRequestMethodType = _.findIndex(resultStaticReference, {staticType: Constants.stockRequestMethodType})
          this.stockRequestMethodType = indexStockRequestMethodType > -1 ? resultStaticReference[indexStockRequestMethodType].staticReferenceData : []

          let indexCommissionType = _.findIndex(resultStaticReference, {staticType: Constants.commissionType})
          this.commissionTypeList = indexCommissionType > -1 ? resultStaticReference[indexCommissionType].staticReferenceData : []

          let indexCustomerDeliverOrderStatus = _.findIndex(resultStaticReference, {staticType: Constants.customerDeliverOrderStatus})
          this.customerDeliverOrderStatus = indexCustomerDeliverOrderStatus > -1 ? resultStaticReference[indexCustomerDeliverOrderStatus].staticReferenceData : []

          let indexPayrollCategoryType = _.findIndex(resultStaticReference, {staticType: Constants.payrollCategoryType})
          this.payrollCategoryType = indexPayrollCategoryType > -1 ? resultStaticReference[indexPayrollCategoryType].staticReferenceData : []

          let indexPurchaserType = _.findIndex(resultStaticReference, {staticType: Constants.purchaserType})
          this.purchaserType = indexPurchaserType > -1 ? resultStaticReference[indexPurchaserType].staticReferenceData : []

          let indexStockRequestPurchaserType = _.findIndex(resultStaticReference, {staticType: Constants.stockRequestPurchaserType})
          this.stockRequestPurchaserType = indexStockRequestPurchaserType > -1 ? resultStaticReference[indexStockRequestPurchaserType].staticReferenceData : []

          let indexStockRequestStatus = _.findIndex(resultStaticReference, {staticType: Constants.stockRequestStatus})
          this.stockRequestStatus = indexStockRequestStatus > -1 ? resultStaticReference[indexStockRequestStatus].staticReferenceData : []
          
          let indexStockReceiveStatus = _.findIndex(resultStaticReference, {staticType: Constants.stockReceiveStatus})
          this.stockReceiveStatus = indexStockReceiveStatus > -1 ? resultStaticReference[indexStockReceiveStatus].staticReferenceData : []
          
          let inboundNoticeRequestStatus = _.findIndex(resultStaticReference, {staticType: Constants.inboundNoticeStatus})
          this.inboundNoticeStatus = inboundNoticeRequestStatus > -1 ? resultStaticReference[inboundNoticeRequestStatus].staticReferenceData : []

          let indexPurchaseOrderStatus = _.findIndex(resultStaticReference, {staticType: Constants.purchaseOrderStatus})
          this.purchaseOrderStatus = indexPurchaseOrderStatus > -1 ? resultStaticReference[indexPurchaseOrderStatus].staticReferenceData : []

          let indexPurchaseOrderType = _.findIndex(resultStaticReference, {staticType: Constants.purchaseOrderType})
          this.purchaseOrderType = indexPurchaseOrderType > -1 ? resultStaticReference[indexPurchaseOrderType].staticReferenceData : []

          let indexPurchasePaymentMethod = _.findIndex(resultStaticReference, {staticType: Constants.purchasePaymentMethod})
          this.purchasePaymentMethod = indexPurchasePaymentMethod > -1 ? resultStaticReference[indexPurchasePaymentMethod].staticReferenceData : []

          let indexCreatePurchaseOrderType = _.findIndex(resultStaticReference, {staticType: Constants.createPurchaseOrderType})
          this.createPurchaseOrderType = indexCreatePurchaseOrderType > -1 ? resultStaticReference[indexCreatePurchaseOrderType].staticReferenceData : []
          
          let indexGoodsReceiveNoteStatus = _.findIndex(resultStaticReference, {staticType: Constants.goodsReceiveNoteStatus})
          this.goodsReceiveNoteStatus = indexGoodsReceiveNoteStatus > -1 ? resultStaticReference[indexGoodsReceiveNoteStatus].staticReferenceData : []

          let indexPurchaseDeliverOrderStatus = _.findIndex(resultStaticReference, {staticType: Constants.purchaseDeliverOrderStatus})
          this.purchaseDeliverOrderStatus = indexPurchaseDeliverOrderStatus > -1 ? resultStaticReference[indexPurchaseDeliverOrderStatus].staticReferenceData : []
          
          let indexLeaveType = _.findIndex(resultStaticReference, {staticType: Constants.leaveType})
          this.leaveType = indexLeaveType > -1 ? resultStaticReference[indexLeaveType].staticReferenceData : []

          let indexNotificationType = _.findIndex(resultStaticReference, {staticType: Constants.notificationType})
          this.notificationType = indexNotificationType > -1 ? resultStaticReference[indexNotificationType].staticReferenceData : []

          let indexStockInOutStatus = _.findIndex(resultStaticReference, {staticType: Constants.stockInOutStatus})
          this.stockInOutStatus = indexStockInOutStatus > -1 ? resultStaticReference[indexStockInOutStatus].staticReferenceData : []

          let indexStockAdjustmentStatus = _.findIndex(resultStaticReference, {staticType: Constants.stockAdjustmentStatus})
          this.notificationType = indexLeaveType > -1 ? resultStaticReference[indexStockAdjustmentStatus].staticReferenceData : []

          let indexStockOutReason = _.findIndex(resultStaticReference, {staticType: Constants.stockOutReason})
          this.stockOutReason = indexStockOutReason > -1 ? resultStaticReference[indexStockOutReason].staticReferenceData : []

          let indexControlType = _.findIndex(resultStaticReference, {staticType: Constants.controlType})
          this.controlType = indexControlType > -1 ? resultStaticReference[indexControlType].staticReferenceData : []

          let indexFormType = _.findIndex(resultStaticReference, {staticType: Constants.formType})
          this.formType = indexFormType > -1 ? resultStaticReference[indexFormType].staticReferenceData : []

          let indexPaymentMethodOptionType = _.findIndex(resultStaticReference, {staticType: Constants.paymentMethodOptionType})
          this.paymentMethodOptionType = indexPaymentMethodOptionType > -1 ? resultStaticReference[indexPaymentMethodOptionType].staticReferenceData : []

          let indexTransferAccount = _.findIndex(resultStaticReference, {staticType: Constants.transferAccount})
          this.transferAccount = indexTransferAccount > -1 ? resultStaticReference[indexTransferAccount].staticReferenceData : []

          let indexEWalletType = _.findIndex(resultStaticReference, {staticType: Constants.eWalletType})
          this.eWalletType = indexEWalletType > -1 ? resultStaticReference[indexEWalletType].staticReferenceData : []

          let indexStockTakeStatus = _.findIndex(resultStaticReference, {staticType: Constants.stockTakeStatus})
          this.stockTakeStatus = indexStockTakeStatus > -1 ? resultStaticReference[indexStockTakeStatus].staticReferenceData : []

          let indexDebitType = _.findIndex(resultStaticReference, {staticType: Constants.debitType})
          this.debitType = indexDebitType > -1 ? resultStaticReference[indexDebitType].staticReferenceData : []

          let indexAuditLogEntityType = _.findIndex(resultStaticReference, {staticType: Constants.auditLogEntityType})
          this.auditLogEntityType = indexAuditLogEntityType > -1 ? resultStaticReference[indexAuditLogEntityType].staticReferenceData : []

          let indexAuditType = _.findIndex(resultStaticReference, {staticType: Constants.auditType})
          this.auditType = indexAuditType > -1 ? resultStaticReference[indexAuditType].staticReferenceData : []
          
          let indexCustomerSearchType = _.findIndex(resultStaticReference, {staticType: Constants.customerSearchType})
          this.customerSearchType = indexCustomerSearchType > -1 ? resultStaticReference[indexCustomerSearchType].staticReferenceData : []

          let indexAppointmentSourceType = _.findIndex(resultStaticReference, {staticType: Constants.appointmentSourceType})
          this.appointmentSourceType = indexAppointmentSourceType > -1 ? resultStaticReference[indexAppointmentSourceType].staticReferenceData : []

          let indexSalesOrderStatus = _.findIndex(resultStaticReference, {staticType: Constants.salesOrderStatus})
          this.salesOrderStatus = indexSalesOrderStatus > -1 ? resultStaticReference[indexSalesOrderStatus].staticReferenceData : []

          let indexCalendarViewType = _.findIndex(resultStaticReference, {staticType: Constants.calendarViewType})
          this.calendarViewType = indexCalendarViewType > -1 ? resultStaticReference[indexCalendarViewType].staticReferenceData : []

          let indexBookingSlotIntervalType = _.findIndex(resultStaticReference, {staticType: Constants.bookingSlotIntervalType})
          this.bookingSlotIntervalType = indexBookingSlotIntervalType > -1 ? resultStaticReference[indexBookingSlotIntervalType].staticReferenceData : []

          let indexPromotionType = _.findIndex(resultStaticReference, {staticType: Constants.promotionType})
          this.promotionType = indexPromotionType > -1 ? resultStaticReference[indexPromotionType].staticReferenceData : []
          
          let indexRedemptionCapType = _.findIndex(resultStaticReference, {staticType: Constants.redemptionCapType})
          this.redemptionCapType = indexRedemptionCapType > -1 ? resultStaticReference[indexRedemptionCapType].staticReferenceData : []
          
          let indexExchangeToCreditStatus = _.findIndex(resultStaticReference, {staticType: Constants.exchangeToCreditStatus})
          this.exchangeToCreditStatus = indexExchangeToCreditStatus > -1 ? resultStaticReference[indexExchangeToCreditStatus].staticReferenceData : []

          let indexLeadStatus = _.findIndex(resultStaticReference, {staticType: Constants.leadStatus})
          this.leadStatus = indexLeadStatus > -1 ? resultStaticReference[indexLeadStatus].staticReferenceData : []

          let indexCreditAdjustmentType = _.findIndex(resultStaticReference, {staticType: Constants.creditAdjustmentType})
          this.creditAdjustmentType = indexCreditAdjustmentType > -1 ? resultStaticReference[indexCreditAdjustmentType].staticReferenceData : []
          
          let indexCommissionEarnType = _.findIndex(resultStaticReference, {staticType: Constants.commissionEarnType})
          this.commissionEarnType = indexCommissionEarnType > -1 ? resultStaticReference[indexCommissionEarnType].staticReferenceData : []
          
          let indexProductCommissionType = _.findIndex(resultStaticReference, {staticType: Constants.productCommissionType})
          this.productCommissionType = indexProductCommissionType > -1 ? resultStaticReference[indexProductCommissionType].staticReferenceData : []

          let indexTransferCreditAdjustmentType = _.findIndex(resultStaticReference, {staticType: Constants.transferCreditAdjustmentType})
          this.transferCreditAdjustmentType = indexTransferCreditAdjustmentType > -1 ? resultStaticReference[indexTransferCreditAdjustmentType].staticReferenceData : []
          
          let indexLeaveStatus = _.findIndex(resultStaticReference, {staticType: Constants.leaveStatus})
          this.leaveStatus = indexLeaveStatus > -1 ? resultStaticReference[indexLeaveStatus].staticReferenceData : []

          let indexLeavePeriodType = _.findIndex(resultStaticReference, {staticType: Constants.leavePeriodType})
          this.leavePeriodType = indexLeavePeriodType > -1 ? resultStaticReference[indexLeavePeriodType].staticReferenceData : []

          let indexStockMovementType = _.findIndex(resultStaticReference, {staticType: Constants.stockMovementType})
          this.stockMovementType = indexStockMovementType > -1 ? resultStaticReference[indexStockMovementType].staticReferenceData : []
          
          let indexCreditHistoryEntityType = _.findIndex(resultStaticReference, {staticType: Constants.creditHistoryEntityType})
          this.creditHistoryEntityType = indexCreditHistoryEntityType > -1 ? resultStaticReference[indexCreditHistoryEntityType].staticReferenceData : []
          
          let indexPaymentAdjustmentStatus = _.findIndex(resultStaticReference, {staticType: Constants.paymentAdjustmentStatus})
          this.paymentAdjustmentStatus = indexPaymentAdjustmentStatus > -1 ? resultStaticReference[indexPaymentAdjustmentStatus].staticReferenceData : []

          let indexCustomerProductReturnType = _.findIndex(resultStaticReference, {staticType: Constants.customerProductReturnType})
          this.customerProductReturnType = indexCustomerProductReturnType > -1 ? resultStaticReference[indexCustomerProductReturnType].staticReferenceData : []

          let indexCustomerProductReturnStatus = _.findIndex(resultStaticReference, {staticType: Constants.customerProductReturnStatus})
          this.customerProductReturnStatus = indexCustomerProductReturnStatus > -1 ? resultStaticReference[indexCustomerProductReturnStatus].staticReferenceData : []

          let indexStockReturnToHQStatus = _.findIndex(resultStaticReference, {staticType: Constants.stockReturnToHQStatus})
          this.stockReturnToHQStatus = indexStockReturnToHQStatus > -1 ? resultStaticReference[indexStockReturnToHQStatus].staticReferenceData : []
          
          let indexExtendPackageStatus = _.findIndex(resultStaticReference, {staticType: Constants.extendPackageStatus})
          this.extendPackageStatus = indexExtendPackageStatus > -1 ? resultStaticReference[indexExtendPackageStatus].staticReferenceData : []
          
          let indexSalesAdjustmentType = _.findIndex(resultStaticReference, {staticType: Constants.salesAdjustmentType})
          this.salesAdjustmentType = indexSalesAdjustmentType > -1 ? resultStaticReference[indexSalesAdjustmentType].staticReferenceData : []
          
          let indexCommissionSchemeType = _.findIndex(resultStaticReference, {staticType: Constants.commissionSchemeType})
          this.commissionSchemeType = indexCommissionSchemeType > -1 ? resultStaticReference[indexCommissionSchemeType].staticReferenceData : []
          
          let indexAccessRequestStatus = _.findIndex(resultStaticReference, {staticType: Constants.accessRequestStatus})
          this.accessRequestStatus = indexAccessRequestStatus > -1 ? resultStaticReference[indexAccessRequestStatus].staticReferenceData : []
          
          let indexCustomerTransferStatus = _.findIndex(resultStaticReference, {staticType: Constants.customerTransferStatus})
          this.customerTransferStatus = indexCustomerTransferStatus > -1 ? resultStaticReference[indexCustomerTransferStatus].staticReferenceData : []

          let indexStockOutProductReasonType = _.findIndex(resultStaticReference, {staticType: Constants.stockOutProductReasonType})
          this.stockOutProductReasonType = indexStockOutProductReasonType > -1 ? resultStaticReference[indexStockOutProductReasonType].staticReferenceData : []

          let indexStockLevelStatus = _.findIndex(resultStaticReference, {staticType: Constants.stockLevelStatus})
          this.stockLevelStatus = indexStockLevelStatus > -1 ? resultStaticReference[indexStockLevelStatus].staticReferenceData : []

          let indexAdditionBonusType = _.findIndex(resultStaticReference, {staticType: Constants.additionBonusType})
          this.additionBonusType = indexAdditionBonusType > -1 ? resultStaticReference[indexAdditionBonusType].staticReferenceData : []
          
          let indexVirtualWarehouseStatus = _.findIndex(resultStaticReference, {staticType: Constants.virtualWarehouseStatus})
          this.virtualWarehouseStatus = indexVirtualWarehouseStatus > -1 ? resultStaticReference[indexVirtualWarehouseStatus].staticReferenceData : []
          
          let indexProductRedemptionStatus = _.findIndex(resultStaticReference, {staticType: Constants.productRedemptionStatus})
          this.productRedemptionStatus = indexProductRedemptionStatus > -1 ? resultStaticReference[indexProductRedemptionStatus].staticReferenceData : []

          let indexVirtualWarehouseCreditExchangeStatus = _.findIndex(resultStaticReference, {staticType: Constants.virtualWarehouseCreditExchangeStatus})
          this.virtualWarehouseCreditExchangeStatus = indexVirtualWarehouseCreditExchangeStatus > -1 ? resultStaticReference[indexVirtualWarehouseCreditExchangeStatus].staticReferenceData : []
          
          let indexProductRedemptionNoteReturnStatus = _.findIndex(resultStaticReference, {staticType: Constants.productRedemptionNoteReturnStatus})
          this.productRedemptionNoteReturnStatus = indexProductRedemptionNoteReturnStatus > -1 ? resultStaticReference[indexProductRedemptionNoteReturnStatus].staticReferenceData : []

          let indexCreateSalesAdjustmentType = _.findIndex(resultStaticReference, {staticType: Constants.createSalesAdjustmentType})
          this.createSalesAdjustmentType = indexCreateSalesAdjustmentType > -1 ? resultStaticReference[indexCreateSalesAdjustmentType].staticReferenceData : []
          
          let indexDailySalesRecordType = _.findIndex(resultStaticReference, {staticType: Constants.dailySalesRecordType})
          this.dailySalesRecordType = indexDailySalesRecordType > -1 ? resultStaticReference[indexDailySalesRecordType].staticReferenceData : []
          
          let indexBatchJobRunningType = _.findIndex(resultStaticReference, {staticType: Constants.batchJobRunningType})
          this.batchJobRunningType = indexBatchJobRunningType > -1 ? resultStaticReference[indexBatchJobRunningType].staticReferenceData : []

          let indexSubBranchRequestType = _.findIndex(resultStaticReference, {staticType: Constants.subBranchRequestType})
          this.subBranchRequestType = indexSubBranchRequestType > -1 ? resultStaticReference[indexSubBranchRequestType].staticReferenceData : []
          
          let indexCustomerSubBranchRequestStatus = _.findIndex(resultStaticReference, {staticType: Constants.customerSubBranchRequestStatus})
          this.customerSubBranchRequestStatus = indexCustomerSubBranchRequestStatus > -1 ? resultStaticReference[indexCustomerSubBranchRequestStatus].staticReferenceData : []

          let indexSearchCustomerCriteriaType = _.findIndex(resultStaticReference, {staticType: Constants.searchCustomerCriteriaType})
          this.searchCustomerCriteriaType = indexSearchCustomerCriteriaType > -1 ? resultStaticReference[indexSearchCustomerCriteriaType].staticReferenceData : []
          
          let indexAppolousSaleStatus = _.findIndex(resultStaticReference, {staticType: Constants.appolousSaleStatus})
          this.appolousSaleStatus = indexAppolousSaleStatus > -1 ? resultStaticReference[indexAppolousSaleStatus].staticReferenceData : []
          
          let indexVirtualWarehouseStockMovementType = _.findIndex(resultStaticReference, {staticType: Constants.virtualWarehouseMovementType})
          this.virtualWarehouseMovementType = indexVirtualWarehouseStockMovementType > -1 ? resultStaticReference[indexVirtualWarehouseStockMovementType].staticReferenceData : []

          let indexDailySalesRecordTypeDropdownFilter = _.findIndex(resultStaticReference, {staticType: Constants.dailySalesRecordTypeDropdownFilter})
          this.dailySalesRecordTypeDropdownFilter = indexDailySalesRecordTypeDropdownFilter > -1 ? resultStaticReference[indexDailySalesRecordTypeDropdownFilter].staticReferenceData : []

          let indexCustomerRelationshipType = _.findIndex(resultStaticReference, {staticType: Constants.customerRelationshipType})
          if (indexCustomerRelationshipType > -1) {
            this.customerRelationshipType = this.processCustomerRelationshipType(resultStaticReference[indexCustomerRelationshipType].staticReferenceData);
          }
          else {
            this.customerRelationshipType = [];
          }

          let indexCustomerValidateType = _.findIndex(resultStaticReference, {staticType: Constants.customerValidateType})
          this.customerValidateType = indexCustomerValidateType > -1 ? resultStaticReference[indexCustomerValidateType].staticReferenceData : []

          let indexDefaultFollowUpRemark = _.findIndex(resultStaticReference, {staticType: Constants.defaultFollowUpRemark})
          this.defaultFollowUpRemark = indexDefaultFollowUpRemark > -1 ? resultStaticReference[indexDefaultFollowUpRemark].staticReferenceData : []

          let indexLeadPlatformType = _.findIndex(resultStaticReference, {staticType: Constants.leadPlatformType})
          this.leadPlatformType = indexLeadPlatformType > -1 ? resultStaticReference[indexLeadPlatformType].staticReferenceData : []

          let indexMachineConsumptionType = _.findIndex(resultStaticReference, {staticType: Constants.machineConsumptionType})
          this.machineConsumptionType = indexMachineConsumptionType > -1 ? resultStaticReference[indexMachineConsumptionType].staticReferenceData : []

          let indexMachineUsageType = _.findIndex(resultStaticReference, {staticType: Constants.machineUsageType})
          this.machineUsageType = indexMachineUsageType > -1 ? resultStaticReference[indexMachineUsageType].staticReferenceData : []

          let indexClType = _.findIndex(resultStaticReference, {staticType: Constants.clType})
          this.clType = indexClType > -1 ? resultStaticReference[indexClType].staticReferenceData : []          

          let indexEarnSalesType = _.findIndex(resultStaticReference, {staticType: Constants.earnSalesType})
          this.earnSalesType = indexEarnSalesType > -1 ? resultStaticReference[indexEarnSalesType].staticReferenceData : []

          let indexExchangeType = _.findIndex(resultStaticReference, {staticType: Constants.exchangeType})
          this.exchangeType = indexExchangeType > -1 ? resultStaticReference[indexExchangeType].staticReferenceData : []

          let indexTncCategoryType = _.findIndex(resultStaticReference, {staticType: Constants.tncCategoryType})
          this.tncCategoryType = indexTncCategoryType > -1? resultStaticReference[indexTncCategoryType].staticReferenceData : []
          
          let indexCashOutStatus = _.findIndex(resultStaticReference, {staticType: Constants.cashOutStatus})
          this.cashOutStatus = indexCashOutStatus > -1 ? resultStaticReference[indexCashOutStatus].staticReferenceData : []

          let indexCashOutTransactionType = _.findIndex(resultStaticReference, {staticType: Constants.cashOutTransactionType})
          this.cashOutTransactionType = indexCashOutTransactionType > -1 ? resultStaticReference[indexCashOutTransactionType].staticReferenceData : []

          let indexChangePaymentModeStatus = _.findIndex(resultStaticReference, {staticType: Constants.changePaymentModeStatus})
          this.changePaymentModeStatus = indexChangePaymentModeStatus > -1 ? resultStaticReference[indexChangePaymentModeStatus].staticReferenceData : []
          
          let indexCloseCounterStatus = _.findIndex(resultStaticReference, {staticType: Constants.closeCounterStatus})
          this.closeCounterStatus = indexCloseCounterStatus > -1 ? resultStaticReference[indexCloseCounterStatus].staticReferenceData : []

          let indexCreditPaymentType = _.findIndex(resultStaticReference, {staticType: Constants.creditPaymentType})
          this.creditPaymentType = indexCreditPaymentType > -1 ? resultStaticReference[indexCreditPaymentType].staticReferenceData : []
          
          let indexCreditType = _.findIndex(resultStaticReference, {staticType: Constants.creditType})
          this.creditType = indexCreditType > -1 ? resultStaticReference[indexCreditType].staticReferenceData : []
          
          let indexTaxEntityBusinessType = _.findIndex(resultStaticReference, {staticType: Constants.taxEntityBusinessType})
          this.taxEntityBusinessType = indexTaxEntityBusinessType > -1 ? resultStaticReference[indexTaxEntityBusinessType].staticReferenceData : []

          let indexTaxEntityIdentityType = _.findIndex(resultStaticReference, {staticType: Constants.taxEntityIdentityType})
          this.taxEntityIdentityType = indexTaxEntityIdentityType > -1 ? resultStaticReference[indexTaxEntityIdentityType].staticReferenceData : []
          
          let indexCreditRedemptionNoteRequestStatus = _.findIndex(resultStaticReference, {staticType: Constants.creditRedemptionNoteRequestStatus})
          this.creditRedemptionNoteRequestStatus = indexCreditRedemptionNoteRequestStatus > -1 ? resultStaticReference[indexCreditRedemptionNoteRequestStatus].staticReferenceData : []

          let indexCreditNoteStatus = _.findIndex(resultStaticReference, {staticType: Constants.creditNoteStatus})
          this.creditNoteStatus = indexCreditNoteStatus > -1 ? resultStaticReference[indexCreditNoteStatus].staticReferenceData : []

          let indexRefundNoteStatus = _.findIndex(resultStaticReference, {staticType: Constants.refundNoteStatus})
          this.refundNoteStatus = indexRefundNoteStatus > -1 ? resultStaticReference[indexRefundNoteStatus].staticReferenceData : []

          this.staticReference = resultStaticReference;
        });
      }
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  };

  getStaticReferenceWithType = async (type: string, allValueDisplay?: string) => {
    try {
      let aryExistingResultTemp : StaticReferenceDataObject[] = [];
      if (type == Constants.productType && this.productType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.productType);
      }
      if (type == Constants.identityType && this.identityType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.identityType);
      }
      else if (type == Constants.employeeType && this.employeeType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.employeeType);
      }
      else if (type == Constants.appointmentStatusType && this.appointmentStatusType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.appointmentStatusType);
      }
      else if (type == Constants.purchaseType && this.purchaseType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.purchaseType);
      }
      else if (type == Constants.discountType && this.discountType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.discountType);
      }
      else if (type == Constants.cardType && this.cardType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.cardType);
      }
      else if (type == Constants.paymentStatus && this.paymentStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.paymentStatus);
      }
      else if (type == Constants.stockRequestMethodType && this.stockRequestMethodType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockRequestMethodType);
      }
      else if (type == Constants.commissionType && this.commissionTypeList.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.commissionTypeList);
      }
      else if (type == Constants.customerDeliverOrderStatus && this.customerDeliverOrderStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.customerDeliverOrderStatus);
      }
      else if (type == Constants.payrollCategoryType && this.payrollCategoryType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.payrollCategoryType);
      }
      else if (type == Constants.purchaserType && this.purchaserType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.purchaserType);
      }
      else if (type == Constants.stockRequestPurchaserType && this.stockRequestPurchaserType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockRequestPurchaserType);
      }
      else if (type == Constants.stockRequestStatus && this.stockRequestStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockRequestStatus);
      }
      else if (type == Constants.stockReceiveStatus && this.stockReceiveStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockReceiveStatus);
      }
      else if (type == Constants.inboundNoticeStatus && this.inboundNoticeStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.inboundNoticeStatus);
      }
      else if (type == Constants.purchaseOrderStatus && this.purchaseOrderStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.purchaseOrderStatus);
      }
      else if (type == Constants.purchaseOrderType && this.purchaseOrderType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.purchaseOrderType);
      }
      else if (type == Constants.purchasePaymentMethod && this.purchasePaymentMethod.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.purchasePaymentMethod);
      }
      else if (type == Constants.createPurchaseOrderType && this.createPurchaseOrderType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.createPurchaseOrderType);
      }
      else if (type == Constants.goodsReceiveNoteStatus && this.goodsReceiveNoteStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.goodsReceiveNoteStatus);
      }
      else if (type == Constants.purchaseDeliverOrderStatus && this.purchaseDeliverOrderStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.purchaseDeliverOrderStatus);
      }
      else if (type == Constants.leaveType && this.leaveType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.leaveType);
      }        
      else if (type == Constants.notificationType && this.notificationType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.notificationType);
      }
      else if (type == Constants.stockAdjustmentStatus && this.stockAdjustmentStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockAdjustmentStatus);
      }
      else if (type == Constants.stockInOutStatus && this.stockInOutStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockInOutStatus);
      }
      else if (type == Constants.stockOutReason && this.stockOutReason.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockOutReason);
      }
      else if (type == Constants.controlType && this.controlType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.controlType);
      }
      else if (type == Constants.formType && this.formType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.formType);
      }
      else if (type == Constants.paymentMethodOptionType && this.paymentMethodOptionType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.paymentMethodOptionType);
      }
      else if (type == Constants.transferAccount && this.transferAccount.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.transferAccount);
      }
      else if (type == Constants.eWalletType && this.eWalletType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.eWalletType);
      }
      else if (type == Constants.stockTakeStatus && this.stockTakeStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockTakeStatus);
      }
      else if (type == Constants.debitType && this.debitType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.debitType);
      }
      else if (type == Constants.auditLogEntityType && this.auditLogEntityType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.auditLogEntityType);
      }
      else if (type == Constants.auditType && this.auditType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.auditType);
      }
      else if (type == Constants.customerSearchType && this.customerSearchType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.customerSearchType);
      }
      else if (type == Constants.appointmentSourceType && this.appointmentSourceType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.appointmentSourceType);
      }
      else if (type == Constants.salesOrderStatus && this.salesOrderStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.salesOrderStatus);
      }
      else if (type == Constants.calendarViewType && this.calendarViewType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.calendarViewType);
      }
      else if (type == Constants.bookingSlotIntervalType && this.bookingSlotIntervalType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.bookingSlotIntervalType);
      }
      else if (type == Constants.promotionType && this.promotionType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.promotionType);
      }
      else if (type == Constants.redemptionCapType && this.redemptionCapType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.redemptionCapType);
      }
      else if (type == Constants.exchangeToCreditStatus && this.exchangeToCreditStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.exchangeToCreditStatus);
      }
      else if (type == Constants.leadStatus && this.leadStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.leadStatus);
      }
      else if (type == Constants.creditAdjustmentType && this.creditAdjustmentType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.creditAdjustmentType);
      }
      else if (type == Constants.commissionEarnType && this.commissionEarnType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.commissionEarnType);
      }
      else if (type == Constants.productCommissionType && this.productCommissionType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.productCommissionType);
      }
      else if (type == Constants.transferCreditAdjustmentType && this.transferCreditAdjustmentType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.transferCreditAdjustmentType);
      }
      else if (type == Constants.leaveStatus && this.leaveStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.leaveStatus);
      }
      else if (type == Constants.leavePeriodType && this.leavePeriodType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.leavePeriodType);
      }
      else if (type == Constants.stockMovementType && this.stockMovementType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockMovementType);
      }
      else if (type == Constants.creditHistoryEntityType && this.creditHistoryEntityType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.creditHistoryEntityType);
      }
      else if (type == Constants.paymentAdjustmentStatus && this.paymentAdjustmentStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.paymentAdjustmentStatus);
      }
      else if (type == Constants.customerProductReturnType && this.customerProductReturnType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.customerProductReturnType);
      }
      else if (type == Constants.customerProductReturnStatus && this.customerProductReturnStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.customerProductReturnStatus);
      }  
      else if (type == Constants.extendPackageStatus && this.extendPackageStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.extendPackageStatus);
      }        
      else if (type == Constants.stockReturnToHQStatus && this.stockReturnToHQStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockReturnToHQStatus);
      }     
      else if (type == Constants.salesAdjustmentType && this.salesAdjustmentType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.salesAdjustmentType);
      }
      else if (type == Constants.commissionSchemeType && this.commissionSchemeType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.commissionSchemeType);
      }
      else if (type == Constants.accessRequestStatus && this.accessRequestStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.accessRequestStatus);
      }
      else if (type == Constants.stockOutProductReasonType && this.stockOutProductReasonType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockOutProductReasonType);
      }
      else if (type == Constants.customerTransferStatus && this.customerTransferStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.customerTransferStatus);
      }
      else if (type == Constants.stockLevelStatus && this.stockLevelStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.stockLevelStatus);
      }
      else if (type == Constants.additionBonusType && this.additionBonusType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.additionBonusType);
      }
      else if (type == Constants.virtualWarehouseStatus && this.virtualWarehouseStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.virtualWarehouseStatus);
      }
      else if (type == Constants.productRedemptionStatus && this.productRedemptionStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.productRedemptionStatus);
      }
      else if (type == Constants.virtualWarehouseCreditExchangeStatus && this.virtualWarehouseCreditExchangeStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.virtualWarehouseCreditExchangeStatus);
      }
      else if (type == Constants.productRedemptionNoteReturnStatus && this.productRedemptionNoteReturnStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.productRedemptionNoteReturnStatus);
      }
      else if (type == Constants.createSalesAdjustmentType && this.createSalesAdjustmentType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.createSalesAdjustmentType);
      }
      else if (type == Constants.dailySalesRecordType && this.dailySalesRecordType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.dailySalesRecordType);
      }  
      else if (type == Constants.batchJobRunningType && this.batchJobRunningType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.batchJobRunningType);
      }    
      else if (type == Constants.subBranchRequestType && this.subBranchRequestType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.subBranchRequestType);
      }    
      else if (type == Constants.customerSubBranchRequestStatus && this.customerSubBranchRequestStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.customerSubBranchRequestStatus);
      }    
      else if (type == Constants.searchCustomerCriteriaType && this.searchCustomerCriteriaType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.searchCustomerCriteriaType);
      }   
      else if (type == Constants.appolousSaleStatus && this.appolousSaleStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.appolousSaleStatus);
      }
      else if (type == Constants.virtualWarehouseMovementType && this.virtualWarehouseMovementType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.virtualWarehouseMovementType);
      } 
      else if (type == Constants.dailySalesRecordTypeDropdownFilter && this.dailySalesRecordTypeDropdownFilter.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.dailySalesRecordTypeDropdownFilter);
      }     
      else if (type == Constants.customerRelationshipType && this.customerRelationshipType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.customerRelationshipType);
      }         
      else if (type == Constants.customerValidateType && this.customerValidateType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.customerValidateType);
      }  
      else if (type == Constants.genderType && this.genderType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.genderType);
      }
      else if (type == Constants.defaultFollowUpRemark && this.defaultFollowUpRemark.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.defaultFollowUpRemark);
      }  
      else if (type == Constants.leadPlatformType && this.leadPlatformType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.leadPlatformType);
      }  
      else if (type == Constants.machineConsumptionType && this.machineConsumptionType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.machineConsumptionType);
      }   
      else if (type == Constants.machineUsageType && this.machineUsageType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.machineUsageType);
      }   
      else if (type == Constants.clType && this.clType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.clType);
      } 
      else if (type == Constants.earnSalesType && this.earnSalesType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.earnSalesType);
      } 
      else if (type == Constants.exchangeType && this.exchangeType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.exchangeType);
      } 
      else if (type == Constants.tncCategoryType && this.tncCategoryType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.tncCategoryType);
      }
      else if (type == Constants.cashOutStatus && this.cashOutStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.cashOutStatus);
      } 
      else if (type == Constants.cashOutTransactionType && this.cashOutTransactionType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.cashOutTransactionType);
      }
      else if (type == Constants.closeCounterStatus && this.closeCounterStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.closeCounterStatus);
      } 
      else if (type == Constants.changePaymentModeStatus && this.changePaymentModeStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.changePaymentModeStatus);
      } 
      else if (type == Constants.creditPaymentType && this.creditPaymentType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.creditPaymentType);
      }
      else if (type == Constants.creditType && this.creditType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.creditType);
      }
      else if (type == Constants.taxEntityBusinessType && this.taxEntityBusinessType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.taxEntityBusinessType);
      }
      else if (type == Constants.taxEntityIdentityType && this.taxEntityIdentityType.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.taxEntityIdentityType);
      }
      else if (type == Constants.creditRedemptionNoteRequestStatus && this.creditRedemptionNoteRequestStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.creditRedemptionNoteRequestStatus);
      }
      else if (type == Constants.creditNoteStatus && this.creditNoteStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.creditNoteStatus);
      }
      else if (type == Constants.refundNoteStatus && this.refundNoteStatus.length > 0) {
        aryExistingResultTemp = _.cloneDeep(this.refundNoteStatus);
      }
      
      if (aryExistingResultTemp.length > 0) {
        if (allValueDisplay) {
          arraySpliceInAllValue(aryExistingResultTemp, allValueDisplay)
        }
        return Promise.resolve(aryExistingResultTemp);
      }
      
      let resultStaticReferenceWithType = await agent.StaticReference.staticReferenceWithType(type);
      runInAction(() => {
        if (type == Constants.productType) {
          this.productType = resultStaticReferenceWithType.staticReferenceData;
        }
        if (type == Constants.identityType) {
          this.identityType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.employeeType) {
          this.employeeType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.appointmentStatusType) {
          this.appointmentStatusType = this.processAppointmentStatus(resultStaticReferenceWithType.staticReferenceData);
        }
        else if (type == Constants.purchaseType) {
          this.purchaseType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.discountType) {
          this.discountType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.cardType) {
          this.cardType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.paymentStatus) {
          this.paymentStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockRequestMethodType) {
          this.stockRequestMethodType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.commissionType) {
          this.commissionTypeList = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.customerDeliverOrderStatus) {
          this.customerDeliverOrderStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.payrollCategoryType) {
          this.payrollCategoryType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.purchaserType) {
          this.purchaserType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockRequestPurchaserType) {
          this.stockRequestPurchaserType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockRequestStatus) {
          this.stockRequestStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockReceiveStatus) {
          this.stockReceiveStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.inboundNoticeStatus) {
          this.inboundNoticeStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.purchaseOrderStatus) {
          this.purchaseOrderStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.purchaseOrderType) {
          this.purchaseOrderType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.purchasePaymentMethod) {
          this.purchasePaymentMethod = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.createPurchaseOrderType) {
          this.createPurchaseOrderType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.goodsReceiveNoteStatus) {
          this.goodsReceiveNoteStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.purchaseDeliverOrderStatus) {
          this.purchaseDeliverOrderStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.leaveType) {
          this.leaveType = resultStaticReferenceWithType.staticReferenceData;
        }        
        else if (type == Constants.notificationType) {
          this.notificationType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockAdjustmentStatus) {
          this.stockAdjustmentStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockInOutStatus) {
          this.stockInOutStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockOutReason) {
          this.stockOutReason = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.controlType) {
          this.controlType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.formType) {
          this.formType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.paymentMethodOptionType) {
          this.paymentMethodOptionType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.transferAccount) {
          this.transferAccount = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.eWalletType) {
          this.eWalletType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockTakeStatus) {
          this.stockTakeStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.debitType) {
          this.debitType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.auditLogEntityType) {
          this.auditLogEntityType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.auditType) {
          this.auditType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.customerSearchType) {
          this.customerSearchType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.appointmentSourceType) {
          this.appointmentSourceType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.salesOrderStatus) {
          this.salesOrderStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.calendarViewType) {
          this.calendarViewType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.bookingSlotIntervalType) {
          this.bookingSlotIntervalType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.promotionType) {
          this.promotionType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.redemptionCapType) {
          this.redemptionCapType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.exchangeToCreditStatus) {
          this.exchangeToCreditStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.leadStatus) {
          this.leadStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.creditAdjustmentType) {
          this.creditAdjustmentType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.commissionEarnType) {
          this.commissionEarnType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.productCommissionType) {
          this.productCommissionType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.transferCreditAdjustmentType) {
          this.transferCreditAdjustmentType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.leaveStatus) {
          this.leaveStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.leavePeriodType) {
          this.leavePeriodType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockMovementType) {
          this.stockMovementType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.creditHistoryEntityType) {
          this.creditHistoryEntityType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.paymentAdjustmentStatus) {
          this.paymentAdjustmentStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.customerProductReturnType) {
          this.customerProductReturnType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.customerProductReturnStatus) {
          this.customerProductReturnStatus = resultStaticReferenceWithType.staticReferenceData;
        }  
        else if (type == Constants.extendPackageStatus) {
          this.extendPackageStatus = resultStaticReferenceWithType.staticReferenceData;
        }        
        else if (type == Constants.stockReturnToHQStatus) {
          this.stockReturnToHQStatus = resultStaticReferenceWithType.staticReferenceData;
        }     
        else if (type == Constants.salesAdjustmentType) {
          this.salesAdjustmentType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.commissionSchemeType) {
          this.commissionSchemeType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.accessRequestStatus) {
          this.accessRequestStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockOutProductReasonType) {
          this.stockOutProductReasonType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.customerTransferStatus) {
          this.customerTransferStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.stockLevelStatus) {
          this.stockLevelStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.additionBonusType) {
          this.additionBonusType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.virtualWarehouseStatus) {
          this.virtualWarehouseStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.productRedemptionStatus) {
          this.productRedemptionStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.virtualWarehouseCreditExchangeStatus) {
          this.virtualWarehouseCreditExchangeStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.productRedemptionNoteReturnStatus) {
          this.productRedemptionNoteReturnStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.createSalesAdjustmentType) {
          this.createSalesAdjustmentType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.dailySalesRecordType) {
          this.dailySalesRecordType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.batchJobRunningType) {
          this.batchJobRunningType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.subBranchRequestType) {
          this.subBranchRequestType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.customerSubBranchRequestStatus) {
          this.customerSubBranchRequestStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.searchCustomerCriteriaType) {
          this.searchCustomerCriteriaType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.appolousSaleStatus) {
          this.appolousSaleStatus = resultStaticReferenceWithType.staticReferenceData;
        }   
        else if (type == Constants.virtualWarehouseMovementType) {
          this.virtualWarehouseMovementType = resultStaticReferenceWithType.staticReferenceData;
        }   
        else if (type == Constants.dailySalesRecordTypeDropdownFilter) {
          this.dailySalesRecordTypeDropdownFilter = resultStaticReferenceWithType.staticReferenceData;
        }    
        else if (type == Constants.customerRelationshipType) {
          this.customerRelationshipType = this.processCustomerRelationshipType(resultStaticReferenceWithType.staticReferenceData);
        }
        else if (type == Constants.customerValidateType) {
          this.customerValidateType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.genderType) {
          this.genderType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.defaultFollowUpRemark) {
          this.defaultFollowUpRemark = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.leadPlatformType) {
          this.leadPlatformType = resultStaticReferenceWithType.staticReferenceData;
        }     
        else if (type == Constants.machineConsumptionType) {
          this.machineConsumptionType = resultStaticReferenceWithType.staticReferenceData;
        }     
        else if (type == Constants.machineUsageType) {
          this.machineUsageType = resultStaticReferenceWithType.staticReferenceData;
        }   
        else if (type == Constants.clType) {
          this.clType = resultStaticReferenceWithType.staticReferenceData;
        }   
        else if (type == Constants.earnSalesType) {
          this.earnSalesType = resultStaticReferenceWithType.staticReferenceData;
        }       
        else if (type == Constants.exchangeType) {
          this.exchangeType = resultStaticReferenceWithType.staticReferenceData;
        }  
        else if (type == Constants.tncCategoryType) {
          this.tncCategoryType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.cashOutStatus) {
          this.cashOutStatus = resultStaticReferenceWithType.staticReferenceData;
        } 
        else if (type == Constants.cashOutTransactionType) {
          this.cashOutTransactionType = resultStaticReferenceWithType.staticReferenceData;
        } 
        else if (type == Constants.changePaymentModeStatus) {
          this.changePaymentModeStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.closeCounterStatus) {
          this.closeCounterStatus = resultStaticReferenceWithType.staticReferenceData;
        } 
        else if (type == Constants.creditPaymentType) {
          this.creditPaymentType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.creditType) {
          this.creditType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.taxEntityBusinessType) {
          this.taxEntityBusinessType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.taxEntityIdentityType) {
          this.taxEntityIdentityType = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.creditRedemptionNoteRequestStatus) {
          this.creditRedemptionNoteRequestStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.creditNoteStatus) {
          this.creditNoteStatus = resultStaticReferenceWithType.staticReferenceData;
        }
        else if (type == Constants.refundNoteStatus) {
          this.refundNoteStatus = resultStaticReferenceWithType.staticReferenceData;
        }
      });
      
      if (allValueDisplay) {
        arraySpliceInAllValue(resultStaticReferenceWithType.staticReferenceData, allValueDisplay)
      }
      return Promise.resolve(resultStaticReferenceWithType.staticReferenceData);
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  };

  getStaticReferenceAuditModules = async () => {
    try{
      const resultStaticReferenceAuditModulesDropdown = await agent.StaticReference.staticReferenceAuditModule();
      
      let finalGroupData : any = [{label: "All", value: " ", options: [{label: "All Type", value: " "}]}];
      resultStaticReferenceAuditModulesDropdown.map((valueStaticReferenceAuditModuleTemp)=> {
        valueStaticReferenceAuditModuleTemp.modules = valueStaticReferenceAuditModuleTemp.modules.map((valueModuleTemp)=> ({
          ...valueModuleTemp,
          label: `${newSpaceBeforeCapitalLetter(valueModuleTemp.moduleName)}${valueModuleTemp.isLegacy ? "*" : ""}`,
          value: valueModuleTemp.moduleName
        }))

        finalGroupData.push({
          label: `${valueStaticReferenceAuditModuleTemp.categoryName}`,
          value: `${valueStaticReferenceAuditModuleTemp.categoryName}`,
          options: valueStaticReferenceAuditModuleTemp.modules,
        })
      })

      return Promise.resolve(finalGroupData);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  processAppointmentStatus = (staticReferenceDataTemp) => {
    if (staticReferenceDataTemp) {
      let statusArrived = staticReferenceDataTemp.length > 7 ? staticReferenceDataTemp[7] : undefined;
      if (statusArrived) {
        staticReferenceDataTemp.splice(2, 0, statusArrived);
        staticReferenceDataTemp.splice(-1);
      }

      _.remove(staticReferenceDataTemp, {displayValue: Constants.special});
    }
    return staticReferenceDataTemp;
  }

  processCustomerRelationshipType = (staticReferenceDataTemp) => {
    if (staticReferenceDataTemp) {
      _.remove(staticReferenceDataTemp, {displayValue: Constants.parent});
    }
    return staticReferenceDataTemp;
  }
}
