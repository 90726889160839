import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, getBranchId, getBranchName, arraySpliceInAllValue, getYears, getMonths, returnSubBrandList, displaySummaryDetailsTableCellUI, firstDayOfMonthToCurrentDay, returnPriceWithCurrency } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import MyButton from "../../../app/components/form/MyButton";
import { SubBrandObject } from "../../../app/models/brand";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { BusinessEntityListObject } from "../../../app/models/businessEntity";
import { Link } from 'react-router-dom';

const CommissionCalculation = () => {
  const intl = useIntl();
  //Use Store
  const { reportAccountStore, branchStore, businessEntityStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize } = commonStore;
  const { commissionCalculationList, commissionCalculationNotes, getCommissionCalculationList, setCommissionCalculationList, setCommissionCalculationNotes, exportCommissionCalculation } = reportAccountStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getBusinessEntityDropDown } = businessEntityStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const AllBusinessEntity = `${intl.formatMessage({ id: "AllBusinessEntity" })}`;
  const [selectedBusinessEntityName, setSelectedBusinessEntityName] = useState(AllBusinessEntity);
  const [selectedBusinessEntityId, setSelectedBusinessEntityId] = useState(" ");
  const [businessEntityListFinal, setBusinessEntityListFinal] = useState<BusinessEntityListObject[]>([]);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const dateRangeLimit = {
    fromDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
    toDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
  }
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [initialData, setInitialData] = useState(true);
  const [localLoadingDateRange, setLocalLoadingDateRange] = useState(false);

  const colorBlack = Constants.tableCellGrey;
  const colorWhite = "";
  const colorLightBlue = '#dae9f8';
  const colorLightGreen = '#daf2d0';
  const colorLightOrange = '#fbe2d5';
  const colorLightPink = '#f2ceef';

  const blnPermissionExportAccountReport = checkPermission([PermissionConstants.ExportAccountReport]);
  const blnPermissionManageSalesReport = checkPermission([PermissionConstants.ManageSalesReport]);
  const blnPermissionManagePaymentReport = checkPermission([PermissionConstants.ManagePaymentReport]);

  const tableDetailWidth = "140px";
  const tableDetailWidthV2 = "140px";

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths());

    if (returnSubBrandList().length > 0) {
      setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
    }

    async function fetchCommissionCalculationListAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getBusinessEntityDropDown()
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setBusinessEntityListFinal(arraySpliceInAllValue(resultAPI[1], AllBusinessEntity, true))

      setLoading(false);
      setInitialData(false);
    }

    if (!checkPermission([PermissionConstants.ManageAccountReport], true)) {
      return;
    }

    fetchCommissionCalculationListAPI();

    return (() => {
      setCommissionCalculationList([]);
      setCommissionCalculationNotes("");
    })
  }, []);

  useEffect(()=> {
    if (!initialData) {
      setSelectedDateRange(dateRangeLimit)
      setLocalLoadingDateRange(true);
      setTimeout(()=> {
        setLocalLoadingDateRange(false);
      }, 100)
    }
  }, [selectedYear, selectedMonthValue])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const fetchCommissionCalculationListWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if(blnExport){
      await exportCommissionCalculation({ pageNumber: 1, pageSize:pageSize, branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, subBrandId: selectedSubBrandId, businessEntityId: selectedBusinessEntityId });
    }
    else{
      await getCommissionCalculationList({ pageNumber: 1, pageSize:pageSize, branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, subBrandId: selectedSubBrandId, businessEntityId: selectedBusinessEntityId });
    }
    setLocalLoading(false);
  };

  const returnHeaderCss = () => {
    return {
      // borderRight: `solid ${Constants.lightgreyfaded}`, 
      // borderRightWidth: "1px"
    }
  }

  const returnTableDataCellCss = () => {
    return {
      height: 0,
      paddingTop: 0,
      paddingBottom: 0
    }
  }

  const returnTableFormatterCss = () => {
    return {
      height: '100%',
    }
  }

  //Table Content UI
  const CommissionCalculationColumns = [
    {
      dataField: "businessEntityName",
      text: intl.formatMessage({ id: "BusinessEntity" }).toUpperCase(),
      headerStyle: {...returnHeaderCss(), backgroundColor: colorBlack, color: colorWhite},
      style: returnTableDataCellCss(),
      formatter: (cellContent, row, index) => (
        <div className="flex-direction-row" style={returnTableFormatterCss()}>
          {displayTableDetailColumn(row.businessEntityName, true)}
        </div>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      headerStyle: {...returnHeaderCss(), backgroundColor: colorBlack, color: colorWhite},
      style: returnTableDataCellCss(),
      formatter: (cellContent, row, index) => (
        <div className="flex-direction-row" style={returnTableFormatterCss()}>
          {displayTableDetailColumn(row.branchName, true)}
        </div>
      ),
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      headerStyle: {...returnHeaderCss(), backgroundColor: colorBlack, color: colorWhite},
      style: returnTableDataCellCss(),
      hidden: returnSubBrandList().length < 1,
      formatter: (cellContent, row, index) => (
        <div className="flex-direction-row" style={{minWidth: '80px', ...returnTableFormatterCss()}}>
          {displayTableDetailColumn(row.subBrandName, true)}
        </div>
      ),
    },
    {
      dataField: "groupSales",
      text: intl.formatMessage({ id: "GroupCommissionSales" }).toUpperCase(),
      headerStyle: {...returnHeaderCss(), textAlign: 'center', backgroundColor: colorLightBlue},
      style: {...returnTableDataCellCss(), backgroundColor: colorLightBlue},
      formatter: (cellContent, row, index) => (
        <div className="flex-direction-row" style={returnTableFormatterCss()}>
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "Invoice" }) : returnPriceWithCurrency(row.groupInvoiceSales), false, tableDetailWidth, "", index)}
          {/* {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "EarnedPlus" }) : returnPriceWithCurrency(row.groupEarnedSales), false, tableDetailWidth, returnSharedSalesSummaryRoutePath(row, index), index)}
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "SharedMinus" }) : returnPriceWithCurrency(row.groupSharedSales), false, tableDetailWidth, returnSharedSalesSummaryRoutePath(row, index), index)} */}
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "EarnedPlus" }) : returnPriceWithCurrency(row.groupEarnedSales), false, tableDetailWidth, "", index)}
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "SharedMinus" }) : returnPriceWithCurrency(row.groupSharedSales), false, tableDetailWidth, "", index)}
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "Total" }) : returnPriceWithCurrency(row.groupTotalSales), true, tableDetailWidth, returnBranchSalesSummaryRoutePath(row, index), index)}
        </div>
      ),
    },
    {
      dataField: "productSales",
      text: intl.formatMessage({ id: "ProductCommissionSales" }).toUpperCase(),
      headerStyle: {...returnHeaderCss(), textAlign: 'center', backgroundColor: colorLightGreen},
      style: {...returnTableDataCellCss(), backgroundColor: colorLightGreen},
      formatter: (cellContent, row, index) => (
        <div className="flex-direction-row" style={returnTableFormatterCss()}>
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "Invoice" }) : returnPriceWithCurrency(row.productInvoiceSales), false, tableDetailWidth, "", index)}
          {/* {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "EarnedPlus" }) : returnPriceWithCurrency(row.productEarnedSales), false, tableDetailWidth, returnSharedSalesSummaryRoutePath(row, index), index)}
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "SharedMinus" }) : returnPriceWithCurrency(row.productSharedSales), false, tableDetailWidth, returnSharedSalesSummaryRoutePath(row, index), index)} */}
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "EarnedPlus" }) : returnPriceWithCurrency(row.productEarnedSales), false, tableDetailWidth, "", index)}
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "SharedMinus" }) : returnPriceWithCurrency(row.productSharedSales), false, tableDetailWidth, "", index)}
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "Total" }) : returnPriceWithCurrency(row.productTotalSales), true, tableDetailWidth, returnBranchSalesSummaryRoutePath(row, index), index)}
        </div>
      ),
    },
    {
      dataField: "totalCommissionSales",
      text: intl.formatMessage({ id: "CommissionSales" }).toUpperCase(),
      headerStyle: {...returnHeaderCss(), textAlign: 'center', backgroundColor: colorBlack, color: colorWhite},
      style: returnTableDataCellCss(),
      formatter: (cellContent, row, index) => (
        <div className="flex-direction-row" style={returnTableFormatterCss()}>
          {displayTableDetailColumn(index === 0 ? Constants.emptyData : returnPriceWithCurrency(row.totalCommissionSales), true, "", returnBranchSalesSummaryRoutePath(row, index), index)}
        </div>
      ),
    },
    {
      dataField: "totalCommissionSalesAdjustment",
      text: intl.formatMessage({ id: "CommissionSalesAdjustment" }).toUpperCase(),
      headerStyle: {...returnHeaderCss(), textAlign: 'center', backgroundColor: colorBlack, color: colorWhite},
      style: returnTableDataCellCss(),
      formatter: (cellContent, row, index) => (
        <div className="flex-direction-row" style={returnTableFormatterCss()}>
          {displayTableDetailColumn(index === 0 ? Constants.emptyData : returnPriceWithCurrency(row.totalCommissionSalesAdjustment), true, "", returnSalesAdjustmentHistoryRoutePath(row, index), index)}
        </div>
      ),
    },
    {
      dataField: "totalInvoiceSales",
      text: "",
      headerStyle: {...returnHeaderCss(), backgroundColor: colorLightOrange},
      style: {...returnTableDataCellCss(), backgroundColor: colorLightOrange},
      formatter: (cellContent, row, index) => (
        <div className="flex-direction-row" style={returnTableFormatterCss()}>
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "InvoiceAmountForReport" }).toUpperCase() : returnPriceWithCurrency(row.totalInvoiceSales), true, tableDetailWidthV2, "", index)}
        </div>
      ),
    },
    {
      dataField: "collection",
      text: intl.formatMessage({ id: "Collection" }).toUpperCase(),
      headerStyle: {...returnHeaderCss(), textAlign: 'center', backgroundColor: colorLightPink},
      style: {...returnTableDataCellCss(), backgroundColor: colorLightPink},
      formatter: (cellContent, row, index) => (
        <div className="flex-direction-row" style={returnTableFormatterCss()}>
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "FTotal" }) : returnPriceWithCurrency(row.totalPaymentCollection), false, tableDetailWidth, returnCombinedPaymentCollectionSummaryRoutePath(row, index), index)}
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "GCollectionOnBehehalfOf" }) : returnPriceWithCurrency(row.paymentCollectOnbehalfDeduction), false, tableDetailWidth, returnCollectOnBehalfDetailsRoutePath(row, index, true), index)}
          {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "HCollectiononBehalf" }) : returnPriceWithCurrency(row.paymentCollectOnbehalfAddition), true, tableDetailWidth, returnCollectOnBehalfDetailsRoutePath(row, index, false), index)}
        </div>
      ),
    },
  ];

  const returnSharedSalesSummaryRoutePath = (row, index) => {
    return blnPermissionManageSalesReport && index !== 0 && index !== commissionCalculationList.length-1 ? `/${RoutesList.report}/${RoutesList.sharedSalesSummary}?branchId=${index === commissionCalculationList.length-1 ? " " : row.branchId}&subBrandId=${index === commissionCalculationList.length-1 ? " " : row.subBrandId || ""}&year=${selectedYear}&month=${selectedMonthValue}&fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}` : "";
  }

  const returnBranchSalesSummaryRoutePath = (row, index) => {
    return blnPermissionManageSalesReport && index !== 0 ? `/${RoutesList.report}/${RoutesList.branchSalesSummary}?branchId=${index === commissionCalculationList.length-1 ? " " : row.branchId}&subBrandId=${index === commissionCalculationList.length-1 ? " " : row.subBrandId || ""}&year=${selectedYear}&month=${selectedMonthValue}&fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}` : "";
  }

  const returnSalesAdjustmentHistoryRoutePath = (row, index) => {
    return blnPermissionManageSalesReport && index !== 0 ? `/${RoutesList.report}/${RoutesList.salesAdjustmentHistory}?branchId=${index === commissionCalculationList.length-1 ? " " : row.branchId}&fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}&salesAdjustmentTypes=ManualDeductionAdjustment&salesAdjustmentTypes=ManualAdditionAdjustment&salesAdjustmentTypes=EncashFromCredit` : "";
  }

  const returnCombinedPaymentCollectionSummaryRoutePath = (row, index) => {
    return blnPermissionManagePaymentReport && index !== 0 ? `/${RoutesList.report}/${RoutesList.combinedCollectionSummary}?branchId=${index === commissionCalculationList.length-1 ? " " : row.branchId}&subBrandId=${index === commissionCalculationList.length-1 ? " " : row.subBrandId || ""}&fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}` : "";
  }

  const returnCollectOnBehalfDetailsRoutePath = (row, index, blnPaymentBranch) => {
    return blnPermissionManagePaymentReport && index !== 0 ? `/${RoutesList.report}/${RoutesList.collectOnBehalfDetails}?${blnPaymentBranch ? "branchId" : "purchaseBranchId"}=${index === commissionCalculationList.length-1 ? " " : row.branchId}&subBrandId=${index === commissionCalculationList.length-1 ? " " : row.subBrandId || ""}&fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}` : "";
  }

  const CommissionCalculationRowStyle = (row, index) => {
    return ({
      backgroundColor: index === 0 ? colorBlack : "",
      fontWeight: index === 0 || (index === commissionCalculationList.length-1 && commissionCalculationList.length > 2) ? 600 : Constants.normalFontWeight,
    })
  }

  const displayTableDetailColumn = (valueDisplay, hideBorderRight: boolean = false, width?: string, hyperLink?: string, index?: number) => {
    return (
      <div
        style={{
          flex: 1,
          paddingLeft: "0.75rem",
          paddingRight: "0.75rem",
          paddingTop: '8px',
          paddingBottom: '8px',
          borderRight: hideBorderRight ? "" : "solid #eff2f7",
          borderRightWidth: hideBorderRight ? "" : "1px",
          alignItems: 'center', 
          justifyContent: index || index === 0 ? index === 0 ? 'center' : 'right' : undefined,
          textAlign: index || index === 0 ? index === 0 ? 'center' : 'right' : undefined,
          display: 'flex', 
          opacity: valueDisplay === Constants.emptyData ? 0 : 100,
          minHeight: '60px',
          width: width || tableDetailWidth,
          minWidth: width || tableDetailWidth,
          maxWidth: width || tableDetailWidth
        }}>
        {
          hyperLink
          ?
          <Link
            to={hyperLink}
            target={Constants.supportBlank}
            className="flex-direction-row">
            {valueDisplay}
          </Link>
          :
          valueDisplay
        }
      </div>
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CommissionCalculation" }) })}
        title={intl.formatMessage({ id: "CommissionCalculation" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAccountReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchCommissionCalculationListWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Commission-Calculation.aspx`}>
        <Row>
          {
            returnSubBrandList().length > 0
            &&
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "SubBrand" })}
                name="subBrandName"
                options={subBrandListFinal}
                initialLabel={selectedSubBrandName}
                initialValue={selectedSubBrandId}
                labelField="name"
                valueField="id"
                disabled={localLoading || loading}
                setFieldLabel={setSelectedSubBrandName}
                setFieldValue={setSelectedSubBrandId}
                validationRequired={true}
              />
            </Col>
          }
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "BusinessEntity" })}
              name="businessEntityName"
              options={businessEntityListFinal}
              initialLabel={selectedBusinessEntityName}
              initialValue={selectedBusinessEntityId}
              setFieldLabel={setSelectedBusinessEntityName}
              setFieldValue={setSelectedBusinessEntityId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl={"3"}>
            {
              localLoadingDateRange
              ?
              <Loading/>
              :
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRange" })}
                name={"DateRange"}
                disabled={localLoading || loading}
                initialValue={selectedDateRange}
                minMaxDateObj={dateRangeLimit}
                validationRequired={true}
                onChangeFunction={onSelectDateRange}/>
            }
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading }
              disable={localLoading || loading }
              onClick={() => {
                fetchCommissionCalculationListWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={CommissionCalculationColumns}
                rowStyle={CommissionCalculationRowStyle}
                bordered={true}
                keyField={"randomId"}
                data={commissionCalculationList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CommissionCalculation" }) })}
                objSorted={defaultSorted}
                requestAPI={getCommissionCalculationList}
                hideSearchBar={true} />
              {
                commissionCalculationNotes
                &&
                <Label 
                  style={{
                    padding: "20px", 
                    paddingTop: "0px",
                    marginTop: "-10px", 
                    backgroundColor: Constants.white, 
                    whiteSpace: "pre-wrap",
                    fontStyle: "italic",
                    fontWeight: "bold",
                    width: "100%"
                  }}>
                  {commissionCalculationNotes}
                </Label>
              }
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(CommissionCalculation);
