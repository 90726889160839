import React, { useState, useEffect } from "react";
import { Col,Label, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { newSpaceBeforeCapitalLetter, replaceAllStringWithArray } from "../../../app/common/function/function";
import { WindowObject } from "../../../app/models/common";
import { useIntl } from "react-intl";
import { UserRoleDetailObject, UserRoleListObject } from "../../../app/models/userRole";
import MyButton from "../../../app/components/form/MyButton";
import { Constants } from "../../../app/constants/Constants";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedUserRole: UserRoleDetailObject | undefined;
}

export default observer(function UserRolePermissionsListingModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { setLoading } = commonStore;

  //Data List
  const [userRoleListDisplay, setUserRoleListDisplay] = useState<any>([]);
  const userRoleColumns = ["Name", "Active"];

  const hideModal = () => {
    props.setModal(false);
  }

  useEffect(() => {
    async function fetchUserRoleAPI() {
      setLoading(true);
      let resultUserRole = props.selectedUserRole;
      if (resultUserRole) {
        let aryPermissionTemp: any = [];
        let objPermissionTemp: any = [];
        if (resultUserRole.permissions) {
          resultUserRole.permissions.map((valueRolesTemp: any) => {
            let categoryName = replaceAllStringWithArray(valueRolesTemp.name, ["Manage ", "Create ", "Update ", "Delete ", "Convert ", "Request ", "Review "], "");
            if (valueRolesTemp.name.includes(Constants.report)) {
              let aryTemp = valueRolesTemp.name.split(" ");
              if (aryTemp.length > 0) {
                if (aryTemp[aryTemp.length-1] === Constants.report) {
                  categoryName = Constants.report;
                }
              }
            }
            let indexCategory = _.findIndex(aryPermissionTemp, { categoryName: categoryName })
            objPermissionTemp = {
              Name: valueRolesTemp.name,
              Active: valueRolesTemp.isActive
            }
            if (indexCategory > -1) {
              aryPermissionTemp[indexCategory].data.push(objPermissionTemp);
            }
            else {
              aryPermissionTemp.push({
                categoryName: categoryName,
                data: [objPermissionTemp]
              })
            }
          })
        }
        setUserRoleListDisplay(aryPermissionTemp);
      }
      setLoading(false);
    }
    fetchUserRoleAPI();
  }, [props.selectedUserRole])

  const displayRow = (value) => {
    let aryViews: any = [];
    for (var key in value) {
      let keyTemp = key;
      aryViews.push(
        <td key={`row_key_${keyTemp}`}>
          {
            keyTemp === 'Name'
              ?
              value[keyTemp]
              :
              displayActiveStatus(value, keyTemp)
          }
        </td>
      )
    }
    return aryViews;
  }

  const displayActiveStatus = (row, key) => {
    return (
      <div className="flex-justfity-content-center">
        {row.Active ? <div className="rounded-circle green-circle" style={{ height: '18px', width: '18px' }} /> : <div className="rounded-circle red-circle" style={{ height: '18px', width: '18px' }} />}
      </div>
    )
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          hideModal();
        }}
        size="lg"
        centered>
        {
          !props.selectedUserRole
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              {/* {
            ((errorMessage || successMessage) && !loading)
            &&
            <div className="margin-top-48">
              <AlertMessage 
                errorMessage={errorMessage}
                successMessage={successMessage}/>
            </div>
          } */}
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0">{props.selectedUserRole.name}</h5>
                </div>
                <div style={{ display: 'flex-right' }} className="flex-direction-row">
                </div>
                <button
                  type="button"
                  onClick={() => { hideModal(); }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  {
                    userRoleListDisplay.map((value, index) => {
                      if (value.data) {
                        return (
                          <div key={`${value.categoryName}_${index}`}>
                            <Label className={`${index !== 0 ? "margin-top-24" : ""}`} style={{ fontSize: '15px', fontWeight: '600px' }}><strong>{value.categoryName}</strong></Label>
                            <Table className="table table-bordered mb-0">
                              <thead className="table-light">
                                <tr>
                                  {
                                    userRoleColumns.map((valuePermissionColumns, index) => {
                                      return (
                                        <th key={valuePermissionColumns} style={{ width: '60%', textAlign: 'center' }}>{newSpaceBeforeCapitalLetter(valuePermissionColumns)}</th>
                                      )
                                    })
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  value.data.map((valueData, indexValueDate) => {
                                    return (
                                      <tr key={`data_${index}_${indexValueDate}`}>
                                        {
                                          displayRow(valueData)
                                        }
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </Table>
                          </div>
                        )
                      }
                    })}
                </div>
                <div className="mb-3 mt-4 flex-right">
                  <MyButton
                    type="button"
                    class="btn btn-back"
                    style={{ width: '20%' }}
                    content={intl.formatMessage({ id: "Back" })}
                    onClick={() => {
                      hideModal();
                    }}
                  />
                </div>
              </div>
            </div>
        }
      </Modal>
  )
})