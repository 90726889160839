import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, getMonths, checkPermission, getBranchUser, getBrandId, arraySpliceInAllValue } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import ReportExpandRow from "../subView/ReportExpandRow";
import { BusinessEntityListObject } from "../../../app/models/businessEntity";
import _ from "lodash";

const CompanyRevenueSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportManagementStore, businessEntityStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { companyRevenueSummaryList, setCompanyRevenueSummaryList, getCompanyRevenueSummaryList, exportCompanyRevenue } = reportManagementStore;
  const { getBusinessEntityDropDown } = businessEntityStore;

  //Data List
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedBusinessEntityId, setSelectedBusinessEntityId] = useState(" ");
  const [selectedBusinessEntityName, setSelectedBusinessEntityName] = useState(All);
  const [businessEntityDropdownListFinal, setBusinessEntityDropdownListFinal] = useState<BusinessEntityListObject[]>([]);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportManagementReport = checkPermission([PermissionConstants.ExportManagementReport]);

  const aryCompanyRevenueSummaryDetail: ObjectForReportRow[] = [
    { label: "Branch", value: "branchName", type: "string" },
    { label: "InvoiceSales", value: "totalInvoiceSales", type: "currency" },
    { label: "PaymentCollection", value: "totalPaymentCollection", type: "currency" },
    { label: "CommissionGiven", value: "totalCommissionGiven", type: "currency" },
    { label: "CostConducted", value: "totalCostConducted", type: "currency" },
    { label: "EarnedSales", value: "totalEarnedSales", type: "currency" },
    { label: "UnearnedRevenue", value: "totalUnearnedRevenue", type: "currency" },
    { label: "RefundAmount", value: "totalRefundAmount", type: "currency" },
    { label: "Profit", value: "totalProfit", type: "currency" },
    { label: "Action", value: "routeUrl", type: "action" }
  ];

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths())

    async function fetchCompanyRevenueSummary() {
      setLoading(true);
      let aryAPI: any = [
        getBusinessEntityDropDown(getBrandId()),
      ];

      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[0]) {
        setBusinessEntityDropdownListFinal(arraySpliceInAllValue(resultAPI[0], All, false, "name", "value"))
      }
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageManagementReport], true)) {
      return;
    }

    fetchCompanyRevenueSummary();

    return (() => {
      setCompanyRevenueSummaryList(undefined);
    })
  }, [])

  const fetchCompanyRevenueSummaryWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await exportCompanyRevenue({ businessEntityId: selectedBusinessEntityId, year: selectedYear, month: selectedMonthValue });
    }
    else {
      await getCompanyRevenueSummaryList({ businessEntityId: selectedBusinessEntityId, year: selectedYear, month: selectedMonthValue });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const CompanyRevenueSummaryColumns = [
    {
      dataField: "businessEntityId", //field name from array to display
      text: "businessEntityId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "businessEntityName",
      text: intl.formatMessage({ id: "BusinessEntity" }).toUpperCase(),
    },
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "CompanyRevenueSummaryDetails" })}
        rowFieldName={"companyRevenueSummaryDetails"}
        rowColumns={aryCompanyRevenueSummaryDetail}
        keyFieldName={"branchId"}
        actionTitle={intl.formatMessage({ id: "ViewCustomerSalesOrderCost" })}
        actionPathName={`/${RoutesList.report}/${RoutesList.salesOrderCost}`}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CompanyRevenueSummary" }) })}
        title={intl.formatMessage({ id: "CompanyRevenueSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportManagementReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchCompanyRevenueSummaryWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Company-Revenue-Summary.aspx`}>
        <Row>
          {
            !getBranchUser()
            &&
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "BusinessEntity" })}
                name="businessEntityName"
                className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                options={businessEntityDropdownListFinal}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBusinessEntityName}
                initialValue={selectedBusinessEntityId}
                disabled={localLoading || loading}
                setFieldLabel={setSelectedBusinessEntityName}
                setFieldValue={setSelectedBusinessEntityId}
                validationRequired={true}
              />
            </Col>
          }
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              // styles={{marginLeft: '25px'}}
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchCompanyRevenueSummaryWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CompanyRevenueSummaryColumns}
              data={companyRevenueSummaryList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CompanyRevenueSummary" }) })}
              objSorted={defaultSorted}
              keyField={"businessEntityId"}
              requestAPI={getCompanyRevenueSummaryList}
              businessEntityId={selectedBusinessEntityId}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              expandRow={expandRow}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(CompanyRevenueSummary);
