import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import _ from 'lodash';
import { history } from "../..";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { GroupCodeListObject } from "../../app/models/groupCode";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { checkPermission, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import Loading from "../../app/components/loading/Loading";

const GroupCode = () => {
  const intl = useIntl();
  //Use Store
  const { groupCodeStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, setShowDeletePrompt, prevPath,setErrorMessage, setSuccessMessage, windowSize } = commonStore;
  const { groupCodeList, groupCodePaginationParams, getGroupCode, deleteGroupCode, setGroupCodePaginationParams } = groupCodeStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<GroupCodeListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateGroupCode = checkPermission([PermissionConstants.CreateGroupCode]);
  const blnPermissionUpdateGroupCode = checkPermission([PermissionConstants.UpdateGroupCode]);
  const blnPermissionDeleteGroupCode = checkPermission([PermissionConstants.DeleteGroupCode]);
  const [localLoading, setLocalLoading] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  let groupCodeRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    name: validation.values.name,
    ...!blnAllActive && {isActive: blnIsActive}
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchGroupCodeAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.groupCode)
      if (blnBackNavigation) {
        if (groupCodePaginationParams) {
          setCurrentPage(groupCodePaginationParams.pageNumber)
          validation.setValues((values)=> ({
            ...values,
            name: groupCodePaginationParams.name || "",
            isActive: groupCodePaginationParams.isActive || false,
          }))
          if(groupCodePaginationParams.hasOwnProperty('isActive')){
            setBlnIsActive(groupCodePaginationParams.isActive || false);
            setBlnAllActive(false);
          }
          groupCodeRequestBody = groupCodePaginationParams;
        }
      }
      else {
        setGroupCodePaginationParams(undefined);
      }
      await getGroupCode(groupCodeRequestBody)
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageGroupCode], true)) {
      return;
    }

    fetchGroupCodeAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.groupCode)
        if (!blnSetParams) {
          setGroupCodePaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchGroupCodeApi(true);
    }
  }, [triggerSearch, blnAllActive, blnIsActive])

  const onClickDelete = async (row: GroupCodeListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  async function fetchGroupCodeApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getGroupCode(groupCodeRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  //Table Content UI
  const GroupCodeColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        width: '60%'
      },
    },
    {
      dataField: "displayOrder",
      text: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.displayOrder}`}</div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.groupCode}/view/${row.id}`}
              type="view"/>
            {
              blnPermissionUpdateGroupCode
              &&
              <TableActionButton 
                to={`/${RoutesList.groupCode}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteGroupCode
              &&
              <TableActionButton 
                onClick={() => onClickDelete(row)}
                type="delete"/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "GroupCode" }) })}
        title={intl.formatMessage({ id: "GroupCode" })}
        addButton={
          blnPermissionCreateGroupCode
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "GroupCode" }) })}
            onClick={() => { history.push(`/${RoutesList.groupCode}/add`) }}
            linkTo={`/${RoutesList.groupCode}/add`}
            disable={loading} />
        }>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterName" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-26" : "margin-bottom-4"}`}>
            <div className="btn-group d-flex mb-3" role="group">
              <input type="radio" className="btn-check" name="blnViewAll" id="blnViewAll" autoComplete="off" disabled={localLoading || loading} checked={blnAllActive}
                onChange={()=> {}}
                onClick={() => {
                  setCurrentPage(1);
                  setBlnAllActive(true);
                }} />
              <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnViewAll">{intl.formatMessage({ id: "All" })}</label>
              <input type="radio" className="btn-check" name="blnIsActive" id="blnIsActiveTrue" autoComplete="off" disabled={localLoading || loading} checked={blnIsActive && !blnAllActive}
                onChange={()=> {}}
                onClick={() => {
                  setCurrentPage(1);
                  setBlnIsActive(true);
                  setBlnAllActive(false);
                }} />
              <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnIsActiveTrue">{intl.formatMessage({ id: "Active" })}</label>
              <input type="radio" className="btn-check" name="blnIsActive" id="blnIsActiveFalse" autoComplete="off" disabled={localLoading || loading} checked={!blnIsActive && !blnAllActive}
                onChange={()=> {}}
                onClick={() => {
                  setCurrentPage(1);
                  setBlnIsActive(false);
                  setBlnAllActive(false);
                }} />
              <label className="btn btn-outline-primary" style={{zIndex: 0, minWidth: Constants.activeTabWidth, maxWidth: Constants.activeTabWidth}} htmlFor="blnIsActiveFalse">{intl.formatMessage({ id: "Inactive" })}</label>
            </div>
          </Col>
        </Row>
        {
          localLoading
          ?
            <Loading/>
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={GroupCodeColumns}
              data={groupCodeList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "GroupCode" }) })}
              objSorted={defaultSorted}
              name={validation.values.name}
              isActive={!blnAllActive ? blnIsActive : undefined}
              requestAPI={getGroupCode} />
        }
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteGroupCode(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (groupCodeList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getGroupCode({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(GroupCode);
