import React, { Fragment } from 'react'
import { Col, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { calculateBalanceAmount, calculatePOSTotalPrice, newSpaceBeforeCapitalLetter, returnCurrency, returnMalaysiaCurrencyRounding, returnPriceWithCurrency } from '../../../app/common/function/function';
import Borderline from '../../../app/components/form/BorderLine';
import GeneralTextArea from '../../../app/components/form/GeneralTextArea';
import { Constants } from '../../../app/constants/Constants';
import { CustomerOrderDetailObject, CustomerPackageDetailObject, CustomerPaymentDetailObject } from '../../../app/models/customerOrder';
import { useIntl } from "react-intl";
import GeneralInput from '../../../app/components/form/GeneralInput';
import TableActionButton from '../../../app/components/table/TableActionButton';
import moment from 'moment';
import _ from 'lodash';

interface Props {
  validation: any;
  disabledPackageUpgradePayment: boolean;
  deletePaymentOrder?: Function;
}

export default function PackageUpgradeCart (props: Props) {
  const intl = useIntl();
  const displayCustomerOrder = () => {
    if (props.validation.values.customerPackageDetails.length < 1) {
      return (
        <div style={{height: '20px'}}>
          <span className="small-text">{intl.formatMessage({ id: "NoItemSelected" }, { moduleName: intl.formatMessage({id: "Item"}).toLowerCase() })}</span> 
          {props.validation.touched.customerPackageDetails && props.validation.errors.customerPackageDetails ? (
            <Label className="error margin-left-8" style={{fontSize: "12px"}}>{`* ${intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() })}` }</Label>
          ) : null}
        </div>)
    }

    return (
      props.validation.values.customerPackageDetails.map((value: CustomerPackageDetailObject, index) => (
        <Row className="mb-4" key={value.serviceTreatmentId}>
          <Col xs="7" md="7" xl="7"  style={{alignItems: 'center', display: 'flex'}}>
            <span className="avatar-sm circle-with-text rounded-circle">{value.count}</span>
            <div className="margin-left-16">
              <span>{value.serviceTreatmentName}</span>
              {value.discountValue ? <p className="margin-bottom-0" style={{ fontSize: '10px' }}>{`${intl.formatMessage({ id: "With" })} ${value.discountType == Constants.amount ? returnCurrency() : ""} ${value.discountValue} ${value.discountType == Constants.percentage ? "%" : ""} ${intl.formatMessage({ id: "Discount" }).toLowerCase()}`} </p> : null}
            </div>
          </Col>
          <Col xs={"5"} md={"5"} xl={"5"} className="flex-right" style={{alignItems: 'center', display: 'flex'}}>
            <p className="margin-bottom-0">{returnPriceWithCurrency((value.count*value.unitPrice)-value.discountAmount)}</p>
          </Col>
        </Row>
      ))
    )
  }

  const displayCustomerPayment = () => {
    if (props.validation.values.customerPaymentDetails.length < 1) {
      return (<div/>)
    }

    return (
      <div>
        {
          props.validation.values.customerPaymentDetails.map((value: CustomerPaymentDetailObject, index) => (
            <Row className="mb-4" key={`${value.paymentMethodId}_${index}`}>
              {/* <div className="avatar-sm"> */}
              <Col xs="6" md="6" xl="6"  style={{alignItems: 'center'}}>
                <p className={`margin-bottom-0 ${props.disabledPackageUpgradePayment && "small-text"}`}>{`${value.paymentMethodName}${value.last4CardDigit && value.cardType ? ` (${value.cardType} | ${value.last4CardDigit})` : ""}`}</p>
                <p className={`${props.disabledPackageUpgradePayment && "small-text"} payment-method-sub-text`}>{`${value?.paymentMethodOptionName ? `${value.paymentMethodOptionName}` : ""}${value.paymentIssuerBankName ? ` - ${value.paymentIssuerBankName}` : ""}`}</p>
              </Col>
              <Col xs={props.disabledPackageUpgradePayment ? "6" : "5"} md={props.disabledPackageUpgradePayment ? "6" : "5"} xl={props.disabledPackageUpgradePayment ? "6" : "5"}  style={{alignItems: 'center', display: 'flex', justifyContent: 'flex-end'}}>
                <p className={`margin-bottom-0 ${props.disabledPackageUpgradePayment && "small-text"}`}>{returnPriceWithCurrency(value.paidAmount)}</p>
              </Col>
              {
                !props.disabledPackageUpgradePayment
                &&
                <Col xs="1" md="1" xl="1" style={{alignItems: 'center', display: 'flex'}} className="text-danger flex-right" 
                  onClick={()=> { 
                    if (props.deletePaymentOrder && !props.disabledPackageUpgradePayment) {
                      if (value.isCustomerCredit && value.creditPaymentType === Constants.credit) {
                        let finalAvailableCreditsTemp = Number(props.validation.values?.availableCredits) + Number(value.paidAmount);
                        props.validation.setFieldValue("availableCredits", finalAvailableCreditsTemp.toFixed(2))
                      }
                      else if (value.isCustomerCredit && value.creditPaymentType === Constants.productCredit) {
                        let finalAvailableProductCreditsTemp = Number(props.validation.values?.availableProductCredits) + Number(value.paidAmount);
                        props.validation.setFieldValue("availableProductCredits", finalAvailableProductCreditsTemp.toFixed(2))
                      }
                      props.deletePaymentOrder(index)                      
                    }
                  }}>
                  <TableActionButton 
                    type={'delete'}
                    onClick={()=> {}}/>
                </Col>
              }
              {/* </div> */}
            </Row>
          ))
      }
      <Borderline/>
      </div>
    )
  }

  return (
    <div>
      {/*Item Selected */}
      {
        displayCustomerOrder()
      }
      <Borderline/>
      {
        Constants.supportMalaysiaRounding
        &&
        <>
          <Row>
            <Col className="flex-direction-row">
              <Label className="mb-0" style={{fontSize: '12px'}}>{intl.formatMessage({id: "Rounding"})}</Label>
              <GeneralInput
                title={""}
                name="hasRounding"
                className="margin-left-8"
                type="checkbox"
                disabled={props.disabledPackageUpgradePayment}
                validation={props.validation}
                onChangeFunctionWithSetValidationField={(valueRounding)=> {
                  let valuesTemp = _.cloneDeep(props.validation.values);
                  valuesTemp.hasRounding = valueRounding;
                  if (valueRounding) {
                    let finalPriceAfterRounding = returnMalaysiaCurrencyRounding(valuesTemp.totalPrice);
                    let totalRoundingPrice = Math.round((finalPriceAfterRounding - valuesTemp.totalPrice) * 100) / 100;
                    valuesTemp.totalPriceBeforeRounding = Number(valuesTemp.totalPrice);
                    valuesTemp.totalPrice = Number(finalPriceAfterRounding);
                    valuesTemp.totalRoundingPrice = totalRoundingPrice;
                  }
                  else {
                    valuesTemp.totalPrice = valuesTemp.totalPriceBeforeRounding;
                    valuesTemp.totalRoundingPrice = 0;
                    valuesTemp.hasRounding = valueRounding;
                  }
                  let prefillPaidNumber : any = calculateBalanceAmount({values: valuesTemp}, "customerPaymentDetails", "totalPrice", valuesTemp.customerPaymentDetails);
                  valuesTemp.paidNumber = prefillPaidNumber;
                  props.validation.setValues(valuesTemp);                
                }} />
            </Col>
            <Col className="flex-right">
              <Label className="mb-0" style={{fontSize: '12px'}}>{returnPriceWithCurrency(props.validation.values.totalRoundingPrice)}</Label>
            </Col>
          </Row>
          <Borderline/>
        </>
      }
      <Row>
        <Col>
          <Label className="mb-0">{intl.formatMessage({id: "Total"})}</Label>
        </Col>
        <Col className="flex-right">
          <Label className="mb-0">{returnPriceWithCurrency(props.validation.values.totalPrice)}</Label>
        </Col>
      </Row>
      <Borderline/>
      {displayCustomerPayment()}
      {/* <GeneralInput
        title={intl.formatMessage({ id: "ExpiredDate"})}
        name="expiredDate"
        type="date"
        validationRequired={true}
        minCurrentDate={true}
        disabled={props.disabledPackageUpgradePayment}
        validation={props.validation}
      />
      <Borderline/> */}
      <GeneralTextArea
        title={intl.formatMessage({id: "Note"})}
        name="note"
        row={5}
        disabled={props.disabledPackageUpgradePayment}
        validation={props.validation}/>
      <Borderline/>
      <Row>
        <GeneralInput
          title={""}
          name="isBackdate"
          className={`mt-3`}
          type="checkbox"
          disabled={true}
          validationRequired={false}
          validation={props.validation}
          childrenUI={
            <Label className="margin-bottom-0 margin-left-4">
              {intl.formatMessage({ id: "IsApplyPackageUpgradeSubmissionDate" })}
            </Label>
          }
        />
        {
          props.validation.values.isBackdate
          &&
          <GeneralInput
            title={""}
            name="submissionDate"
            className="mt-2"
            placeholder={intl.formatMessage({ id: "PackageUpgradeSubmissionDate" })}
            type="datetime-local"
            disabled={true}
            validationRequired={true}
            validation={props.validation}
            maxCurrentDate={true}
          />
        }
      </Row>
      <Borderline/>
    </div>
  )
}
