import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import ReportListingDetail from "./subView/ReportListingDetail";
import { ObjectForDropdown } from "../../app/models/common";
import { Col, Row } from "reactstrap";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { checkPermission } from "../../app/common/function/function";
import _ from "lodash";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { Constants } from "../../app/constants/Constants";
import { useStore } from "../../app/stores/store";
import Loading from "../../app/components/loading/Loading";

const Report = () => {
  const intl = useIntl();
  
  //Use Store
  const { settingsStore, commonStore } = useStore();
  const { loading, setLoading } = commonStore;
  const { generalSettings, getGeneralSettings } = settingsStore;

  const [salesReportList, setSalesReportList] = useState<ObjectForDropdown[]>([]);
  const [marcomReportList, setMarcomReportList] = useState<ObjectForDropdown[]>([]);
  const [appointmentReportList, setAppointmentReportList] = useState<ObjectForDropdown[]>([]);
  const [customerReportList, setCustomerReportList] = useState<ObjectForDropdown[]>([]);
  const [paymentReportList, setPaymentReportList] = useState<ObjectForDropdown[]>([]);
  const [servicesReportList, setServicesReportList] = useState<ObjectForDropdown[]>([]);
  const [stockReportList, setStockReportList] = useState<ObjectForDropdown[]>([]);
  const [managementReportList, setManagementReportList] = useState<ObjectForDropdown[]>([]);
  const [masterDataReportList, setMasterDataReportList] = useState<ObjectForDropdown[]>([]);
  const [humanResourceReportList, setHumanResourceReportList] = useState<ObjectForDropdown[]>([]);
  const [appolousReportList, setAppolousReportList] = useState<ObjectForDropdown[]>([]);
  const [accountReportList, setAccountReportList] = useState<ObjectForDropdown[]>([]);
  const blnShowAppolous = generalSettings?.isShowAppolous;
  const blnEnableTransferCredit = generalSettings?.isTransferCreditEnabled;

  useEffect(() => {
    async function fetchGeneralSettingsAPI() {
      setLoading(true);
      let aryAPI: any = [
        getGeneralSettings()
      ];
      await Promise.all(aryAPI);
      setLoading(false);
    }

    let salesReportListTemp : ObjectForDropdown[] = [];
    let marcomReportListTemp : ObjectForDropdown[] = [];
    let appointmentReportListTemp : ObjectForDropdown[] = [];
    let customerReportListTemp : ObjectForDropdown[] = [];
    let paymentReportListTemp : ObjectForDropdown[] = [];
    let servicesReportListTemp : ObjectForDropdown[] = [];
    let stockReportListTemp : ObjectForDropdown[] = [];
    let managementReportListTemp : ObjectForDropdown[] = [];
    let masterDataReportListTemp : ObjectForDropdown[] = [];
    let humanResourceReportListTemp : ObjectForDropdown[] = [];
    let appolousReportListTemp : ObjectForDropdown[] = [];
    let accountReportListTemp : ObjectForDropdown[] = [];

    if (checkPermission(Constants.ReportPermissionList, true)) {
      if (checkPermission([PermissionConstants.ManageSalesReport], false)) {
        salesReportListTemp.push(
          {label: intl.formatMessage({ id: "ConsultantSalesSummary" }), value: RoutesList.consultantSalesSummary}, 
          {label: intl.formatMessage({ id: "ConsultantSales" }), value: RoutesList.consultantSales},
          {label: intl.formatMessage({ id: "ConsultantDailySales" }), value: RoutesList.consultantDailySales},
          {label: intl.formatMessage({ id: "ConsultantSalesCommission" }), value: RoutesList.consultantSalesCommission},
          {label: intl.formatMessage({ id: "SalesOrderCommissionSummary" }), value: `${RoutesList.salesOrderCommissionSummary}`},
          // {label: intl.formatMessage({ id: "DailySalesSummary" }), value: RoutesList.dailySalesSummary},
          // {label: intl.formatMessage({ id: "MonthlySalesSummary" }), value: RoutesList.monthlySalesSummary},
          // {label: intl.formatMessage({ id: "DailySalesDetails" }), value: RoutesList.dailySalesDetails},
          {label: intl.formatMessage({ id: "DynamicSalesDetails" }), value: `${RoutesList.dynamicSalesDetails}`},
          {label: intl.formatMessage({ id: "BranchSalesSummary" }), value: `${RoutesList.branchSalesSummary}`},
          {label: intl.formatMessage({ id: "BranchMonthlySalesSummary" }), value: `${RoutesList.branchMonthlySalesSummary}`},
          {label: intl.formatMessage({ id: "BranchDailySalesSummary" }), value: `${RoutesList.branchDailySalesSummary}`},
          {label: intl.formatMessage({ id: "SalesBySalesCategory" }), value: `${RoutesList.sales}salesCategory`},
          // {label: intl.formatMessage({ id: "SalesByPackage" }), value: `${RoutesList.sales}package`},
          {label: intl.formatMessage({ id: "SalesByGroupCode" }), value: `${RoutesList.salesByGroupCode}`},
          {label: intl.formatMessage({ id: "SalesByProduct" }), value: `${RoutesList.sales}product`},
          {label: intl.formatMessage({ id: "SalesByService" }), value: `${RoutesList.sales}service`},
          {label: intl.formatMessage({ id: "SalesByEmployee" }), value: `${RoutesList.sales}employee`},
          {label: intl.formatMessage({ id: "ProductSold" }), value: `${RoutesList.productSold}`},
          {label: intl.formatMessage({ id: "SalesAdjustmentHistory" }), value: `${RoutesList.salesAdjustmentHistory}`},
          {label: intl.formatMessage({ id: "SalesOverview" }), value: `${RoutesList.salesOverview}`},
          {label: intl.formatMessage({ id: "SalesOverviewDetails" }), value: `${RoutesList.salesOverviewDetails}`},
          {label: intl.formatMessage({ id: "SalesOverviewYearly" }), value: `${RoutesList.salesOverviewYearly}`},
          {label: intl.formatMessage({ id: "SharedSalesSummary" }), value: `${RoutesList.sharedSalesSummary}`},
          {label: intl.formatMessage({ id: "DiscountSalesDetails" }), value: `${RoutesList.discountSalesDetails}`},
          {label: intl.formatMessage({ id: "ProductSalesDetails" }), value: `${RoutesList.productSalesDetails}`},
          {label: intl.formatMessage({ id: "ClosingRate" }), value: `${RoutesList.closingRate}`},
          {label: intl.formatMessage({ id: "ExchangeToCreditDetails" }), value: `${RoutesList.exchangeToCreditDetails}`},
          {label: intl.formatMessage({ id: "RefundFromCredit" }), value: `${RoutesList.refundFromCredit}`},
          {label: intl.formatMessage({ id: "SalesVariance" }), value: `${RoutesList.salesVariance}`},
        )
      }

      if (checkPermission([PermissionConstants.ManageMarcomReport], false)) {
        marcomReportListTemp.push(
          {label: intl.formatMessage({ id: "LeadsTurnUpRateSummary" }), value: RoutesList.leadsTurnUpRateSummary}, 
          {label: intl.formatMessage({ id: "NewVisitVsTurnUp"}), value: RoutesList.newVisitVsTurnUp},
          {label: intl.formatMessage({ id: "LeadsSummaryByMediaSource" }), value: RoutesList.leadsSummaryByMedia}, 
          {label: intl.formatMessage({ id: "LeadsSummaryByEmployee" }), value: RoutesList.leadsSummaryByEmployee}, 
          {label: intl.formatMessage({ id: "LeadsDetails" }), value: RoutesList.leadsDetail},
          {label: intl.formatMessage({ id: "LeadAppointments" }), value: RoutesList.leadAppointments},
          {label: intl.formatMessage({ id: "CustomerTurnedUp" }), value: RoutesList.customerTurnedUp},
          {label: intl.formatMessage({ id: "PromotionCodeHistory" }), value: RoutesList.promoCodeHistory},
          {label: intl.formatMessage({ id: "AppointmentOverviewSummary" }), value: RoutesList.appointmentOverviewSummary},
        )
      }

      if (checkPermission([PermissionConstants.ManageAppointmentReport], false)) {
        appointmentReportListTemp.push(
          {label: intl.formatMessage({ id: "AppointmentSummaryBySource" }), value: RoutesList.appointmentSummaryBySource}, 
          {label: intl.formatMessage({ id: "AppointmentSummaryByStatus" }), value: RoutesList.appointmentSummaryByStatus},
          {label: intl.formatMessage({ id: "AppointmentDetails" }), value: RoutesList.appointmentDetails},
          {label: intl.formatMessage({ id: "RoomOccupancySummary" }), value: RoutesList.roomOccupancy},
          {label: intl.formatMessage({ id: "RoomOccupancyDetails" }), value: RoutesList.roomUtilization},
        )
      }

      if (checkPermission([PermissionConstants.ManageCustomerReport], false)) {
        customerReportListTemp.push(
          {label: intl.formatMessage({ id: "CustomerDetails" }), value: RoutesList.customerDetails},
          {label: intl.formatMessage({ id: "AverageSpendingSummary" }), value: RoutesList.averageSpendingSummary},
          {label: intl.formatMessage({ id: "PackageUnutilizedBalance" }), value: RoutesList.packageUnutilizedBalance},
          {label: intl.formatMessage({ id: "ProductUnutilizedBalance" }), value: RoutesList.productUnutilizedBalance},
          {label: intl.formatMessage({ id: "CreditHistory" }), value: RoutesList.creditHistory},
          {label: intl.formatMessage({ id: "TransferCreditHistory" }), value: RoutesList.transferCreditHistory},
          {label: intl.formatMessage({ id: "ExpiringPackage" }), value: RoutesList.expiringPackage},
          {label: intl.formatMessage({ id: "CustomerWithoutNextAppointment" }), value: RoutesList.withoutAppointment},
          {label: intl.formatMessage({ id: "CustomerBirthday" }), value: RoutesList.customerBirthday},
          {label: intl.formatMessage({ id: "CustomerUpsalesSummary" }), value: RoutesList.upsalesSummary},
          {label: intl.formatMessage({ id: "CustomerWithoutPackage" }), value: RoutesList.withoutPackage},
          {label: intl.formatMessage({ id: "TopSpender" }), value: RoutesList.topSpender},
        )
      }

      if (checkPermission([PermissionConstants.ManagePaymentReport], false)) {
        paymentReportListTemp.push(
          //{label: intl.formatMessage({ id: "PaymentCollectionSummary" }), value: RoutesList.collectionSummary},
          {label: intl.formatMessage({ id: "PaymentCollectionDetails" }), value: RoutesList.collectionDetails},
          {label: intl.formatMessage({ id: "PaymentCollectOnBehalfSummary" }), value: RoutesList.collectOnBehalf},
          {label: intl.formatMessage({ id: "PaymentCollectOnBehalfDetails" }), value: RoutesList.collectOnBehalfDetails},
          {label: intl.formatMessage({ id: "PaymentCombinedCollectionSummary" }), value: RoutesList.combinedCollectionSummary},
        )
      }

      if (checkPermission([PermissionConstants.ManageServiceReport], false)) {
        servicesReportListTemp.push(
          {label: intl.formatMessage({ id: "ServicesByEmployee" }), value: `${RoutesList.services}employee`},
        )
      }
      
      if (checkPermission([PermissionConstants.ManageInventoryReport], false)) {
        stockReportListTemp.push(
          {label: intl.formatMessage({ id: "StockForecastSummary" }), value: RoutesList.stockForecastSummary},
          {label: intl.formatMessage({ id: "StockCard" }), value: RoutesList.stockCard},
          {label: intl.formatMessage({ id: "StockLevel" }), value: RoutesList.stockLevel},
          {label: intl.formatMessage({ id: "StockLevelHistory" }), value: RoutesList.stockLevelHistory},
          {label: intl.formatMessage({ id: "StockReceiveVariant" }), value: RoutesList.stockReceiveVariant},
          {label: intl.formatMessage({ id: "ReservedProductSummary" }), value: RoutesList.reservedProductSummary},
          {label: intl.formatMessage({ id: "ReservedProductReversalSummary" }), value: RoutesList.reservedProductReversalSummary},
        )
      }

      if (checkPermission([PermissionConstants.ManageManagementReport], false)) {
        managementReportListTemp.push(
          {label: intl.formatMessage({ id: "CompanyRevenueSummary" }), value: RoutesList.companyRevenue},
          {label: intl.formatMessage({ id: "SalesOrderCost" }), value: RoutesList.salesOrderCost},
          {label: intl.formatMessage({ id: "AccountPosting" }), value: RoutesList.accountPost},
        )
      }

      if (checkPermission([PermissionConstants.ManageMasterDataReport], false)) {
        masterDataReportListTemp.push(
          {label: intl.formatMessage({ id: "Product" }), value: RoutesList.productMasterData},
          {label: intl.formatMessage({ id: "Package" }), value: RoutesList.packageMasterData},
          {label: intl.formatMessage({ id: "ServiceTreatment" }), value: RoutesList.serviceMasterData},
        )
      }

      if (checkPermission([PermissionConstants.ManageHumanResourceReport], false)) {
        humanResourceReportListTemp.push(
          {label: intl.formatMessage({ id: "DayOff" }), value: RoutesList.dayOff},
          {label: intl.formatMessage({ id: "OutletSchedule" }), value: RoutesList.outletSchedule},
        )
      }

      if (checkPermission([PermissionConstants.ManageAppolousReport], false)) {
        appolousReportListTemp.push(
          //{label: intl.formatMessage({ id: "AppolousPaymentCollectionSummary" }), value: RoutesList.appolousPaymentCollectionSummary},
          {label: intl.formatMessage({ id: "AppolousDynamicSalesDetails" }), value: RoutesList.appolousDynamicSalesDetails},
          {label: intl.formatMessage({ id: "AppolousProductCommissionVariant" }), value: RoutesList.appolousProductCommissionVariant},
          {label: intl.formatMessage({ id: "AppolousFreePackageAndProduct" }), value: RoutesList.appolousFreePackageAndProduct},
        )
      }

      if (checkPermission([PermissionConstants.ManageAccountReport], false)) {
        accountReportListTemp.push(
          {label: intl.formatMessage({ id: "CommissionCalculation" }), value: RoutesList.commissionCalculation},
          // {label: intl.formatMessage({ id: "ContractLiability" }), value: RoutesList.contractLiability},
          {label: intl.formatMessage({ id: "EarnSalesSummary" }), value: RoutesList.earnSalesSummary},
          {label: intl.formatMessage({ id: "UnearnedSalesDetails" }), value: RoutesList.unearnedSalesDetails},
          {label: intl.formatMessage({ id: "RecogniseSalesDetails" }), value: RoutesList.recogniseSalesDetails},
          {label: intl.formatMessage({ id: "PaymentCollection" }), value: RoutesList.paymentCollection},
          {label: intl.formatMessage({ id: "ChangePaymentMode" }), value: RoutesList.changePaymentMode}
        )
      }
    }

    fetchGeneralSettingsAPI();

    setSalesReportList(salesReportListTemp);
    setMarcomReportList(marcomReportListTemp);
    setAppointmentReportList(appointmentReportListTemp);
    setCustomerReportList(customerReportListTemp);
    setPaymentReportList(paymentReportListTemp);
    setServicesReportList(servicesReportListTemp);
    setStockReportList(stockReportListTemp);
    setManagementReportList(managementReportListTemp);
    setMasterDataReportList(masterDataReportListTemp);
    setHumanResourceReportList(humanResourceReportListTemp);
    setAppolousReportList(appolousReportListTemp);
    setAccountReportList(accountReportListTemp);
  }, [])

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "Report"})})}
        title={intl.formatMessage({ id: "Report" })}
        addButton={
          <div>
          </div>
        }>
        {loading ? (
          <Loading />
        ) : (
          <Row form={+true}>
            {
              (salesReportList.length > 0 || paymentReportList.length > 0 || marcomReportList.length > 0 || masterDataReportList.length > 0 || humanResourceReportList.length > 0)
              &&
              <Col xl={6}>
                {
                  salesReportList.length > 0
                  &&              
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "Sales" })}
                    subTitle={intl.formatMessage({ id: "SalesReportDetail" })}
                    aryReport={salesReportList}/>
                }
                {
                  paymentReportList.length > 0
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "Payment" })}
                    className={salesReportList.length > 0 ? "mt-3" : ""}
                    subTitle={intl.formatMessage({ id: "PaymentReportDetail" })}
                    aryReport={paymentReportList}/>
                }
                {
                  marcomReportList.length > 0
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "Marcom" })}
                    className={salesReportList.length > 0 || paymentReportList.length > 0 ? "mt-3" : ""}
                    subTitle={intl.formatMessage({ id: "MarcomReportDetail" })}
                    aryReport={marcomReportList}/>
                }
                {
                  masterDataReportList.length > 0
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "MasterData" })}
                    className={salesReportList.length > 0 || paymentReportList.length > 0 || marcomReportList.length > 0 ? "mt-3" : ""}
                    subTitle={intl.formatMessage({ id: "MasterDataReportDetail" })}
                    aryReport={masterDataReportList}/>
                }
                {
                  humanResourceReportList.length > 0
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "HumanResource" })}
                    className={salesReportList.length > 0 || paymentReportList.length > 0 || marcomReportList.length > 0 || masterDataReportList.length > 0 ? "mt-3 mb-3" : ""}
                    subTitle={intl.formatMessage({ id: "HumanResourceReportDetail" })}
                    aryReport={humanResourceReportList}/>
                }
              </Col>
            }
            {
              (appointmentReportList.length > 0 ||  servicesReportList.length > 0 || customerReportList.length > 0 || 
              managementReportList.length > 0 || stockReportList.length > 0)
              &&
              <Col xl={6}>
                {
                  appointmentReportList.length > 0
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "Appointment" })}
                    subTitle={intl.formatMessage({ id: "AppointmentReportDetail" })}
                    aryReport={appointmentReportList}/>
                }
                {
                  servicesReportList.length > 0
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "Services" })}
                    className={appointmentReportList.length > 0 ? "mt-3" : ""}
                    subTitle={intl.formatMessage({ id: "ServicesReportDetail" })}
                    aryReport={servicesReportList}/>
                }
                {
                  customerReportList.length > 0
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "Customer" })}
                    className={appointmentReportList.length > 0 || servicesReportList.length > 0 ? "mt-3" : ""}
                    subTitle={intl.formatMessage({ id: "CustomerReportDetail" })}
                    aryReport={customerReportList}
                    blnEnableTransferCredit={blnEnableTransferCredit}/>
                }
                {
                  stockReportList.length > 0
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "Inventory" })}
                    className={appointmentReportList.length > 0 || servicesReportList.length > 0 || customerReportList.length > 0 ? "mt-3" : ""}
                    subTitle={intl.formatMessage({ id: "StockReportDetail" })}
                    aryReport={stockReportList}/>
                }
                {
                  managementReportList.length > 0
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "Management" })}
                    className={appointmentReportList.length > 0 || servicesReportList.length > 0 || customerReportList.length > 0 || stockReportList.length > 0 ? "mt-3" : ""}
                    subTitle={intl.formatMessage({ id: "ManagementReportDetail" })}
                    aryReport={managementReportList}/>
                }
                {
                  appolousReportList.length > 0
                  &&
                  blnShowAppolous
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "Appolous" })}
                    className={appointmentReportList.length > 0 || servicesReportList.length > 0 || customerReportList.length > 0 || stockReportList.length > 0 || managementReportList.length > 0 ? "mt-3" : ""}
                    subTitle={intl.formatMessage({ id: "AppolousReportDetail" })}
                    aryReport={appolousReportList}/>
                }
                {
                  accountReportList.length > 0
                  &&
                  <ReportListingDetail
                    title={intl.formatMessage({ id: "Account" })}
                    className={appointmentReportList.length > 0 || servicesReportList.length > 0 || customerReportList.length > 0 || stockReportList.length > 0 || managementReportList.length > 0 || appolousReportList.length > 0 ? "mt-3" : ""}
                    subTitle={intl.formatMessage({ id: "AccountReportDetail" })}
                    aryReport={accountReportList}/>
                }
              </Col>
            }
          </Row>
        )}
      </ListViewLayout>
    </div>
  );
}

export default observer(Report);